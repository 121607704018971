import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormValidationHelpersService } from 'src/app/utils/form/validators/form-validators';
import { FormGroupHelpers } from 'src/app/utils/form/form-group-helpers';
import { UploadedItem } from '../../../delivery/models/uploadedItems.model';
import { DeliveryService } from '../../../delivery/services/delivery.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-delivery-expected-item-modal',
  templateUrl: 'add-delivery-expected-item-modal.component.html',
})
export class AddDeliveryExpectedItemModalComponent implements OnInit {
  @Output() add = new EventEmitter();

  quantity = 0;

  addExpectedItemForm: FormGroup = this.fb.group(
    {
      ean: [null],
      sku: [null],
      comment: [''],
      quantity: [1, [Validators.required]],
      purchasePrice: [0],
      deliveryId: [null, Validators.required],
      skuForEan: '',
      eanValidate: [true, Validators.requiredTrue],
      skuValidate: [false, Validators.requiredTrue],
    },
    { validators: this.formValidationHelpers.isEanOrSkuProvidedValidator },
  );

  constructor(
    public toastr: ToastrService,
    private fb: FormBuilder,
    private formValidationHelpers: FormValidationHelpersService,
    public formGroupHelpers: FormGroupHelpers,
    public deliveryService: DeliveryService,
    @Inject(MAT_DIALOG_DATA) public deliveryId: number,
    public dialogRef: MatDialogRef<AddDeliveryExpectedItemModalComponent>,
  ) {}

  ngOnInit(): void {
    this.addExpectedItemForm.get('deliveryId').patchValue(this.deliveryId);
  }

  save(): void {
    if (this.addExpectedItemForm.valid) {
      const { ean, sku, quantity, comment, purchasePrice } = this.addExpectedItemForm.value;

      const uploadedItems: UploadedItem = {
        uploadedItems: [
          {
            ean,
            sku,
            quantity,
            comment,
            purchasePrice,
          },
        ],
      };
      this.add.emit(uploadedItems);
      this.dialogRef.close();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onQuantityChange(quantity: number): void {
    this.addExpectedItemForm.get('quantity').patchValue(quantity);
  }
}
