import { Filters } from '../../../core/services/list/models/Filters';

export class MassActionSelectByFilters {
  private _state = false;

  private _filters: Filters = null;

  private _itemsCount: number;

  set state(state: boolean) {
    this._state = state;
  }

  get state() {
    return this._state;
  }

  set filters(filters: Filters) {
    this._filters = filters;
  }

  get filters() {
    return this._filters;
  }

  set itemsCount(itemsCount: number) {
    this._itemsCount = itemsCount;
  }

  get itemsCount() {
    return this._itemsCount;
  }

  reset() {
    this._state = false;
    this._filters = null;
  }
}
