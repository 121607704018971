import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/api.service';
import { Filters } from 'src/app/core/services/list/models/Filters';
import { List } from 'src/app/core/models/list';
import { apiRoutes } from 'src/environments/api-routes';
import { MonitoringLog } from '../models/monitoring-log.model';
import { LogType } from '../models/log-type.model';
import { ManualParameters } from '../../core/services/list/models/ManualParameters.model';
import { LogTypeActions } from '../models/log-type-actions.model';

@Injectable({
  providedIn: 'root',
})
export class MonitoringApiService {
  private apiRoutes = apiRoutes;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  getMonitoringList(
    filters: Filters,
    genericFilters = null,
    manualParameters: ManualParameters[] = null,
  ): Observable<List<MonitoringLog>> {
    return this.http.get<List<MonitoringLog>>(
      this.apiService.getMikuUrl(this.apiRoutes.monitoring.transferLogs, filters, genericFilters, manualParameters),
    );
  }

  getMonitoringDetails(id: number): Observable<MonitoringLog> {
    return this.http.get<MonitoringLog>(
      this.apiService.getMikuUrl(this.apiRoutes.monitoring.id.replace('{id}', id.toString())),
    );
  }

  getLogTypes(): Observable<List<LogType>> {
    return this.http.get<List<LogType>>(this.apiService.getMikuUrl(this.apiRoutes.pim.logs.logTypes));
  }

  getLogModules(): Observable<List<LogType>> {
    return this.http.get<List<LogType>>(this.apiService.getMikuUrl(this.apiRoutes.pim.logs.logModules));
  }

  getLogActions(module: string): Observable<LogTypeActions> {
    return this.http.get<LogTypeActions>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.logs.logActions.replace('{module}', module)),
    );
  }
}
