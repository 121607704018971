import { Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ComponentDestroy, TakeUntilDestroy } from '../decorators/take-until-destroy';
import { AuthorizationService } from '../../security/services/authorization.service';

@Directive({
  selector: '[appHasAccess]',
})
@TakeUntilDestroy
export class HasAccessDirective implements OnDestroy, ComponentDestroy, OnInit {
  private _acl: string[];

  @Input('appHasAccess') set acl(value: string[]) {
    this._acl = value || [];
  }

  componentDestroy: () => Observable<void>;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit(): void {
    this.viewContainer.clear();

    this.authorizationService
      .getPrivileges$()
      .pipe(
        tap((data: string[]) => {
          const hasAccess = this._acl.every(v => data.includes(v));
          if (hasAccess) {
            // on dev build it sometimes runs twice, so check if content is already there
            if (this.viewContainer.get(0) === null) {
              this.viewContainer.createEmbeddedView(this.templateRef);
            }
          } else {
            this.viewContainer.clear();
          }
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  ngOnDestroy(): void {}
}
