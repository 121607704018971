import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-to-join',
  templateUrl: './request-to-join.component.html',
  styleUrls: ['./request-to-join.component.scss'],
})
export class RequestToJoinComponent implements OnInit {
  @Output() onCancel = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<RequestToJoinComponent>) {}

  ngOnInit(): void {}

  cancel() {
    this.onCancel.emit();
    this.dialogRef.close();
  }
}
