import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Document } from '../../../document/models/document.model';
import { TableHeaders } from '../../table/models/tableHeaders.model';
import { TableAlign } from '../../table/models/tableAlign.enum';
import { TableCell } from '../../table/models/tableCell.model';
import { TableCellType } from '../../table/models/tableCellType.enum';
import { DocumentApiService } from '../../../document/services/document-api.service';
import { TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { DocumentView } from '../../../document/models/document-view.model';

@Component({
  selector: 'app-document-view-modal',
  templateUrl: './document-view-modal.component.html',
  styleUrls: ['./document-view-modal.component.scss'],
})
@TakeUntilDestroy
export class DocumentViewModalComponent implements OnInit, OnDestroy {
  componentDestroy: () => Observable<void>;

  document: DocumentView = null;

  headers: TableHeaders[] = [
    { value: 'Product' },
    { value: 'Ean' },
    { value: 'Sku' },
    { value: 'Location', align: TableAlign.center },
    { value: 'Quantity', align: TableAlign.center },
    { value: 'Delivery', align: TableAlign.center },
  ];

  cells: TableCell[] = [
    { type: TableCellType.text, key: 'product.name' },
    { type: TableCellType.text, key: 'product.ean' },
    { type: TableCellType.text, key: 'product.sku' },
    { type: TableCellType.text, key: 'location.barcode', align: TableAlign.center },
    { type: TableCellType.text, key: 'quantity', align: TableAlign.center },
    { type: TableCellType.text, key: 'delivery.name', align: TableAlign.center },
  ];

  constructor(
    private dialogRef: MatDialogRef<DocumentViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Document,
    private documentApiService: DocumentApiService,
  ) {}

  ngOnInit(): void {
    this.documentApiService
      .getDocument(this.data.type.code, this.data.document_id)
      .pipe(
        tap(document => (this.document = document)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  ngOnDestroy() {}
}
