import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidationHelpersService } from 'src/app/utils/form/validators/form-validators';
import { FormGroupHelpers } from 'src/app/utils/form/form-group-helpers';
import { EMPTY, Observable, of } from 'rxjs';
import { AuthorizationService } from '../../services/authorization.service';
import { AclApiService } from '../../../acl/services/acl-api.service';
import { List } from '../../../core/models/list';
import { MePermissions } from '../../../acl/models/me-permissions';
import { ComponentDestroy, TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { WarehouseService } from '../../../core/services/warehouse.service';
import { WarehouseListInterface } from '../../../core/models/warehouse-list.interface';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { SettingsService } from '../../../settings/services/settings.service';
import { PusherService } from '../../../core/services/pusher.service';
import * as Sentry from '@sentry/angular-ivy';
import { UserModel } from '../../../core/models/user.model';
import { EveryDayLogoutService } from '../../../core/services/every-day-logout.service';
import { Status2FA } from './models/status2fa.enum';
import { Authorization } from './models/authorization.interface';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from '../../../core/services/broadcast.service';
import { SessionAnalyzerService } from '../../../core/services/session-analyzer.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../styles/security.scss'],
})
@TakeUntilDestroy
export class LoginComponent implements OnInit, OnDestroy, ComponentDestroy {
  componentDestroy: () => Observable<void>;

  warehouseList: WarehouseListInterface[] = [];

  loginStatus: Status2FA = Status2FA.NONE;

  authorizationData: Authorization = null;

  Status2FA = Status2FA;

  loginForm: FormGroup = this.fb.group({
    login: ['', Validators.required],
    password: ['', Validators.required],
    warehouse: ['', Validators.required],
  });

  twoFactorForm: FormGroup = this.fb.group({
    code: ['', Validators.required],
  });

  constructor(
    public authorizationService: AuthorizationService,
    public router: Router,
    private fb: FormBuilder,
    private formValidationHelpers: FormValidationHelpersService,
    public formGroupHelpers: FormGroupHelpers,
    private aclApiService: AclApiService,
    private warehouseService: WarehouseService,
    private localStorageService: LocalStorageService,
    private settings: SettingsService,
    private pusherService: PusherService,
    private everyDayLogoutService: EveryDayLogoutService,
    private toastrService: ToastrService,
    private broadcastService: BroadcastService,
    private sessionAnalyzerService: SessionAnalyzerService,
  ) {
  }

  ngOnInit() {
    this.pusherService.pusher?.disconnect();
    const warehouse = this.warehouseService.warehouseId;
    if (warehouse) this.loginForm.get('warehouse').patchValue(warehouse);

    this.warehouseService
      .getWarehouseList()
      .pipe(
        tap(({ items }) => (this.warehouseList = items)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();

    if (this.authorizationService.isUserLoggedIn()) {
      this.router.navigateByUrl('dashboard');
    }
  }

  ngOnDestroy() {
  }

  loginAction() {
    this.formValidationHelpers.validateAllFormFields(this.loginForm);
    if (this.loginForm.invalid) {
      return;
    }

    this.authorizationService
      .logIn(this.loginForm.value)
      .pipe(
        switchMap(data => {
          this.authorizationData = data;
          return this.checkRegistrationStatus(data);
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  checkRegistrationStatus(data: Authorization): Observable<any> {
    const { status } = data;
    switch (status) {
      case Status2FA.AUTHENTICATED:
        return this.getUserProfile(data);

      case Status2FA.TWOFACTORREGISTER:
        this.loginStatus = Status2FA.TWOFACTORREGISTER;
        return EMPTY;

      case Status2FA.TWOFACTOR:
        this.loginStatus = Status2FA.TWOFACTOR;
        return EMPTY;

      default:
        this.toastrService.error('Something went wrong. Please try again later.');
        return EMPTY;
    }
  }

  getUserProfile(data: Authorization): Observable<any> {
    return of(null).pipe(
      tap(() => {
        this.authorizationService.setToken(data.token);
        this.authorizationService.setSessionToken(data.session);
        this.warehouseService.setWarehouseId(this.loginForm.get('warehouse').value);
        this.pusherService.connect();
        this.pusherService.unsubscribe(this.localStorageService.getItem('connectedChannel'));
        this.everyDayLogoutService.setLastLoginDate();
      }),
      switchMap(() => this.authorizationService.getUserProfile()),
      tap(user => {
        this.localStorageService.setItem('loggedUser', user);
        const { id, username, email } = user as UserModel;
        Sentry.setUser({ ip_address: '{{auto}}', id: id.toString(), username, email });
      }),
      switchMap(() => this.settings.getSettingValue('user_feedback')),
      tap(user_feedback => this.localStorageService.setItem('errorUserFeedback', user_feedback)),
      switchMap(() => this.settings.getSettingValue('user_autologout')),
      tap(logoutTime => {
        this.localStorageService.setItem('autologoutTime', logoutTime || 0);
        this.authorizationService.idleUserLogoutTimer();
      }),
      switchMap(() => this.aclApiService.getMePermissions()),
      tap(({ items }: List<MePermissions>) => {
        this.authorizationService.setPrivileges(items[0].permissions);
        this.router.navigateByUrl('');
        this.broadcastService.connect();
        this.sessionAnalyzerService.init();
      }),
      takeUntil(this.componentDestroy()),
    );
  }

  twoFactorRegisterAction() {
    if (this.twoFactorForm.valid) {
      this.authorizationService
        .twoFactorRegister(this.twoFactorForm.value.code, this.authorizationData.token)
        .pipe(
          switchMap(data => {
            this.authorizationData = data;
            return this.checkRegistrationStatus(data);
          }),
          takeUntil(this.componentDestroy()),
        )
        .subscribe();
    }
  }

  twoFactorLoginAction() {
    if (this.twoFactorForm.valid) {
      this.authorizationService
        .twoFactorAuthenticate(this.twoFactorForm.value.code, this.authorizationData.token)
        .pipe(
          switchMap(data => {
            this.authorizationData = data;
            return this.checkRegistrationStatus(data);
          }),
          takeUntil(this.componentDestroy()),
        )
        .subscribe();
    }
  }
}
