import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ProductSeriesModel } from '../../models/product/product-series.model';
import { AssignedItemsActionsEnum } from '../../../orders/models/assigned-items-actions.enum';
import { ComponentDestroy, TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExpireDataModalInterface } from '../../../orders/models/expire-data-modal.interface';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-expire-date-modal-order-item',
  templateUrl: 'change-expire-date-modal-order-item.component.html',
  styleUrls: ['./change-expire-date-modal-order-item.component.scss'],
})
@TakeUntilDestroy
export class ChangeExpireDateModalOrderItemComponent implements OnInit, OnDestroy, ComponentDestroy {
  @Output() onSave = new EventEmitter();

  quantity = new FormControl([1, Validators.required]);

  maxQuantity = 1;

  selectedSeries: ProductSeriesModel;

  assignedItemsActionsEnum = AssignedItemsActionsEnum;

  componentDestroy: () => Observable<void>;

  constructor(
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: ExpireDataModalInterface,
    public dialogRef: MatDialogRef<ChangeExpireDateModalOrderItemComponent>,
  ) {}

  ngOnInit(): void {
    if (this.data.assignedItemProduct) {
      this.selectedSeries = this.data.assignedItemProduct;
      const availableSeries = this.data.series.find(series => series.seriesId === this.selectedSeries.seriesId);
      this.maxQuantity = this.selectedSeries.quantity + (availableSeries ? availableSeries.quantity : 0);
    } else {
      this.selectedSeries = this.data.series[0];
      this.maxQuantity = this.selectedSeries?.quantity;
    }

    // @ts-ignore
    this.quantity.patchValue(this.data.type === this.assignedItemsActionsEnum.CREATE ? 1 : this.data.currentQuantity);
  }

  ngOnDestroy() {}

  changeDate() {
    this.maxQuantity = this.selectedSeries.quantity;
    // @ts-ignore
    this.quantity.patchValue(this.quantity.value > this.maxQuantity ? this.maxQuantity : this.quantity.value);
  }

  save(): void {
    this.onSave.emit({
      quantity: this.quantity.value,
      seriesId: this.selectedSeries.seriesId,
    });

    this.dialogRef.close();
  }
}
