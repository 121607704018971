export enum ReportsComponents {
  DELIVERY = 'delivery',
  PICK = 'collecting',
  REPLENISHMENT = 'replenishment',
  PACK = 'packing',
  LABELING = 'sticker',
  GOODSMOVEMENT = 'goods-movement',
  TRANSFERREQUEST = 'transfer-request',
  RETURNS = 'returns',
  HANDLINGPALLETS = 'handling-pallets',
  ADDITIONAL = 'additional',
  OTHERS = 'others',
  PIM = 'pim',
  ORDERS = 'orders',
  LOCATIONS = 'locations',
  MASTERPALLETS = 'masterpallets',
  INVENTORYCHECK = 'inventoryCheck',
}
