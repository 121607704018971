import { HostListener, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowHelpersService {
  @HostListener('window:resize')
  currentWindowWidth() {
    return window.innerWidth;
  }
}
