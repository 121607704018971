import { Component, Input } from '@angular/core';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { result } from 'lodash';
import { TableAlign } from '../../models/tableAlign.enum';
import { TableCellType } from '../../models/tableCellType.enum';
import { TableCell } from '../../models/tableCell.model';
import { TableExtraRow } from '../../models/tableExtraRow.model';
import { WindowHelpersService } from '../../../../core/helpers/window-helpers.service';
import { TableHeaders } from '../../models/tableHeaders.model';
import { TableMobileCustom } from '../../models/tableMobileCustom.model';

@Component({
  selector: 'app-table-row, [tableRow]',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
})
export class TableRowComponent {
  @Input() headers: TableHeaders[];

  @Input() cells: TableCell[];

  @Input() extraRow: TableExtraRow;

  @Input() data: any[];

  @Input() ornament = true;

  @Input() className: string;

  @Input() mobileCustomTable: TableMobileCustom;

  @Input() loading = false;

  tableAlign = TableAlign;

  TableCellType = TableCellType;

  tippyProps: NgxTippyProps = {
    arrow: true,
    placement: 'bottom',
    allowHTML: true,
    appendTo: 'parent',
    interactive: true,
    interactiveBorder: 50,
    trigger: 'click',
  };

  constructor(public windowHelpers: WindowHelpersService) {}

  getValue(object, key: string) {
    return key === '' ? object : result(object, key);
  }

  changeCollapse(object, key: string) {
    this.data.forEach(row => (row !== object ? (row[key] = false) : row[key]));
    object[key] = !object[key];
  }
}
