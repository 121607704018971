import { ApiService } from 'src/app/core/api.service';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from 'src/environments/api-routes';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { List } from 'src/app/core/models/list';
import { Filters } from 'src/app/core/services/list/models/Filters';
import { Notification } from '../models/notification.model';
import { Replenishment } from '../models/replenishment.model';
import { GenericObject } from '../../utils/models/generic-object';
import { ReplenishmentLevel } from '../../settings/models/replenishment-level';

@Injectable({
  providedIn: 'root',
})
export class ReplenishmentApiService {
  private apiRoutes = apiRoutes;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  takeReplenishment(replenishmentId: number, quantity: number): Observable<any> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.replenishment.action.take.replace('{replenishmentId}', replenishmentId.toString()),
      ),
      {
        quantity,
      },
    );
  }

  replenish(replenishmentId: number, quantity: number): Observable<any> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.replenishment.action.replenish.replace(
          '{replenishmentId}',
          replenishmentId.toString(),
        ),
      ),
      {
        quantity,
      },
    );
  }

  getUnassignedReplenishment(filters: Filters): Observable<List<Replenishment>> {
    return this.http.get<List<Replenishment>>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.status.unassigned, filters),
    );
  }

  getRelatedReplenishment(filters: Filters, genericFilters): Observable<List<Replenishment>> {
    return this.http.get<List<Replenishment>>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.status.unassigned, filters, genericFilters),
    );
  }

  getAssignedReplenishment(filters: Filters): Observable<List<Replenishment>> {
    return this.http.get<List<Replenishment>>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.status.assigned, filters),
    );
  }

  getNewReplenishment(filters: Filters): Observable<List<Replenishment>> {
    return this.http.get<List<Replenishment>>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.status.new, filters),
    );
  }

  getDoneReplenishment(filters: Filters): Observable<List<Replenishment>> {
    return this.http.get<List<Replenishment>>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.status.done, filters),
    );
  }

  postReserveReplenishment(replenishments: number[] | number): Observable<any> {
    return this.http.post(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.status.reserved, null, { replenishments }),
      {},
    );
  }

  getReservedReplenishment(filters: GenericObject): Observable<List<Replenishment>> {
    return this.http.get<List<Replenishment>>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.status.reserved, null, filters),
    );
  }

  deleteReservedReplanishment(replenishmentId: number): Observable<List<Notification>> {
    return this.http.delete<List<Notification>>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.status.reserved, null, {
        replenishments: replenishmentId,
      }),
    );
  }

  putProduct(id: number): Observable<void> {
    return this.http.put<void>(this.apiService.getUrl(this.apiRoutes.inventory.replenishment.put), { id });
  }

  getReplenishmentLevels(): Observable<List<ReplenishmentLevel>> {
    return this.http.get<List<ReplenishmentLevel>>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.replenishmentLevel),
    );
  }

  removeReplenishmentLevel(id: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.replenishment.singleReplenishmentLevel.replace('{id}', id.toString()),
      ),
    );
  }

  updateReplenishmentLevel(id: number, payload): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.replenishment.singleReplenishmentLevel.replace('{id}', id.toString()),
      ),
      payload,
    );
  }

  createReplenishmentLevel(payload): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.replenishment.replenishmentLevel),
      payload,
    );
  }
}
