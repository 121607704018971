export enum DefaultMobileTableType {
  replenishmentUnassigned = 'replenishmentUnassigned',
  replenishmentAssigned = 'replenishmentAssigned',
  replenishmentNew = 'replenishmentNew',
  replenishmentDone = 'replenishmentDone',
  replenishmentBlockedOrders = 'replenishmentBlockedOrders',
  replenishmentReservedByEmployee = 'replenishmentReservedByEmployee',
  replenishmentReservedByEmployeePutAway = 'replenishmentReservedByEmployeePutAway',
  collecting = 'collecting',
  collected = 'collected',
  pack = 'pack',
  specialPacking = 'specialPacking',
  specialPackingUnassigned = 'specialPackingUnassigned',
  transferRequest = 'transferRequest',
  transferRequestDone = 'transferRequestDone',
  transferRequestAssigned = 'transferRequestAssigned',
  transferRequestReserved = 'transferRequestReserved',
  transferRequestReservedToTake = 'transferRequestReservedToTake',
  handlingPallets = 'handlingPallets',
  masterpallet = 'masterpallet',
  locationVerification = 'locationVerification',
}
