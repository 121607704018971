import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShippingAddressInterface } from '../../../orders/models/shipping-address.interface';
import { ToastrService } from 'ngx-toastr';
import { ShippingMethodEnum } from '../../../orders/models/shipping-method.enum';

@Component({
  selector: 'app-change-shipping-address-modal',
  templateUrl: './change-shipping-address-modal.component.html',
  styleUrls: ['./change-shipping-address-modal.component.scss'],
})
export class ChangeShippingAddressModalComponent implements OnInit {
  @Output() save = new EventEmitter();

  shippingMethodEnum = ShippingMethodEnum;

  form: FormGroup = this.formBuilder.group({
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    companyName: [null],
    line1: [null, [Validators.required]],
    line2: [null],
    city: [null, [Validators.required]],
    zip: [null, [Validators.required]],
    country: [null, [Validators.required]],
    phone: [null, [Validators.required]],
    email: [null],
    boxName: [null],
  });

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { shippingAddress: ShippingAddressInterface; shippingMethod: string },
    public dialogRef: MatDialogRef<ChangeShippingAddressModalComponent>,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    if (this.data?.shippingAddress) this.form.patchValue(this.data?.shippingAddress);
  }

  onSave(): void {
    if (!this.form.valid) {
      this.toastr.error('Fill all required form fields!');
      return;
    } else {
      this.save.emit(this.form.value);
      this.dialogRef.close();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
