import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Filters, Filters as MikuFilters } from './list/models/Filters';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { apiRoutes } from '../../../environments/api-routes';
import { List } from '../models/list';
import { ManufacturerInterface as Manufacturer } from '../models/manufacturer.interface';
import { SelectModel } from '../../utils/miku-select/select.model';
import { SortDirection } from './list/models/sort-direction.enum';
import { Order } from './list/models/Order';
import { Paginator } from './list/models/Paginator';

@Injectable({
  providedIn: 'root',
})
export class ManufacturerService {
  private apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getManufacturers(filters: MikuFilters): Observable<List<Manufacturer>> {
    return this.http.get<List<Manufacturer>>(this.apiService.getMikuUrl(this.apiRoutes.pim.manufacturer.root, filters));
  }

  getManufacturerSelectList(): Observable<SelectModel<Manufacturer>[]> {
    return this.getManufacturers(
      new Filters([], new Order(['name', SortDirection.ASC], []), new Paginator(1, 9999)),
    ).pipe(
      map(({ items }: List<Manufacturer>) => [
        new SelectModel<Manufacturer>({ id: null } as Manufacturer, 'Any'),
        ...items.map((el: Manufacturer) => new SelectModel<Manufacturer>(el, el.name)),
      ]),
      shareReplay(),
    );
  }
}
