import { Injectable } from '@angular/core';
import { GenericObject } from '../../utils/models/generic-object';
import { Observable, Subject } from 'rxjs';
import { LocalStorageActionsEnum } from '../enums/local-storage-actions.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  _watch = new Subject<string>();

  setItem(key: string, value: GenericObject | string | number | boolean): void {
    localStorage.setItem(key, JSON.stringify(value));
    this._watch.next(LocalStorageActionsEnum.added);
  }

  getItem<T>(key: string): T & (GenericObject | string | number | boolean) {
    return JSON.parse(localStorage.getItem(key));
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
    this._watch.next(LocalStorageActionsEnum.removed);
  }

  clear(): void {
    localStorage.clear();
  }

  key(index: number): string {
    return localStorage.key(index);
  }

  watch(): Observable<any> {
    return this._watch.asObservable();
  }
}
