import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Conditions, FilterField } from './FilterField';
import { FieldTypesEnum } from './field-types.enum';
import { MikuGenericObject } from '../../../../utils/models/miku-generic-object';
import { AutocompleteModel } from '../../../../utils/autocomplete/autocomplete.model';

export class AutocompleteFilterField extends FilterField {
  availableOptions$: (query: string) => Observable<AutocompleteModel[]>;

  valueControl = new FormControl({ id: null });

  constructor(
    name: string,
    label: string,
    availableOptions$: (query: string) => Observable<AutocompleteModel[]>,
    value: MikuGenericObject<number> = null,
  ) {
    super(name, label, FieldTypesEnum.AUTOCOMPLETE);
    this.availableOptions$ = query => availableOptions$(query);
    this.setCondition(Conditions.LIKE);
    if (value !== null) {
      this.patchValue(value);
    }
  }

  patchValue(v: any): FilterField {
    this.valueControl.patchValue(v);
    return this;
  }

  setValidators(): FilterField {
    console.warn(`setValidators not implemented ${new Error().stack.split('\n')[2].trim()}`);
    return this;
  }

  isValid(): boolean {
    return true;
  }

  clear(): void {
    this.patchValue({ id: null });
  }

  get fieldControl$(): Observable<FormControl> {
    return new BehaviorSubject<FormControl>(this.valueControl).asObservable();
  }
}
