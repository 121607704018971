import { Item } from './item.model';
import { Store } from './store.model';
import { Warehouse } from './warehouse.model';
import { Meta } from '../../core/models/meta';

export class Order {
  createdAt: string;

  id = 0;

  items: Item[];

  number: number;

  status: string;

  store: Store;

  type: string;

  warehouse: Warehouse;

  warehouseId?: number;

  warehouseCode?: string;

  numberOfProducts?: number;

  overallQuantity?: number;

  meta?: Meta;

  detailedBoxPacking: boolean;

  shippingMethod: string;

  totalWeight: number;

  statistics?: { itemsPercentageCollecting: number; itemsPercentagePacking: number };
}
