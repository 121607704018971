import { ApiService } from 'src/app/core/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiRoutes } from 'src/environments/api-routes';
import { Filters } from 'src/app/core/models/filters';
import { Filters as MikuFilters } from 'src/app/core/services/list/models/Filters';
import { List } from 'src/app/core/models/list';
import { Location } from '../models/location.model';
import { Product } from '../../PIM/models/product.model';
import { ProductLocationMaxQty } from '../models/product-location-max-qty';
import { LocationType } from '../models/type.model';
import { LocationInventoryModel } from '../models/location-inventory.model';
import { MikuGenericObject } from '../../utils/models/miku-generic-object';
import { LocationProduct } from '../models/location-product.model';
import { PathItem } from '../../utils/location-path-editor/models/path-item.model';
import { LocationInterface } from '../models/location.interface';

@Injectable({
  providedIn: 'root',
})
export class LocationApiService {
  private apiRoutes = apiRoutes;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  getStorableLocation(barcode: string): Observable<Location> {
    return this.http.get<Location>(
      this.apiService.getUrl(this.apiRoutes.storage.location.storable).replace('{barcode}', barcode),
    );
  }

  uploadNewPath(warehouseId: number, base64: string): Observable<void> {
    return this.http.put<void>(
      this.apiService.getUrl(
        this.apiRoutes.storage.warehouse.reloadPickingPath.replace('{warehouse}', warehouseId.toString()),
      ),
      { base64 },
    );
  }

  getPickingPath(warehouseId: number): Observable<List<PathItem>> {
    return this.http.get<List<PathItem>>(
      this.apiService
        .getMikuUrl(this.apiRoutes.storage.location.pickingPath.list)
        .replace('{warehouse}', warehouseId.toString()),
    );
  }

  getAvailablePaths(warehouseId: number): Observable<List<PathItem>> {
    return this.http.get<List<PathItem>>(
      this.apiService
        .getMikuUrl(this.apiRoutes.storage.location.pickingPath.toAdd)
        .replace('{warehouse}', warehouseId.toString()),
    );
  }

  mikuGetLocations(filters: MikuFilters, deleted = false): Observable<List<Location>> {
    const params = {};
    if (deleted) params['withDeleted'] = deleted;
    return this.http.get<List<Location>>(this.apiService.getMikuUrl(this.apiRoutes.storage.location.root, filters, params));
  }

  getLocationByBarcode(barcode: string): Observable<Location> {
    return this.http.get<Location>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.barcode.replace('{barcode}', barcode)),
    );
  }

  getLocationsByWarehouse(filters: MikuFilters, warehouseId: number): Observable<List<Location>> {
    return this.http.get<List<Location>>(
      this.apiService.getMikuUrl(
        this.apiRoutes.settings.warehouseLocations.replace('{warehouseId}', warehouseId.toString()),
        filters,
      ),
    );
  }

  getLocation(id: number): Observable<LocationInterface> {
    return this.http.get<LocationInterface>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.id.replace('{id}', id?.toString())),
    );
  }

  getLocationTypes(filters: MikuFilters): Observable<List<LocationType>> {
    return this.http.get<List<LocationType>>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.type.root, filters),
    );
  }

  //@todo: change return type to List<DefaultLocationProductInterface>
  getDefaultProductLocation(locationId: number, filters?: Filters): Observable<List<Product>> {
    return this.http.get<List<Product>>(
      this.apiService.getUrl(
        this.apiRoutes.storage.location.defaultProducts.replace('{id}', locationId.toString()),
        filters,
      ),
    );
  }

  getLocationFromWarehouse(warehouseId: number, locationId: number): Observable<Location> {
    if (warehouseId && locationId) {
      return this.http.get<Location>(
        this.apiService.getMikuUrl(
          this.apiRoutes.storage.location.fromWarehouse
            .replace('{warehouseId}', warehouseId.toString())
            .replace('{locationId}', locationId.toString()),
        ),
      );
    }
  }

  getProductLocationMaxQty(locationId: number, filters?: Filters): Observable<List<ProductLocationMaxQty>> {
    return this.http.get<List<ProductLocationMaxQty>>(
      this.apiService.getUrl(
        this.apiRoutes.storage.location.maximumQuantity.replace('{id}', locationId.toString()),
        filters,
      ),
    );
  }

  getLocationType(id: number): Observable<LocationType> {
    return this.http.get<LocationType>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.type.idParam.replace('{id}', id.toString())),
    );
  }

  updateLocation(locationForm: LocationInterface, id: number): Observable<LocationInterface> {
    return this.http.put<LocationInterface>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.id.replace('{id}', id.toString())),
      locationForm,
    );
  }

  createLocation(locationForm: LocationInterface): Observable<LocationInterface> {
    return this.http.post<LocationInterface>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.root),
      locationForm,
    );
  }

  deleteLocation(id: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.id.replace('{id}', id.toString())),
    );
  }

  getLocationProducts(
    id: number,
    filters: MikuFilters,
    genericFilters: MikuGenericObject<any> = null,
  ): Observable<List<LocationProduct>> {
    return this.http.get<List<LocationProduct>>(
      this.apiService.getMikuUrl(
        this.apiRoutes.storage.location.inventory.root.replace('{id}', id.toString()),
        filters,
        genericFilters,
      ),
    );
  }

  locationProductInventory(
    locationId: number,
    productId: number,
    filters: MikuFilters,
  ): Observable<List<LocationInventoryModel>> {
    return this.http.get<List<LocationInventoryModel>>(
      this.apiService.getMikuUrl(
        this.apiRoutes.storage.location.inventory.productId
          .replace('{id}', locationId.toString())
          .replace('{productId}', productId.toString()),
        filters,
      ),
    );
  }

  updatePickingPath(locations: Location[], warehouseId: number): Observable<void> {
    return this.http.put<void>(
      this.apiService
        .getMikuUrl(this.apiRoutes.storage.location.pickingPath.root)
        .replace('{warehouse}', warehouseId.toString()),
      {
        locations,
      },
    );
  }

  resetPickingPath(warehouseId: number): Observable<void> {
    return this.http.put<void>(
      this.apiService
        .getMikuUrl(this.apiRoutes.storage.location.pickingPath.reset)
        .replace('{warehouse}', warehouseId.toString()),
      {},
    );
  }

  getUnassignedPalletPath(warehouse: number): Observable<List<PathItem>> {
    return this.http.get<List<PathItem>>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.palletPath.getUnassignedLocations, null, {
        warehouse,
      }),
    );
  }

  getAssignedPalletPath(warehouse: number): Observable<List<PathItem>> {
    return this.http.get<List<PathItem>>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.palletPath.getAssignedLocations, null, {
        warehouse,
      }),
    );
  }

  resetPalletPath(warehouse: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.palletPath.root, null, {
        warehouse,
      }),
    );
  }

  updatePalletPath(barcodes: string[], warehouse: number): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(this.apiRoutes.storage.location.palletPath.root, null, { warehouse }),
      {
        barcodes,
      },
    );
  }
}
