import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../core/api.service';
import { apiRoutes } from '../../../environments/api-routes';
import { SettingsDataInterface } from '../models/settings-data.interface';

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService {
  apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getWarehouseSettings(warehouseId: number): Observable<SettingsDataInterface> {
    return this.http.get<SettingsDataInterface>(
      this.apiService.getMikuUrl(this.apiRoutes.settings.warehouse.replace('{warehouseId}', warehouseId.toString())),
    );
  }

  setWarehouseSettings(settings: SettingsDataInterface, warehouseId: number): Observable<void> {
    return this.http.patch<void>(
      this.apiService.getMikuUrl(this.apiRoutes.settings.warehouse.replace('{warehouseId}', warehouseId.toString())),
      {
        settings,
      },
    );
  }

  getDefaultSettings(): Observable<SettingsDataInterface> {
    return this.http.get<SettingsDataInterface>(this.apiService.getMikuUrl(this.apiRoutes.settings.default));
  }

  getPublicSettings(warehouseId: number): Observable<SettingsDataInterface> {
    return this.http.get<SettingsDataInterface>(
      this.apiService.getMikuUrl(
        this.apiRoutes.settings.warehousePublic.replace('{warehouseId}', warehouseId.toString()),
      ),
    );
  }
}
