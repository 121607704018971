import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplace',
})
export class StringReplacePipe implements PipeTransform {
  transform(value: string, splitSeparator: string, joinSeparator: string): string {
    if (!value || !splitSeparator || !joinSeparator) {
      return value;
    }

    return value.replace(new RegExp(splitSeparator, 'g'), joinSeparator);
  }
}
