import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'datepicker-embedded',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.scss'],
})
export class DatepickerComponent implements OnInit {
  @Input() public date: Date = new Date();
  @Output() dateEmitter = new EventEmitter<Date>();

  public selectors: string[] = ['year', 'month', 'day'];
  public time: boolean;

  public name = '';

  public ngOnInit() {
    this.dateEmitter.emit(this.date);
  }

  public setName(name: string): void {
    this.name = name;
  }

  public setWithTime(withTime: boolean): void {
    this.time = withTime;
  }

  public setSelectors(selectors: string[] = ['year', 'month', 'day']): void {
    this.selectors = selectors;
  }

  public next(part: string): void {
    switch (part) {
      case 'year':
        this.date.setFullYear(this.date.getFullYear() + 1);
        break;
      case 'month':
        this.date.setMonth(this.date.getMonth() + 1);

        break;
      case 'day':
        this.date.setDate(this.date.getDate() + 1);

        break;
      case 'hour':
        this.date.setHours(this.date.getHours() + 1);

        break;
      case 'minute':
        this.date.setMinutes(this.date.getMinutes() + 1);

        break;
    }

    this.dateEmitter.emit(this.date);
  }

  public previous(part: string): void {
    switch (part) {
      case 'year':
        this.date.setFullYear(this.date.getFullYear() - 1);

        break;
      case 'month':
        this.date.setMonth(this.date.getMonth() - 1);

        break;
      case 'day':
        this.date.setDate(this.date.getDate() - 1);

        break;
      case 'hour':
        this.date.setHours(this.date.getHours() - 1);

        break;
      case 'minute':
        this.date.setMinutes(this.date.getMinutes() - 1);

        break;
    }

    this.dateEmitter.emit(this.date);
  }

  public addLeadingZeros(value: number): string {
    if (value < 10) {
      return '0' + value;
    }

    return value.toString();
  }
}
