const env = require('../../.env.json');

export const environment = {
  production: env.production || true,
  hmr: false,
  wms_api_url: env.wms_api_url,
  envName: env.envName || 'local.unknown',
  sentryDsn: env.sentryDsn || 'https://fdcfbada78684ee3b7de7e00691b6b34@sentry.advox.pl/7',
  pusher: {
    app_key: env.pusher.app_key,
    host: env.pusher.host || null,
    cluster: env.pusher.cluster || 'eu',
    port: env.pusher.port || null,
    forceTLS: env.pusher.forceTLS || true,
    encrypted: env.pusher.encrypted || true,
    disableStats: env.pusher.disableStats || true,
    enabledTransports: env.pusher.enabledTransports || null,
    user_auth_endpoint: env.pusher.user_auth_endpoint || 'api/pusher/user-auth',
    channel_auth_endpoint: env.pusher.channel_auth_endpoint || 'api/pusher/auth',
  },
};
