import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UtilsModule } from './utils/utils.module';
import { SecurityModule } from './security/security.module';
import '../style/warehouse.scss';
import { TokenInterceptor } from './security/services/token.interceptor';
import { CoreModule } from './core/core.module';
import { SentryErrorHandlerService } from './core/services/sentry-error-handler.service';
import { FiltersModule } from './utils/filters/filters.module';
import { MATERIAL_MOMENT_DATE_FORMATS } from './core/configuration/MATERIAL_MOMENT_DATE_FORMATS';
import { MikuMenuModule } from './miku-menu/miku-menu.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks } from 'ng-lazyload-image';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';

export function toastrConfigFactory(): any {
  return {
    timeOut: 6000,
    extendedTimeOut: 4000,
    preventDuplicates: true,
    maxOpened: 3,
    progressBar: true,
    closeButton: true,
  };
}

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MikuMenuModule,
    ToastrModule.forRoot(toastrConfigFactory()),
    AppRoutingModule,
    UtilsModule,
    CoreModule,
    SecurityModule,
    NgSelectModule,
    FiltersModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMomentDateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    LazyLoadImageModule,
    BreadcrumbsModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  exports: [UtilsModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_MOMENT_DATE_FORMATS },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
  ],
})
export class AppModule {}
