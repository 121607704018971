import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { MenuItem } from '../../models/menu-item';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  @Input() menuItem: MenuItem;

  isActive$: Observable<boolean>;

  constructor(private router: Router, private menuService: MenuService) {}

  ngOnInit(): void {
    this.isActive$ = this.router.events.pipe(
      filter(v => v instanceof NavigationEnd),
      map((v: NavigationEnd) => {
        if (!this.menuItem.children) {
          return this.menuItem.link === v.url;
        }
        return this.menuItem.children.some(el => el.link === v.url);
      }),
    );
  }

  clickMenuItem(): void {
    this.menuService.menuClicked();
  }
}
