export enum FieldTypesEnum {
  TEXT = 'text',
  RANGE_NUMBER = 'range_number',
  DATEPICKER = 'datepicker',
  RANGE_DATEPICKER = 'range_datepicker',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  DEFAULT_COMPONENT = 'default_component',
  AUTOCOMPLETE = 'autocomplete',
}
