import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-package-box-modal',
  templateUrl: './change-package-box-modal.component.html',
})
export class ChangePackageBoxModalComponent {
  @Output() onSave = new EventEmitter();

  boxName = this.data;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: string,
    private dialogRef: MatDialogRef<ChangePackageBoxModalComponent>,
  ) {}

  save(): void {
    this.onSave.emit(this.boxName === '' ? null : this.boxName);
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
