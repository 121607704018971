import { Component, Input, OnInit } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent {
  @Input() title: string;

  @Input() class: string;

  @Input() maxHeight: number = null;

  @Input() footerHeight = 0;

  @Input() withPagination = false;

  @Input() headerAdditionalComponent: ComponentType<any>;

  @Input() headerClass: String;
}
