import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { List } from '../../core/models/list';
import { AclGroupModel } from '../models/acl-group.model';
import { apiRoutes } from '../../../environments/api-routes';
import { GroupFormModel } from '../models/group-form.model';
import { Filters } from '../../core/services/list/models/Filters';
import { PermissionModel } from '../models/permission.model';
import { UserModel } from '../../staff/employee/models/user.model';
import { UserWarehouseGroupModel } from '../models/user-warehouse-group.model';
import { MePermissions } from '../models/me-permissions';
import { AllowedIpInterface } from '../models/allowed-ip.interface';

@Injectable({
  providedIn: 'root',
})
export class AclApiService {
  private apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getGroups(filters: Filters): Observable<List<AclGroupModel>> {
    return this.http.get<List<AclGroupModel>>(
      this.apiService.getMikuUrl(this.apiRoutes.acl.security.group.root, filters),
    );
  }

  getGroup(id: number): Observable<AclGroupModel> {
    return this.http.get<AclGroupModel>(
      this.apiService.getUrl(this.apiRoutes.acl.security.group.idParam.replace('{id}', id.toString())),
    );
  }

  createGroup(groupForm: GroupFormModel): Observable<AclGroupModel> {
    return this.http.post<AclGroupModel>(this.apiService.getUrl(this.apiRoutes.acl.security.group.root), groupForm);
  }

  updateGroup(groupForm: GroupFormModel, id: number): Observable<AclGroupModel> {
    return this.http.patch<AclGroupModel>(
      this.apiService.getUrl(this.apiRoutes.acl.security.group.idParam.replace('{id}', id.toString())),
      groupForm,
    );
  }

  deleteGroup(id: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.acl.security.group.idParam.replace('{id}', id.toString())),
    );
  }

  getPermissions(): Observable<List<PermissionModel>> {
    return this.http.get<List<PermissionModel>>(this.apiService.getMikuUrl(this.apiRoutes.acl.security.permissions));
  }

  getGroupMembers(filters: Filters, id: number): Observable<List<UserModel>> {
    return this.http.get<List<UserModel>>(
      this.apiService.getMikuUrl(this.apiRoutes.acl.security.group.members.replace('{id}', id.toString())),
    );
  }

  addGroupMember(employeeId: number, groupId: number): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.acl.employee.globalGroupId
          .replace('{employeeId}', employeeId.toString())
          .replace('{groupId}', groupId.toString()),
      ),
      {},
    );
  }

  deleteGroupMember(employeeId: number, groupId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.acl.employee.globalGroupId
          .replace('{employeeId}', employeeId.toString())
          .replace('{groupId}', groupId.toString()),
      ),
    );
  }

  getUserGroups(userId: number): Observable<List<UserWarehouseGroupModel>> {
    return this.http.get<List<UserWarehouseGroupModel>>(
      this.apiService.getMikuUrl(this.apiRoutes.acl.employee.root.replace('{employeeId}', userId.toString())),
    );
  }

  getMePermissions(): Observable<List<MePermissions>> {
    return this.http.get<List<MePermissions>>(this.apiService.getMikuUrl(this.apiRoutes.acl.me));
  }

  getIpAddressList(groupId: number): Observable<List<AllowedIpInterface>> {
    return this.http.get<List<AllowedIpInterface>>(
      this.apiService.getMikuUrl(this.apiRoutes.acl.security.ips.root.replace('{id}', groupId.toString())),
    );
  }

  addIpAddress(groupId: number, ip: string): Observable<AllowedIpInterface> {
    return this.http.post<AllowedIpInterface>(
      this.apiService.getMikuUrl(this.apiRoutes.acl.security.ips.root.replace('{id}', groupId.toString())),
      { ip },
    );
  }

  deleteIpAddress(groupId: number, rowId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.acl.security.ips.deleteAddress
          .replace('{id}', groupId.toString())
          .replace('{rowId}', rowId.toString()),
      ),
    );
  }
}
