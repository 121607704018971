import { FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormGroupHelpers {
  public disableFormGroup(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.controls[key].disable({ onlySelf: true });
    });
  }

  public disableFormControl(formGroup: FormGroup, formControlName: string): FormGroupHelpers {
    formGroup.get(formControlName).disable();
    return this;
  }

  public shouldDisplayControlErrors(formGroup: FormGroup, formControlName: string): boolean {
    if (!formGroup) {
      return false;
    }
    const f: { [key: string]: AbstractControl } = formGroup.controls;
    return (f[formControlName].touched || f[formControlName].dirty) && !!f[formControlName].errors;
  }

  public shouldDisplayControlError(formGroup: FormGroup, formControlName: string, errorName: string): boolean {
    const f: { [key: string]: AbstractControl } = formGroup.controls;
    if (!f[formControlName].errors) {
      return false;
    }
    return f[formControlName]?.errors[errorName];
  }

  public shouldDisplayFormErrors(formGroup: FormGroup, error: string): boolean {
    return formGroup?.errors?.[error];
  }

  public getFormControl(formGroup: FormGroup, formControlName: string): AbstractControl {
    return formGroup.get(formControlName);
  }

  public validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
