import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { CoreModule } from '../core/core.module';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuGridListComponent } from './components/menu-grid-list/menu-grid-list.component';
import { LayoutModule } from '../utils/layout/layout.module';
import { MenuGridItemComponent } from './components/menu-grid-item/menu-grid-item.component';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    CoreModule,
    MatMenuModule,
    LayoutModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    UtilsModule,
  ],
  declarations: [MenuListComponent, MenuItemComponent, MenuGridListComponent, MenuGridItemComponent],
  exports: [MenuListComponent, MenuGridListComponent],
})
export class MikuMenuModule {}
