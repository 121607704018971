import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InvalidAddressesInterface } from '../../../orders/models/invalid-addresses.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-invalid-address-modal',
  templateUrl: './edit-invalid-address-modal.component.html',
  styleUrls: ['./edit-invalid-address-modal.component.scss'],
})
export class EditInvalidAddressModalComponent implements OnInit {
  @Output() approve = new EventEmitter();

  addressForm: FormGroup = this.fb.group({
    street: ['', [Validators.required]],
    house: [''],
    flat: [''],
  });

  constructor(
    private dialogRef: MatDialogRef<EditInvalidAddressModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvalidAddressesInterface,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    const { street, house, flat } = this.data;
    this.addressForm.patchValue({
      street,
      house,
      flat,
    });
  }

  onApprove() {
    if (this.addressForm.valid) {
      this.approve.emit(this.addressForm.value);
      this.dialogRef.close();
    } else {
      this.toastr.error('Please fill all required fields');
    }
  }

  onReject() {
    this.dialogRef.close();
  }
}
