import { Injectable } from '@angular/core';
import { Filters } from '../../core/services/list/models/Filters';
import { TextFilterField } from '../../core/services/list/models/TextFilterField';
import { Conditions } from '../../core/services/list/models/FilterField';
import { BooleanFilterField } from '../../core/services/list/models/BooleanFilterField';
import { Order } from '../../core/services/list/models/Order';
import { SortDirection } from '../../core/services/list/models/sort-direction.enum';
import { Paginator } from '../../core/services/list/models/Paginator';

@Injectable({
  providedIn: 'root',
})
export class LocationPrickerService {
  prepareFilters(
    barcode,
    {
      typeId,
      isStorageNullable,
      isNotMasterPallet,
      canAssignProduct,
    }: { typeId?: string | number; isStorageNullable: boolean; isNotMasterPallet: boolean; canAssignProduct: boolean },
  ) {
    const filters = new Filters(
      [
        new TextFilterField('barcode', null, barcode).setVisible(false).setCondition(Conditions.LIKE),
        new BooleanFilterField('deletedAt', null, true).setVisible(false).setCondition(Conditions.NULL),
      ],
      new Order(['barcode', SortDirection.ASC], []),
      new Paginator(1, 20),
    );

    if (typeId) {
      filters.fields.push(
        new TextFilterField('typeId', null, typeId?.toString() ?? null).setVisible(false).setCondition(Conditions.EQ),
      );
    }

    if (canAssignProduct) {
      filters.fields.push(
        new BooleanFilterField('locationType.canAssignProduct', null, true)
          .setVisible(false)
          .setCondition(Conditions.EQ),
      );
    }

    return filters;
  }
}
