export type SelectModelValue<T> = T | { id: number | string; name: string };

export class SelectModel<T> {
  value: SelectModelValue<T> | string;

  label: string;

  constructor(value: SelectModelValue<T> | string, label: string) {
    this.value = value;
    this.label = label;
  }
}
