import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class MikuDatePickerService {
  constructor() {}

  /**
   * Creates array of years
   *
   * `[fromYear, fromYear + 1, ..., fromYear + poolLength]`
   *
   * @param {number} fromYear e.g. 1999
   * @param {number} poolLength How many years after fromYear
   * @returns {number[]}
   */
  public createYearPool(fromYear: number, poolLength: number): number[] {
    const yearArr = [fromYear];

    for (let i = 1; i <= poolLength; i++) {
      yearArr.push(fromYear + i);
    }

    return yearArr;
  }

  /**
   * Returns days array based on year and month
   * @param {number} year 1999
   * @param {number} monthIndex 1 for January
   * @returns {number[]}
   */
  public createDayPool(year: number, month: number): number[] {
    return Array.from(Array(moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()), (_, i) => i + 1);
  }
}
