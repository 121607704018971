import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormGroupHelpers } from '../form-group-helpers';

@Component({
  selector: 'app-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldErrorComponent {
  @Input() controlName: string;
  @Input() errorCode: string;
  @Input() formGroup: FormGroup;
  @Input() message: string;

  constructor(public formGroupHelpers: FormGroupHelpers) {}
}
