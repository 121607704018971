import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatInputModule } from '@angular/material/input';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { MatChipsModule } from '@angular/material/chips';
import { AlertComponent } from './alert/alert.component';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { BarcodeInputComponent } from './barcodeInput/barcodeInput.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { DatepickerInputComponent } from './datepicker/datepicker-input/datepickerInput.component';
import { DatepickerModalComponent } from './datepicker/datepicker-modal/datepickerModal.component';
import { DatepickerRangeComponent } from './datepicker/datepicker-range/datepickerRange.component';
import { ExportComponent } from './export/export.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { DownloadButtonComponent } from './list/download-button/download-button.component';
import { PaginationComponent } from './pagination/pagination.component';
import { UcfirstPipe } from './pipes/ucfirst.pipe';
import { PreloadComponent } from './preload/preload.component';
import { BarcodeRangeComponent } from './range/barcodeRange.component';
import { RangeComponent } from './range/range.component';
import { SelectWarehouseComponent } from './select/warehouse/warehouse.component';
import { ReplenishmentPercentageLevelComponent } from './select/replenishment-percentage-level/replenishment-percentage-level.component';
import { SortableFilter } from './sortable/sortableFilter.pipe';
import { SortableTrComponent } from './sortable/sortableTr.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { EANValidator } from './form/validators/ean.directive';
import { SKUValidator } from './form/validators/sku.directive';
import { BinarySelectComponent } from './select/binary-select/binary-select.component';
import { FormFieldErrorComponent } from './form/form-field-error/form-field-error.component';
import { LogoutComponent } from './logout/logout.component';
import { CoreModule } from '../core/core.module';
import { MikuDatePickerComponent } from './miku-date-picker/miku-date-picker.component';
import { DateTileComponent } from './miku-date-picker/date-tile/date-tile.component';
import { NumberPickerComponent } from './number-picker/number-picker.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { LocationPickerComponent } from './location-picker/location-picker.component';
import { LocationSelectModalComponent } from './location-picker/location-select-modal/location-select-modal.component';
import { InvalidEanScannedComponent } from './invalid-ean-scanned/invalid-ean-scanned.component';
import { MikuScannerFieldComponent } from './miku-scanner-field/miku-scanner-field.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { MikuAutocompleteComponent } from './miku-autocomplete/miku-autocomplete.component';
import { MikuSelectComponent } from './miku-select/miku-select.component';
import { ProductDetailsFormComponent } from './product-details-form/product-details-form.component';
import { MikuCalendarDatepickerComponent } from './miku-calendar-datepicker/miku-calendar-datepicker.component';
import { CalendarDatepickerRangeComponent } from './calendar-datepicker-range/calendar-datepicker-range.component';
import { TablePlaceholderComponent } from './placeholders/table-placeholder/table-placeholder.component';
import { RequestToJoinComponent } from './modals/request-to-join/request-to-join.component';
import { ResponseToJoinComponent } from './modals/response-to-join/response-to-join.component';
import { CreateProdcutModalComponent } from './modals/create-prodcut-modal/create-prodcut-modal.component';
import { WriteSeriesAndQuantityComponent } from './modals/write-series-and-quantity/write-series-and-quantity.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { RelatedItemsComponent } from './modals/related-items/related-items.component';
import { DecisionModalComponent } from './modals/decision-modal/decision-modal.component';
import { QuantityModalComponent } from './modals/quantity-modal/quantity-modal.component';
import { SelectItemModalComponent } from './modals/select-item-modal/select-item-modal.component';
import { LayoutZoomLevelComponent } from './layout-zoom-level/layout-zoom-level.component';
import { ProgressBarComponent } from './modals/progress-bar/progress-bar.component';
import { ChangeOrderStatusComponent } from './change-order-status/change-order-status.component';
import { MassactionSelectAllComponent } from './massaction/massaction-select-all/massaction-select-all.component';
import { MassactionSelectByFiltersComponent } from './massaction/massaction-select-by-filters/massaction-select-by-filters.component';
import { MassactionDropdownComponent } from './massaction/massaction-dropdown/massaction-dropdown.component';
import { UploadComponent } from './upload/upload.component';
import { ScanOrderNumberModalComponent } from './modals/scan-order-number-modal/scan-order-number-modal.component';
import { ProductRelatedOrdersModalComponent } from './modals/product-related-orders-modal/product-related-orders-modal.component';
import { ImportProductsFromCsvComponent } from './import-products-from-csv/import-products-from-csv.component';
import { TableComponent } from './table/table.component';
import { TableCustomComponentComponent } from './table/components/table-custom-component/table-custom-component.component';
import { TableRowComponent } from './table/components/table-row/table-row.component';
import { TableHeadersComponent } from './table/components/table-headers/table-headers.component';
import { MonitoringMobileTableComponent } from './table-mobile-components/monitoring-mobile-table/monitoring-mobile-table.component';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { NewVersionModalComponent } from './modals/new-version-modal/new-version-modal.component';
import { ButtonHideTableComponent } from './button-hide-table/button-hide-table.component';
import { DefaultMobileTableComponent } from './table-mobile-components/default-mobile-table/default-mobile-table.component';
import { PackingMobileTableHeaderComponent } from './table-mobile-components/packing/packing-mobile-table-header/packing-mobile-table-header.component';
import { PackingActionsModalComponent } from './modals/packing-actions-modal/packing-actions-modal.component';
import { ActionProgressInfoComponent } from './action-progress-info/action-progress-info.component';
import { CollectingActionsModalComponent } from './modals/collecting-actions-modal/collecting-actions-modal.component';
import { ShowProductModalComponent } from './modals/show-product-modal/show-product-modal.component';
import { LocationPathEditorComponent } from './location-path-editor/location-path-editor.component';
import { HandlingPalletsActionModalComponent } from './modals/handling-pallets-action-modal/handling-pallets-action-modal.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { DocumentListModalComponent } from './modals/document-list-modal/document-list-modal.component';
import { ActionsCellComponent } from './modals/document-list-modal/table-cells/actions-cell/actions-cell.component';
import { DocumentViewModalComponent } from './modals/document-view-modal/document-view-modal.component';
import { AddActivityModalComponent } from './modals/add-activity-modal/add-activity-modal.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { CreatePackageSizeModalComponent } from './modals/create-package-size-modal/create-package-size-modal.component';
import { ChoosePackageSizeModalComponent } from './modals/choose-package-size-modal/choose-package-size-modal.component';
import { NumberOfPackagesModalComponent } from './modals/number-of-packages-modal/number-of-packages-modal.component';
import { NumberSpinnerComponent } from './number-spinner/number-spinner.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { InfoCardRowComponent } from './info-card/components/info-card-row/info-card-row.component';
import { AddProductSeriesModalComponent } from './modals/add-product-series-modal/add-product-series-modal.component';
import { AddProductModalComponent } from './modals/add-product-modal/add-product-modal.component';
import { ImportOrderFromCsvComponent } from './import-order-from-csv/import-order-from-csv.component';
import { InventoryCheckRequestModalComponent } from './modals/inventory-check-request-modal/inventory-check-request-modal.component';
import { ImportLocationFromCsvComponent } from './import-location-from-csv/import-location-from-csv.component';
import { InfoCardFooterComponent } from './info-card/components/info-card-footer/info-card-footer.component';
import { VersionInfoComponent } from './version-info/version-info.component';
import { ReturnAllOrderModalComponent } from './modals/return-all-order-modal/return-all-order-modal.component';
import { EditReturnCellComponent } from './modals/return-all-order-modal/tabl-cells/edit-return-cell/edit-return-cell.component';
import { LeaveProcessModalComponent } from './modals/leave-process-modal/leave-process-modal.component';
import { AddDeliveryExpectedItemModalComponent } from './modals/add-expected-item/add-delivery-expected-item-modal.component';
import { DeliveryVerifyItemModalComponent } from './modals/delivery/delivery-verify-item-modal/delivery-verify-item-modal.component';
import { ChangeShippingAddressModalComponent } from './modals/change-shipping-address-modal/change-shipping-address-modal.component';
import { EditInvalidAddressModalComponent } from './modals/edit-invalid-address-modal/edit-invalid-address-modal.component';
import { AddAllowedIpModalComponent } from './modals/add-allowed-ip-modal/add-allowed-ip-modal.component';
import { StayOnPageModalComponent } from './modals/stay-on-page-modal/stay-on-page-modal.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AddWarehouseToProductComponent } from './modals/add-warehouse-to-product/add-warehouse-to-product.component';
import { CheckLocationMobileTableComponent } from './table-mobile-components/check-location-mobile-table/check-location-mobile-table.component';
import { AddInventoryCheckRequestModalComponent } from './modals/add-inventory-check-request-modal/add-inventory-check-request-modal.component';
import { SpecialPackingTypeModalComponent } from './modals/special-packing-type-modal/special-packing-type-modal.component';
import { AddCommentModalComponent } from './modals/add-comment-modal/add-comment-modal.component';
import { PackingSelectPackageModalComponent } from './modals/packing-select-package-modal/packing-select-package-modal.component';
import { ChooseQuantityModalComponent } from './modals/choose-quantity-modal/choose-quantity-modal.component';
import { ImageLazyLoadComponent } from './image-lazy-load/image-lazy-load.component';
import { CheckProductMobileTableComponent } from './table-mobile-components/check-product-mobile-table/check-product-mobile-table.component';
import { SelectModalComponent } from './modals/shared/select-modal/select-modal.component';

@NgModule({
  providers: [DragulaService],
  imports: [
    FormsModule,
    RouterModule,
    NgSelectModule,
    CommonModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    MatButtonModule,
    CoreModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatListModule,
    MatTableModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    NgxTippyModule,
    DragulaModule,
    MatChipsModule,
    LazyLoadImageModule,
    NgOptimizedImage,
  ],
  declarations: [
    SortableTrComponent,
    SortableFilter,
    PreloadComponent,
    AutofocusDirective,
    ConfirmComponent,
    AlertComponent,
    PaginationComponent,
    FilterButtonComponent,
    DatepickerComponent,
    DatepickerInputComponent,
    DatepickerModalComponent,
    DatepickerRangeComponent,
    BarcodeInputComponent,
    ExportComponent,
    SelectWarehouseComponent,
    LocationPickerComponent,
    RangeComponent,
    ReplenishmentPercentageLevelComponent,
    DownloadButtonComponent,
    BarcodeRangeComponent,
    SpinnerComponent,
    UcfirstPipe,
    EANValidator,
    BinarySelectComponent,
    SKUValidator,
    FormFieldErrorComponent,
    LogoutComponent,
    MikuDatePickerComponent,
    DateTileComponent,
    NumberPickerComponent,
    UploadButtonComponent,
    LocationPickerComponent,
    LocationSelectModalComponent,
    InvalidEanScannedComponent,
    MikuScannerFieldComponent,
    DeleteModalComponent,
    MikuAutocompleteComponent,
    MikuSelectComponent,
    ProductDetailsFormComponent,
    MikuCalendarDatepickerComponent,
    CalendarDatepickerRangeComponent,
    TablePlaceholderComponent,
    RequestToJoinComponent,
    ResponseToJoinComponent,
    WriteSeriesAndQuantityComponent,
    CreateProdcutModalComponent,
    AutocompleteComponent,
    RelatedItemsComponent,
    DecisionModalComponent,
    QuantityModalComponent,
    SelectItemModalComponent,
    LayoutZoomLevelComponent,
    ProgressBarComponent,
    ChangeOrderStatusComponent,
    MassactionSelectAllComponent,
    MassactionSelectByFiltersComponent,
    MassactionDropdownComponent,
    UploadComponent,
    ScanOrderNumberModalComponent,
    ProductRelatedOrdersModalComponent,
    ImportProductsFromCsvComponent,
    TableComponent,
    TableCustomComponentComponent,
    TableRowComponent,
    TableHeadersComponent,
    MonitoringMobileTableComponent,
    InfoTooltipComponent,
    NewVersionModalComponent,
    ButtonHideTableComponent,
    DefaultMobileTableComponent,
    PackingMobileTableHeaderComponent,
    PackingActionsModalComponent,
    ActionProgressInfoComponent,
    CollectingActionsModalComponent,
    ShowProductModalComponent,
    LocationPathEditorComponent,
    HandlingPalletsActionModalComponent,
    MultiselectComponent,
    DocumentListModalComponent,
    ActionsCellComponent,
    DocumentViewModalComponent,
    CreatePackageSizeModalComponent,
    ChoosePackageSizeModalComponent,
    AddActivityModalComponent,
    BarcodeComponent,
    CreatePackageSizeModalComponent,
    AddActivityModalComponent,
    ChoosePackageSizeModalComponent,
    NumberOfPackagesModalComponent,
    NumberSpinnerComponent,
    InfoCardComponent,
    InfoCardRowComponent,
    AddProductSeriesModalComponent,
    AddProductModalComponent,
    ImportOrderFromCsvComponent,
    InventoryCheckRequestModalComponent,
    ImportLocationFromCsvComponent,
    InfoCardFooterComponent,
    VersionInfoComponent,
    ReturnAllOrderModalComponent,
    EditReturnCellComponent,
    LeaveProcessModalComponent,
    AddDeliveryExpectedItemModalComponent,
    DeliveryVerifyItemModalComponent,
    ChangeShippingAddressModalComponent,
    EditInvalidAddressModalComponent,
    AddAllowedIpModalComponent,
    AddWarehouseToProductComponent,
    StayOnPageModalComponent,
    CheckLocationMobileTableComponent,
    AddInventoryCheckRequestModalComponent,
    SpecialPackingTypeModalComponent,
    AddCommentModalComponent,
    PackingSelectPackageModalComponent,
    ChooseQuantityModalComponent,
    ImageLazyLoadComponent,
    CheckLocationMobileTableComponent,
    CheckProductMobileTableComponent,
    SelectModalComponent,
  ],
  exports: [
    SortableTrComponent,
    PreloadComponent,
    AutofocusDirective,
    ConfirmComponent,
    AlertComponent,
    PaginationComponent,
    FilterButtonComponent,
    DatepickerInputComponent,
    DatepickerRangeComponent,
    ExportComponent,
    SelectWarehouseComponent,
    LocationPickerComponent,
    RangeComponent,
    ReplenishmentPercentageLevelComponent,
    DownloadButtonComponent,
    BarcodeRangeComponent,
    BarcodeInputComponent,
    SpinnerComponent,
    UcfirstPipe,
    EANValidator,
    BinarySelectComponent,
    SKUValidator,
    FormFieldErrorComponent,
    LogoutComponent,
    MikuDatePickerComponent,
    NumberPickerComponent,
    UploadButtonComponent,
    LocationPickerComponent,
    ProductDetailsFormComponent,
    MikuAutocompleteComponent,
    MikuSelectComponent,
    MikuCalendarDatepickerComponent,
    CalendarDatepickerRangeComponent,
    TablePlaceholderComponent,
    AutocompleteComponent,
    LayoutZoomLevelComponent,
    ChangeOrderStatusComponent,
    MassactionSelectAllComponent,
    MassactionSelectByFiltersComponent,
    MassactionDropdownComponent,
    UploadComponent,
    ScanOrderNumberModalComponent,
    ProductRelatedOrdersModalComponent,
    ImportProductsFromCsvComponent,
    TableComponent,
    TableRowComponent,
    MonitoringMobileTableComponent,
    ButtonHideTableComponent,
    ActionProgressInfoComponent,
    LocationPathEditorComponent,
    MultiselectComponent,
    InfoTooltipComponent,
    BarcodeComponent,
    MikuScannerFieldComponent,
    InfoCardComponent,
    InfoCardRowComponent,
    NumberSpinnerComponent,
    ImportOrderFromCsvComponent,
    ImportLocationFromCsvComponent,
    InfoCardFooterComponent,
    VersionInfoComponent,
    AddDeliveryExpectedItemModalComponent,
    DeliveryVerifyItemModalComponent,
    ImageLazyLoadComponent,
  ],
  entryComponents: [DatepickerComponent, DatepickerModalComponent],
})
export class UtilsModule {}
