import { MenuItem } from './models/menu-item';

export const menuItems: MenuItem[] = [
  {
    label: 'pick',
    link: '/pick',
    icon: 'fa fa-search fa-fw',
    acl: ['order.collect'],
  },
  {
    label: 'pack',
    link: '/pack',
    icon: 'fa fa-cube fa-fw',
    acl: ['order.pack'],
  },
  {
    label: 'labeling',
    link: '/labeling',
    icon: 'fa fa-sticky-note fa-fw',
    acl: ['sticker.list'],
  },
  {
    label: 'deliveries',
    link: '/delivery',
    icon: 'fa fa-truck fa-fw',
    acl: ['delivery.list'],
  },
  {
    label: 'transfer request',
    link: '/transfer-request',
    icon: 'fa fa-refresh fa-fw',
    acl: ['transfer_request.list'],
  },
  {
    label: 'goods movement',
    link: '/goods-movement',
    icon: 'fa fa-refresh fa-fw',
    acl: ['goods_movement.handle'],
  },
  {
    label: 'replenishing',
    link: '/replenishment',
    icon: 'fa fa-refresh fa-fw',
    acl: ['replenishment.list'],
  },
  {
    label: 'returns',
    link: '/returns',
    icon: 'fa fa-undo fa-fw',
    acl: ['return.handle', 'return.create'],
  },
  {
    label: 'handling pallets',
    link: '/handling-pallets',
    icon: 'fa fa-th-large',
    acl: ['forklift_operator_tasks.list'],
  },
  {
    label: 'PIM',
    link: '/pim',
    icon: 'fa fa-folder-o fa-fw',
    acl: ['product.list'],
  },
  {
    label: 'orders',
    link: '/orders',
    icon: 'fa fa-shopping-basket fa-fw',
    acl: ['order.list'],
  },
  {
    label: 'storage',
    icon: 'fa fa-cubes fa-fw',
    acl: [],
    children: [
      {
        label: 'locations',
        link: '/locations',
        icon: 'fa fa-database fa-fw',
        acl: ['location.list'],
      },
      {
        label: 'warehouse',
        link: '/warehouse',
        icon: 'fa fa-share-alt fa-fw',
        acl: ['warehouse.list'],
      },
      {
        label: 'masterpallet',
        link: '/masterpallets',
        icon: 'fa fa-cube fa-fw',
        acl: ['masterpallet.list'],
      },
    ],
  },
  {
    label: 'inventory check',
    link: '/inventory-check',
    icon: 'fa fa-check-square-o fa-fw',
    acl: ['inventory_check.handle'],
  },
  {
    label: 'documents',
    link: '/document',
    icon: 'fa fa-check-square-o fa-fw',
    acl: ['document.list'],
  },
  {
    label: 'employees',
    link: '/staff',
    icon: 'fa fa-users fa-fw',
    acl: ['employee.list'],
  },
  {
    label: 'reports',
    link: '/reports',
    icon: 'fa fa-line-chart fa-fw',
    acl: ['reports.list'],
  },
  {
    label: 'report activity',
    link: '/report-activity',
    icon: 'fa fa-flag',
    acl: [],
  },
  {
    label: 'statistics',
    link: '/statistics',
    icon: 'fa fa-bar-chart fa-fw',
    acl: ['reports.list'],
  },
  {
    label: 'monitoring',
    link: '/monitoring',
    icon: 'fa fa-book fa-book fa-fw',
    acl: ['logs.list'],
  },
  {
    label: 'access control list',
    link: '/acl',
    icon: 'fa fa-lock fa-fw',
    acl: ['system.settings.update'],
  },
  {
    label: 'label printer',
    link: '/label-printer',
    icon: 'fa fa-print fa-fw',
    acl: ['logs.list'],
  },
  {
    label: 'settings',
    link: '/settings',
    icon: 'fa fa-cog fa-fw',
    acl: ['system.settings.update'],
  },
];
