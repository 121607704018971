import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterService } from './filter-button.service';

@Component({
  selector: 'app-filter-button',
  templateUrl: 'filter-button.component.html',
})
export class FilterButtonComponent {
  @Input() public showForm: boolean;
  @Output() public showFormChange: EventEmitter<boolean> = new EventEmitter();

  public buttonIsActive: Observable<boolean> = this.filterService.ifFiltersAreActive();

  constructor(private filterService: FilterService) {}

  public toggleFilters(): void {
    this.showForm = !this.showForm;

    this.showFormChange.emit(this.showForm);
  }
}
