import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from '../../models/menu-item';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-menu-grid-list',
  templateUrl: './menu-grid-list.component.html',
  styleUrls: ['./menu-grid-list.component.scss'],
})
export class MenuGridListComponent implements OnInit {
  menuItems$: Observable<MenuItem[]>;

  constructor(private menuService: MenuService) {}

  ngOnInit(): void {
    this.menuItems$ = this.menuService.getMenuItems$();
  }
}
