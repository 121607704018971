import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NumberHelpersService {
  convertBooleanToNumber(value: boolean): number {
    return value ? 1 : 0;
  }

  convertNumberToBoolean(value: number | string): boolean {
    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }
    return value === 1;
  }
}
