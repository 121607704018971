import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-binary-select',
  templateUrl: 'binary-select.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: BinarySelectComponent, multi: true }],
})
export class BinarySelectComponent implements ControlValueAccessor {
  @Input() public name: string = 'yes-no-select-default';
  @Input() public required: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public onChange: (res: number) => void = (res: number) => {};

  public currentValue: number;
  public options = [
    {
      value: 1,
      label: 'Yes',
    },
    {
      value: 0,
      label: 'No',
    },
  ];

  public propagateChange: (_: any) => void = (_: any) => {};

  get value(): number {
    return this.currentValue;
  }

  public writeValue(currentValue: number) {
    this.currentValue = currentValue;
  }

  public registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void): void {}

  public Changed() {
    this.propagateChange(this.currentValue);
    this.onChange(this.currentValue);
  }
}
