import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { LocalStorageService } from '../../core/services/local-storage.service';

@Component({
  selector: 'app-layout-zoom-level',
  templateUrl: './layout-zoom-level.component.html',
  styleUrls: ['./layout-zoom-level.component.scss'],
})
export class LayoutZoomLevelComponent implements OnInit, AfterViewInit {
  @Input() componentName: string;

  @Output() currentZoomLevel = new EventEmitter();

  zoomLevel: number;

  savedZoomLevel: number;

  defaultZoomLevel = 10;

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.savedZoomLevel = parseInt(this.localStorageService.getItem<string>(`ZoomLevel${this.componentName}`));
    this.zoomLevel = this.savedZoomLevel ? this.savedZoomLevel : this.defaultZoomLevel;
  }

  ngAfterViewInit() {
    this.emitZoomLevel();
  }

  incrementZoomLevel(): void {
    this.zoomLevel++;
    this.localStorageService.setItem(`ZoomLevel${this.componentName}`, `${this.zoomLevel}`);
    this.emitZoomLevel();
  }

  decrementZoomLevel(): void {
    if (this.zoomLevel > 3) {
      this.zoomLevel--;
      this.localStorageService.setItem(`ZoomLevel${this.componentName}`, `${this.zoomLevel}`);
      this.emitZoomLevel();
    }
  }

  emitZoomLevel() {
    this.currentZoomLevel.emit(this.zoomLevel);
  }
}
