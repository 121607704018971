import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SkuValidatorService {
  static REGEX = '^(P\\d+)$';

  public validate(code: string): boolean {
    return !!code.match(SkuValidatorService.REGEX);
  }
}
