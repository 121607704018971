import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, fromEvent, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { TakeUntilDestroy } from 'src/app/core/decorators/take-until-destroy';

@Component({
  selector: 'app-number-picker',
  templateUrl: './number-picker.component.html',
  styleUrls: ['./number-picker.component.scss'],
})
@TakeUntilDestroy
export class NumberPickerComponent implements OnInit, OnDestroy {
  componentDestroy: () => Observable<void>;

  @ViewChild('input') input: ElementRef;

  @Input() label: string;

  @Input() valueControl: FormControl;

  @Input() maxValue: number;

  @Input() minValue: number;

  @Input() maxValueCallback: Observable<void>;

  @Input() focusAfterInit = false;

  private destroy$: Subject<void> = new Subject();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    fromEvent(this.input?.nativeElement, 'focusout')
      .pipe(
        switchMap(() => {
          const v = this.valueControl.value;
          if (this.minValue && v < this.minValue) {
            this.valueControl.patchValue(this.minValue);
            return of(null);
          }
          if (this.maxValue && v > this.maxValue) {
            this.valueControl.patchValue('');
            return this.maxValueCallback;
          }
          return of(null);
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();

    if (this.focusAfterInit) this.input?.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public decrement(event: Event): void {
    event.stopPropagation();
    const v = this.valueControl.value;
    if (+v === NaN) {
      return;
    }
    this.valueControl.patchValue(v - 1 < this.minValue ? this.minValue : v - 1);
  }

  public increment(event: Event): void {
    event.stopPropagation();
    const v = this.valueControl.value;
    if (+v === NaN) {
      return;
    }
    this.valueControl.patchValue(v + 1 > this.maxValue ? this.maxValue : v + 1);
  }
}
