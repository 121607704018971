import { ScanInputAction } from './ScanInputAction';

export class BarcodeInput {
  public _name: string;

  public _actions: ScanInputAction[] = [];

  public _id: string;

  constructor(name: string) {
    this.name = name;
    this.id = `barcodeInput_${name}`;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get actions(): ScanInputAction[] {
    return this._actions;
  }

  set actions(actions: ScanInputAction[]) {
    this._actions = actions;
  }

  get nativeElement(): any {
    return document.getElementById(this._id);
  }

  setAction(action: ScanInputAction): void {
    this.actions.push(action);
  }

  getActionBySubject(name: string): ScanInputAction {
    return this.actions.find(
      (action: ScanInputAction, index: number, array: ScanInputAction[]) => action.subject === name,
    );
  }
}
