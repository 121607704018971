import { FilterField } from './FilterField';
import { Order } from './Order';
import { Paginator } from './Paginator';

export class Filters {
  fields: FilterField[] = [];

  order: Order;

  paginator: Paginator;

  constructor(initFields: FilterField[], order: Order, paginator: Paginator) {
    this.fields = initFields;
    this.order = order;
    this.paginator = paginator;
  }

  setFilter(key: string, v: FilterField): void {
    const index = this.fields.findIndex(el => el.key === key);
    if (index > -1) {
      this.fields[index] = v;
    } else {
      this.fields.push(v);
    }
  }
}
