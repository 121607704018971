import { Injectable } from '@angular/core';
import Smartlook from 'smartlook-client';
import { environment } from '../../../environments/environment';
import { AuthorizationService } from '../../security/services/authorization.service';
import { LocalStorageService } from './local-storage.service';
import { WarehouseService } from './warehouse.service';
import { versionInfo } from '../../../../version-info';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SettingsService } from '../../settings/services/settings.service';
import { Subject } from 'rxjs';
import { ServiceDestroyed } from '../decorators/take-until-destroy';
import { NumberHelpersService } from '../helpers/number-helpers.service';

@Injectable({
  providedIn: 'root',
})
export class SessionAnalyzerService implements ServiceDestroyed {
  serviceDestroyed = new Subject<void>();

  isEnabled = true;

  constructor(
    private authorizationService: AuthorizationService,
    private localStorageService: LocalStorageService,
    private warehouseService: WarehouseService,
    private settingsService: SettingsService,
    private numberHelpers: NumberHelpersService,
  ) {}

  init(): void {
    this.settingsService
      .getSettingValue('record_activity')
      .pipe(
        tap(
          record_activity =>
            (this.isEnabled = this.numberHelpers.convertNumberToBoolean(parseInt(record_activity, 10))),
        ),
        switchMap(() => this.settingsService.getSettingValue('smartlook_key')),
        tap(key => {
          if (this.isEnabled && key && environment.production) {
            this.prepareInit(key);
          }
        }),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  prepareInit(smartlookKey: string) {
    let displayMode = 'pwa';
    if (window.matchMedia('(display-mode: browser)').matches) {
      displayMode = 'browser';
    }
    const user = this.authorizationService.getUser();

    // @ts-ignore
    Smartlook.identify(user?.id, {
      // @ts-ignore
      username: user?.username,
      deviceId: this.localStorageService.getItem('deviceId'),
      warehouse: this.warehouseService?.warehouseId,
      appVersion: versionInfo?.tag,
      displayMode,
      // @ts-ignore
      userId: user?.id,
    });

    Smartlook.init(smartlookKey, {
      advancedNetwork: {
        websockets: true,
      },
    });

    console.info(
      'Smartlook initialized',
      // @ts-ignore
      user?.id,
      // @ts-ignore
      user?.username,
      this.warehouseService?.warehouseId,
      versionInfo?.tag,
      displayMode,
    );
  }

  disconnect(): void {
    Smartlook.disable();
  }
}
