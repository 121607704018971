import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuantityModal } from './quantityModal.model';

@Component({
  selector: 'app-quantity-modal',
  templateUrl: './quantity-modal.component.html',
})
export class QuantityModalComponent implements OnInit {
  @Output() confirm = new EventEmitter();

  @Output() reject = new EventEmitter();

  quantity: number;

  constructor(
    public dialogRef: MatDialogRef<QuantityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuantityModal,
  ) {}

  ngOnInit() {
    this.quantity = this.data.quantity;
  }

  onConfirm() {
    this.confirm.emit(this.quantity);
    this.dialogRef.close();
  }

  onReject() {
    this.reject.emit();
    this.dialogRef.close();
  }

  onQuantityChange(value: number) {
    this.quantity = value;
  }
}
