import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DecisionModal } from './decisionModal.model';

@Component({
  selector: 'app-decision-modal',
  templateUrl: './decision-modal.component.html',
})
export class DecisionModalComponent {
  @Output() onApprove = new EventEmitter();

  @Output() onReject = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<DecisionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DecisionModal,
  ) {}

  approve() {
    this.onApprove.emit();
    this.dialogRef.close();
  }

  reject() {
    this.onReject.emit();
    this.dialogRef.close();
  }
}
