import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-special-packing-type-modal',
  templateUrl: './special-packing-type-modal.component.html',
})
export class SpecialPackingTypeModalComponent implements OnInit {
  @Output() onSplitBoxes = new EventEmitter();
  @Output() onPackAsOneBox = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<SpecialPackingTypeModalComponent>) {}

  ngOnInit(): void {}

  splitBoxes() {
    this.onSplitBoxes.emit();
    this.dialogRef.close();
  }

  packAsOneBox() {
    this.onPackAsOneBox.emit();
    this.dialogRef.close();
  }
}
