import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-new-version-modal',
  templateUrl: './new-version-modal.component.html',
})
export class NewVersionModalComponent {
  @Output() onReload = new EventEmitter();

  reload(): void {
    this.onReload.emit();
  }
}
