import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-allowed-ip-modal',
  templateUrl: './add-allowed-ip-modal.component.html',
})
export class AddAllowedIpModalComponent implements OnInit {
  @Output() approve = new EventEmitter();

  allowedIp = new FormControl('', { validators: Validators.required });

  constructor(private dialogRef: MatDialogRef<AddAllowedIpModalComponent>) {}

  ngOnInit(): void {}

  onApprove() {
    if (this.allowedIp.valid) {
      this.approve.emit(this.allowedIp.value);
      this.dialogRef.close();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
