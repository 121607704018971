import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { MenuItem } from '../models/menu-item';
import { menuItems } from '../menu-data';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuItems = new BehaviorSubject<MenuItem[]>(menuItems);

  private shouldCloseMenu: Subject<void> = new Subject<void>();

  private _closeMenu = new Subject<void>();

  constructor(private breakpointObserver: BreakpointObserver) {}

  getMenuItems$(): Observable<MenuItem[]> {
    return this.menuItems.asObservable();
  }

  menuClicked(): void {
    this.shouldCloseMenu.next();
  }

  closeMenu(): void {
    this._closeMenu.next();
  }

  shouldCloseMenu$(): Observable<boolean> {
    return this.shouldCloseMenu.pipe(map(() => this.breakpointObserver.isMatched('(max-width: 1024px)')));
  }

  closeMenu$(): Observable<void> {
    return this._closeMenu.asObservable();
  }
}
