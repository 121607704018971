import { Injectable } from '@angular/core';
import { filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, Subject, iif, of } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AssignedItems } from '../models/assigned-items.model';
import { ConfirmService } from '../../utils/confirm/confirm.service';
import { SeriesApiService } from '../../utils/services/series-api.service';
import { ServiceDestroyed } from '../../core/decorators/take-until-destroy';
import { AssignedItemsActionsEnum } from '../models/assigned-items-actions.enum';
import { ProductSeriesModel } from '../../utils/models/product/product-series.model';
import { ChangeExpireDateModalOrderItemComponent } from '../../utils/modals/change-expire-date-modal-order-item/change-expire-date-modal-order-item.component';
import { OrderApiService } from './order-api.service';
import { Order } from '../models/order.model';
import { Item } from '../models/item.model';
import { Filters } from '../../core/services/list/models/Filters';
import { List } from '../../core/models/list';
import { Stock } from '../models/stock.model';
import { Replenishment } from '../../replenishment/models/replenishment.model';
import { ShippingInfoInterface } from '../models/shipping-info.interface';
import { ShippingDocumentInterface } from '../models/shipping-document.interface';
import { apiRoutes } from '../../../environments/api-routes';
import { ExportService } from '../../utils/export/export.service';
import { MonitoringApiService } from '../../monitoring/services/monitoring-api.service';
import { MonitoringLog } from '../../monitoring/models/monitoring-log.model';
import { DocumentTypeEnum } from '../models/document-type.enum';
import { DeliveryInfoInterface } from '../models/delivery-info.interface';
import { FiltersService } from '../../utils/filters/services/filters.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangeShippingAddressModalComponent } from '../../utils/modals/change-shipping-address-modal/change-shipping-address-modal.component';
import { PackageApiService } from '../../utils/services/package/package-api.service';
import { InvalidAddressesInterface } from '../models/invalid-addresses.interface';
import { ConvertedAddressPayloadInterface } from '../models/converted-address-payload.interface';
import { Product } from '../models/product.model';
import { ShippingAddressInterface } from '../models/shipping-address.interface';
import { ChangePackageBoxModalComponent } from '../components/order-view/change-package-box/change-package-box-modal/change-package-box-modal.component';

@Injectable({
  providedIn: 'root',
})
export class OrderService implements ServiceDestroyed {
  serviceDestroyed = new Subject<void>();

  apiRoutes = apiRoutes;

  order: Order = new Order();

  ordersList;

  itemsToChange: Item[] = [];

  blockedReplenishments: Replenishment[];

  shippingInfo: ShippingInfoInterface;

  orderLogs: MonitoringLog[] = [];

  unresolvedInvalidAddresses: InvalidAddressesInterface[] = [];

  resolvedInvalidAddresses: InvalidAddressesInterface[] = [];

  shippingDocuments: {
    invoices: { items: ShippingDocumentInterface[]; downloaded: boolean };
    pickingLists: { items: ShippingDocumentInterface[]; downloaded: boolean };
  } = {
    invoices: { items: [], downloaded: false },
    pickingLists: { items: [], downloaded: false },
  };

  constructor(
    private confirmService: ConfirmService,
    private seriesApiService: SeriesApiService,
    private toastr: ToastrService,
    public modalService: BsModalService,
    private orderApiService: OrderApiService,
    private exportService: ExportService,
    private monitoringApiService: MonitoringApiService,
    private filtersService: FiltersService,
    private dialog: MatDialog,
    private packageApiService: PackageApiService,
  ) {}

  getOrdersList(initializedFilters: Filters): Observable<Order> {
    return this.orderApiService.getOrders(initializedFilters).pipe(
      tap(({ items }) => {
        this.ordersList = items.map(item => ({
          ...item,
          isAction: false,
          numberOfPackages: item?.packages?.length,
          orderWeight: item?.packages?.reduce((acc, curr) => acc + curr.weight, 0),
        }));
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  createActionArray(): void {
    this.itemsToChange = this.ordersList.filter(order => order.isAction === true);
  }

  getOrder(id: number): Observable<Order> {
    return this.orderApiService.getOrder(id).pipe(takeUntil(this.serviceDestroyed));
  }

  getOrders(id: number): Observable<void | Order> {
    return this.orderApiService.getOrder(id).pipe(
      switchMap(order => {
        return this.getOrderAvailableStock(id).pipe(
          map(({ items }) => {
            this.order = {
              ...order,
              items: order.items.map(item => ({
                ...item,
                orderId: order.id,
                status: order.status,
                assignedItems: item.assignedItems.map(assignedItem => ({
                  ...assignedItem,
                  stock: items.filter(stock => stock.product.seriesId === assignedItem.product.seriesId)[0],
                })),
                trBackgroundColor: this.validProduct(item),
              })),
            };
          }),
        );
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  getOrderAvailableStock(id: number): Observable<List<Stock>> {
    return this.orderApiService.orderAvailableStock(id);
  }

  validStock = (item: AssignedItems) => item.stock.availableQuantity < item.stock.quantity;

  deleteAssignedItem(e: Event, orderId: number, itemId: number, assignedItemId: number) {
    e.stopPropagation();
    this.confirmService
      .show('Do you want to remove this item?')
      .pipe(
        filter(decision => decision === true),
        switchMap(() =>
          this.seriesApiService.deleteAssignedItem(orderId, itemId, assignedItemId).pipe(
            tap(() => this.toastr.success('Order item has been deleted')),
            switchMap(() => this.getOrders(orderId)),
          ),
        ),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  modifyAssignedItems(
    e: Event,
    type: AssignedItemsActionsEnum,
    orderId: number,
    productId: number = null,
    itemId: number = null,
    assignedItemId: number = null,
    currentQuantity: number = null,
    assignedItemProduct?: ProductSeriesModel | Product,
  ) {
    e.stopPropagation();
    this.seriesApiService
      .getAvailableStocks(productId)
      .pipe(
        switchMap(({ items: series }) => {
          if (series.length < 1 && type === AssignedItemsActionsEnum.CREATE) {
            this.toastr.error('No available stock!');
            return EMPTY;
          }

          const dialogRef = this.dialog.open(ChangeExpireDateModalOrderItemComponent, {
            width: 'auto',
            maxHeight: '90vh',
            data: {
              series,
              orderId,
              itemId,
              productId,
              type,
              currentQuantity,
              assignedItemId,
              assignedItemProduct,
            },
          });

          return dialogRef.componentInstance.onSave.pipe(
            switchMap(data => {
              switch (type) {
                case AssignedItemsActionsEnum.CREATE:
                  return this.seriesApiService
                    .createAssignedItem(orderId, itemId, data)
                    .pipe(tap(() => this.toastr.success('Order item has been added')));

                case AssignedItemsActionsEnum.UPDATE:
                  return this.seriesApiService
                    .updateAssignedItem(orderId, itemId, assignedItemId, data)
                    .pipe(tap(() => this.toastr.success('Order item has been updated')));

                default:
                  return EMPTY;
              }
            }),
            switchMap(() => this.getOrders(orderId)),
            takeUntil(this.serviceDestroyed),
          );
        }),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  changeOrderStatus(orderId: number, status: string): void {
    this.orderApiService
      .changeOrderStatus(orderId, status)
      .pipe(
        tap(() => {
          this.toastr.success('Saved');
        }),
        switchMap(() => this.getOrders(orderId)),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  validProduct(item: Item): string {
    let dateValid = true;
    if (item.product.expiresAt && item.assignedItems.length > 0) {
      item.assignedItems.forEach(assignedItem => {
        if (item.product.expiresAt !== assignedItem.product.expiresAt) {
          dateValid = false;
        }
      });
    }
    return !(item.assignedQuantity === item.quantity && dateValid) ? `var(--warning)` : null;
  }

  getBlockedReplenishments(orderId: number): Observable<List<Replenishment>> {
    return this.orderApiService.getBlockedReplenishments(orderId).pipe(
      tap(({ items }) => {
        items.map(item => (item.orderId = orderId));
        this.blockedReplenishments = items;
      }),
    );
  }

  getShippingInfo(orderId: number = this.order.id): Observable<ShippingInfoInterface> {
    return this.orderApiService.getShippingInfo(orderId).pipe(tap(shippingInfo => this.mapShippingInfo(shippingInfo)));
  }

  mapShippingInfo(shippingInfo: ShippingInfoInterface) {
    this.shippingInfo = shippingInfo;
    this.shippingInfo.mappedPackageOrder = [];
    for (const [key, value] of Object.entries(shippingInfo?.packageOrder ?? {})) {
      value.packageOrderId = key;
      value.waybillName = `${shippingInfo?.orderNumber}-${value?.trackingNumber}`;
      this.shippingInfo.mappedPackageOrder.push(value);
    }
    this.shippingInfo.deliveryNumber = this.shippingInfo?.mappedPackageOrder.at(-1)?.deliveryNumber ?? null;
    this.shippingInfo.trackingNumber = this.shippingInfo?.mappedPackageOrder.at(-1)?.trackingNumber ?? null;
    this.shippingInfo.trackingUrl = this.shippingInfo?.mappedPackageOrder.at(-1)?.trackingUrl ?? null;
    this.shippingInfo.shipmentStatus = this.shippingInfo?.mappedPackageOrder.at(-1)?.status ?? null;
  }

  getShippingDocuments(orderId: number): Observable<List<ShippingDocumentInterface>> {
    return this.orderApiService.getShippingDocuments(orderId).pipe(
      tap(({ items }) => {
        this.mapShippingDocuments(items, this.shippingDocuments);
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  mapShippingDocuments(documents: ShippingDocumentInterface[], shippingDocuments) {
    shippingDocuments.invoices.items = (documents ?? []).filter(({ type }) => type === 'invoice');
    shippingDocuments.pickingLists.items = (documents ?? []).filter(({ type }) => type === 'picking-list');
    shippingDocuments.invoices.items.forEach(({ downloadedAt }) => {
      if (downloadedAt) {
        shippingDocuments.invoices.downloaded = true;
      }
    });
    shippingDocuments.pickingLists.items.forEach(({ downloadedAt }) => {
      if (downloadedAt) {
        shippingDocuments.pickingLists.downloaded = true;
      }
    });
  }

  downloadDocument(
    documents: ShippingDocumentInterface[] | DeliveryInfoInterface[],
    documentType: DocumentTypeEnum,
  ): Observable<File> {
    const documentsList = (documents ?? []).map(document => ({
      id: document?.id ?? document?.packageOrderId,
      name: document?.name ?? document?.trackingNumber,
    }));

    let documentsIds: number[] = [];

    if (documentsList.length <= 0) {
      this.toastr.error('There are no documents to print!');
      return of(null);
    }
    documentsIds = documentsList.map(({ id }) => id);

    switch (documentType) {
      case DocumentTypeEnum.DOCUMENT:
        return this.orderApiService
          .downloadMassDocuments(documentsIds)
          .pipe(tap(response => this.exportService.saveFile('', response, 'pdf', documentsList[0].name)));
      case DocumentTypeEnum.WAYBILL:
        return of(documentsList).pipe(
          mergeMap(res => res),
          mergeMap(({ id, name: fileName }) => this.downloadWaybillAction(id, fileName)),
          takeUntil(this.serviceDestroyed),
        );
      default:
        return of(null);
    }
  }

  getOrderLogs(filters: Filters): Observable<List<MonitoringLog>> {
    return this.monitoringApiService.getMonitoringList(filters).pipe(tap(({ items }) => (this.orderLogs = items)));
  }

  downloadWaybillAction(packageOrderId: number | string, waybillName: string): Observable<File> {
    return this.orderApiService.downloadWaybill(packageOrderId).pipe(
      tap(file => {
        this.exportService.saveFile('', file, 'pdf', waybillName);
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  downloadOrderPdf(orderId: number): void {
    this.exportService
      .getFile(this.apiRoutes.sales.order.print.replace('{id}', orderId.toString()))
      .pipe(
        tap(response => {
          this.exportService.saveFile(`order-${orderId}-`, response, 'pdf');
        }),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  downloadSpecialPackingOrderCsv(orderId: number): void {
    this.exportService
      .getFiles(this.apiRoutes.packing.specialPacking.printOrder.replace('{id}', orderId.toString()))
      .pipe(
        tap(response => {
          this.exportService.saveFile(`order-${orderId}-`, response, 'csv');
        }),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  changeSpecialPackageStatus(event: MatSlideToggleChange, orderId: number) {
    of({})
      .pipe(
        switchMap(() =>
          iif(
            () => event.checked,
            this.orderApiService
              .markSpecialPackage(orderId)
              .pipe(tap(() => this.toastr.success('Marked as special package'))),
            this.orderApiService
              .removeSpecialPackageMark(orderId)
              .pipe(tap(() => this.toastr.success('Removed special package mark'))),
          ),
        ),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  autoPackOrder(orderId: number): void {
    this.confirmService
      .show('Do you really want to warehouse movement?')
      .pipe(
        filter(confirm => confirm === true),
        switchMap(() => this.orderApiService.autoPackOrder(orderId)),
        tap(() => {
          this.toastr.success('The order has been autopack!');
          this.filtersService.emitRefreshSubject();
        }),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  changeShippingAddress(): void {
    this.confirmService
      .show('Do you really want to change shipping address?')
      .pipe(
        filter(confirm => confirm === true),
        switchMap(() =>
          this.packageApiService
            .getPackageSizesList()
            .pipe(map(({ items }) => items.filter(item => item.isDefault)[0].id)),
        ),
        switchMap(defaultPackageId => {
          const { orderShippingMethod: shippingMethod } = this.shippingInfo;
          const packageSizes = [];
          for (let i = 0; i < this.shippingInfo.packageCount; i += 1) {
            packageSizes.push(defaultPackageId);
          }

          const dialogRef = this.dialog.open(ChangeShippingAddressModalComponent, {
            width: 'auto',
            maxHeight: '90vh',
            data: { shippingAddress: this.shippingInfo?.shippingAddress, shippingMethod },
          });

          return dialogRef.componentInstance.save.pipe(
            switchMap(shippingAddress => {
              return this.orderApiService.changeShippingMethod(
                {
                  shippingAddress,
                  shippingMethod,
                  packageSizes,
                },
                this.order.id,
              );
            }),
          );
        }),
        switchMap(() => this.getShippingInfo(this.order.id)),
        tap(() => this.toastr.success('Address has been changed!')),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  getUnresolvedInvalidAddressesList(): Observable<List<InvalidAddressesInterface>> {
    return this.orderApiService.getUnresolvedInvalidAddressesList().pipe(
      tap(({ items }) => (this.unresolvedInvalidAddresses = items)),
      takeUntil(this.serviceDestroyed),
    );
  }

  getResolvedInvalidAddressesList(filters: Filters): Observable<List<InvalidAddressesInterface>> {
    return this.orderApiService.getResolvedInvalidAddressesList(filters).pipe(
      tap(({ items }) => (this.resolvedInvalidAddresses = items)),
      takeUntil(this.serviceDestroyed),
    );
  }

  mapInvalidAddress(payload: ConvertedAddressPayloadInterface): Observable<void> {
    return this.orderApiService.mapInvalidAddress(payload);
  }

  getShippingMethods(warehouseId: number): Observable<any> {
    return this.orderApiService.getShippingMethods(warehouseId).pipe(takeUntil(this.serviceDestroyed));
  }

  cancelShipment(): Observable<any> {
    return this.orderApiService.cancelShipment(this.order.id).pipe(takeUntil(this.serviceDestroyed));
  }

  refreshShipmentStatus(): Observable<any> {
    return this.orderApiService.refreshShipmentStatus(this.order.id).pipe(takeUntil(this.serviceDestroyed));
  }

  clearServiceData(): void {
    this.ordersList = null;
    this.order = new Order();
    this.itemsToChange = [];
    this.blockedReplenishments = null;
    this.shippingInfo = null;
    this.orderLogs = [];
    this.unresolvedInvalidAddresses = [];
    this.resolvedInvalidAddresses = [];
    this.shippingDocuments = {
      invoices: { items: [], downloaded: false },
      pickingLists: { items: [], downloaded: false },
    };
  }

  changePackageBox(
    shippingAddress: ShippingAddressInterface,
    orderId: number = this.order.id,
  ): Observable<ShippingInfoInterface> {
    const dialogRef = this.dialog.open(ChangePackageBoxModalComponent, {
      data: shippingAddress?.boxName,
    });

    return dialogRef.componentInstance.onSave.pipe(
      switchMap(boxName =>
        this.orderApiService.changeShippingMethod(
          {
            shippingAddress: { ...shippingAddress, boxName },
            shippingMethod: this.order.shippingMethod,
          },
          orderId,
        ),
      ),
      switchMap(() => this.getShippingInfo()),
    );
  }

  changeOrderComment(comment: string): Observable<void> {
    return this.orderApiService.changeOrderComment(this.order.id, comment).pipe(takeUntil(this.serviceDestroyed));
  }
}
