import { Component, Input } from '@angular/core';
import { TableHeaders } from './models/tableHeaders.model';
import { TableAlign } from './models/tableAlign.enum';
import { TableCell } from './models/tableCell.model';
import { TableExtraRow } from './models/tableExtraRow.model';
import { WindowHelpersService } from '../../core/helpers/window-helpers.service';
import { TableMobileCustom } from './models/tableMobileCustom.model';
import { TableMobileCustomHeader } from './models/tableMobileCustomHeader.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() headers: TableHeaders[];

  @Input() cells: TableCell[];

  @Input() extraRow: TableExtraRow;

  @Input() data: any[];

  @Input() headersData: any;

  @Input() mobileCustomHeader: TableMobileCustomHeader;

  @Input() mobileCustomTable: TableMobileCustom;

  @Input() mobileExtraRow: TableMobileCustom;

  @Input() title: string;

  @Input() class: string;

  @Input() secondColor: boolean;

  @Input() loading = false;

  tableAlign = TableAlign;

  constructor(public windowHelpers: WindowHelpersService) {}
}
