import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Conditions, FilterField } from './FilterField';
import { FieldTypesEnum } from './field-types.enum';

export class RangeNumberFilterField extends FilterField {
  fromValue = new FormControl(null);

  toValue = new FormControl(null);

  constructor(name: string, label: string, valueFrom: number = null, valueTo: number = null) {
    super(name, label, FieldTypesEnum.RANGE_NUMBER);
    this.setCondition([Conditions.GTE, Conditions.LTE]);
    if (valueFrom !== null && valueTo !== null) {
      this.patchValue(valueFrom, valueTo);
    }
  }

  patchValue(valueFrom: number, valueTo: number): FilterField {
    if (typeof valueFrom !== 'number' || typeof valueTo !== 'number') {
      console.warn(`Invalid number provided ${new Error().stack.split('\n')[2].trim()}`);
      return this;
    }

    if (valueFrom > valueTo) {
      console.warn(`Number from cannot be greater then number to ${new Error().stack.split('\n')[2].trim()}`);
      this.fromValue.patchValue(valueTo);
      this.toValue.patchValue(valueTo);
      return this;
    }
    this.fromValue.patchValue(valueFrom);
    this.toValue.patchValue(valueTo);
    return this;
  }

  setValidators(): FilterField {
    console.warn(`setValidators not implemented ${new Error().stack.split('\n')[2].trim()}`);
    return this;
  }

  isValid(): boolean {
    return true;
  }

  clear() {
    this.fromValue.patchValue(null);
    this.toValue.patchValue(null);
  }

  get fieldControl$(): Observable<FormControl[]> {
    return new BehaviorSubject<FormControl[]>([this.fromValue, this.toValue]).asObservable();
  }
}
