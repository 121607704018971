import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RelatedItem } from '../../models/related-item.model';
import { TableHeaders } from '../../table/models/tableHeaders.model';
import { TableAlign } from '../../table/models/tableAlign.enum';
import { TableCell } from '../../table/models/tableCell.model';
import { TableCellType } from '../../table/models/tableCellType.enum';

@Component({
  selector: 'app-related-items',
  templateUrl: './related-items.component.html',
  styleUrls: ['./related-items.component.scss'],
})
export class RelatedItemsComponent {
  @Output() onApproveAll = new EventEmitter();

  @Output() onApproveTheSameSeries = new EventEmitter();

  @Output() onReject = new EventEmitter();

  headers: TableHeaders[] = [
    { value: 'ID' },
    { value: 'Product' },
    { value: 'Source location', align: TableAlign.center },
    { value: 'Target location', align: TableAlign.center },
    { value: 'Serial number', align: TableAlign.center },
    { value: 'Quantity', align: TableAlign.center },
  ];

  cells: TableCell[] = [
    { type: TableCellType.text, key: 'id' },
    { type: TableCellType.text, key: 'name' },
    { type: TableCellType.text, key: 'sourceLocation', align: TableAlign.center },
    { type: TableCellType.text, key: 'targetLocation', align: TableAlign.center },
    { type: TableCellType.text, key: 'serialNumber', align: TableAlign.center },
    { type: TableCellType.text, key: 'quantity', align: TableAlign.center },
  ];

  constructor(
    public dialogRef: MatDialogRef<RelatedItemsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { items: RelatedItem[]; showTheSameSeries: boolean },
  ) {}

  approveAll() {
    this.onApproveAll.emit();
    this.dialogRef.close();
  }

  approveTheSameSeries() {
    this.onApproveTheSameSeries.emit();
    this.dialogRef.close();
  }

  reject() {
    this.onReject.emit();
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
