import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-action-progress-info',
  templateUrl: './action-progress-info.component.html',
  styleUrls: ['./action-progress-info.component.scss'],
})
export class ActionProgressInfoComponent {
  @Input() id: string | number;

  @Input() text: string;

  @Input() progress: string | number;
}
