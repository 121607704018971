import { BehaviorSubject, queueScheduler } from 'rxjs';
import { delay, observeOn, tap } from 'rxjs/operators';

export class Queue {
  name: string;

  queue$ = new BehaviorSubject([]);

  callback: (queue: any[]) => void;

  constructor(name: string, callback: (queue: any[]) => void = () => {}) {
    this.name = name;
    this.callback = callback;
  }

  add(item: () => void) {
    this.queue$.next([...this.queue$.value, item]);
  }

  process() {
    this.queue$
      .pipe(
        observeOn(queueScheduler),
        delay(2000),
        tap(queue => {
          if (queue.length > 0) this.queue$.next(queue.slice(1));
        }),
      )
      .subscribe(queue => {
        if (queue.length > 0) this.callback(queue);
      });
  }
}
