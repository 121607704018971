import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../security/services/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class AclGuard implements CanActivate {
  constructor(private router: Router, private authorizationService: AuthorizationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const acl = route.data?.acl || [];

    if (!this.authorizationService.hasAccess(acl)) {
      return this.router.parseUrl('/dashboard');
    }
    return true;
  }
}
