import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { FiltersComponent } from './components/filters/filters.component';
import { UtilsModule } from '../utils.module';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MobileFiltersBtnComponent } from './components/mobile-filters-btn/mobile-filters-btn.component';
import { CoreModule } from '../../core/core.module';

@NgModule({
  declarations: [FiltersComponent, PaginatorComponent, MobileFiltersBtnComponent],
  exports: [FiltersComponent, PaginatorComponent, MobileFiltersBtnComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatPaginatorModule,
    MatCheckboxModule,
    UtilsModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    CoreModule,
    MatChipsModule,
  ],
  providers: [
    {
      provide: 'REFRESH_LIST_SUBJECT',
      useValue: new Subject(),
    },
  ],
})
export class FiltersModule {}
