import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { RelatedOrder } from '../../../PIM/models/related-order.model';
import { PimApiService } from '../../../PIM/services/pim-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { Observable } from 'rxjs';
import { TableHeaders } from '../../table/models/tableHeaders.model';
import { TableAlign } from '../../table/models/tableAlign.enum';
import { TableCell } from '../../table/models/tableCell.model';
import { TableCellType } from '../../table/models/tableCellType.enum';

@Component({
  selector: 'app-product-related-orders-modal',
  templateUrl: './product-related-orders-modal.component.html',
})
@TakeUntilDestroy
export class ProductRelatedOrdersModalComponent implements OnInit, OnDestroy {
  componentDestroy: () => Observable<void>;

  relatedOrders: RelatedOrder[] = [];

  headers: TableHeaders[] = [
    { value: 'Number' },
    { value: 'Status', align: TableAlign.center },
    { value: 'Expires at', align: TableAlign.center },
    { value: 'Serial number', align: TableAlign.center },
    { value: 'Quantity', align: TableAlign.center },
  ];

  cells: TableCell[] = [
    { type: TableCellType.link, key: 'order.number', link: 'orders/view/$', $: 'order.id' },
    { type: TableCellType.text, key: 'order.status', align: TableAlign.center },
    { type: TableCellType.date, key: 'product.expiresAt', dateFormat: 'DD.MM.YYYY', align: TableAlign.center },
    { type: TableCellType.text, key: 'product.serialNumber', align: TableAlign.center },
    { type: TableCellType.text, key: 'quantity', align: TableAlign.center },
  ];

  constructor(
    private pimApiService: PimApiService,
    public dialogRef: MatDialogRef<ProductRelatedOrdersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.getProductRelatedOrders();
  }

  ngOnDestroy() {}

  getProductRelatedOrders(): void {
    this.pimApiService
      .getProductRelatedItems(this.data.productId)
      .pipe(
        tap(({ items }) => (this.relatedOrders = items)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  close(): void {
    this.dialogRef.close();
  }
}
