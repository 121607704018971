import { Injectable } from '@angular/core';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TransferRequestItem } from '../models/transferRequestItem.model';
import { Observable, of } from 'rxjs';
import { List } from '../../core/models/list';
import { TransferRequestApiService } from './transfer-request-api.service';
import { ConfirmService } from '../../utils/confirm/confirm.service';
import { ToastrService } from 'ngx-toastr';
import { Filters } from '../../core/services/list/models/Filters';
import { TextFilterField } from '../../core/services/list/models/TextFilterField';
import { Conditions } from '../../core/services/list/models/FilterField';
import { Order } from '../../core/services/list/models/Order';
import { SortDirection } from '../../core/services/list/models/sort-direction.enum';
import { Paginator } from '../../core/services/list/models/Paginator';
import { RelatedItemsComponent } from '../../utils/modals/related-items/related-items.component';
import { MatDialog } from '@angular/material/dialog';
import { FiltersService } from '../../utils/filters/services/filters.service';

@Injectable({
  providedIn: 'root',
})
export class TransferRequestService {
  constructor(
    private transferRequestApiService: TransferRequestApiService,
    private confirmService: ConfirmService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private filtersService: FiltersService,
  ) {}
  toTake: TransferRequestItem[] = null;

  toPut: TransferRequestItem[] = null;

  getToTake(): Observable<List<TransferRequestItem>> {
    return this.transferRequestApiService.getToTake().pipe(tap(({ items }) => (this.toTake = items)));
  }

  getToPut(): Observable<List<TransferRequestItem>> {
    return this.transferRequestApiService.getToPut().pipe(tap(({ items }) => (this.toPut = items)));
  }

  cancelReservation(id: number): Observable<any> {
    return this.confirmService.show('Do you want cancel this reserve?').pipe(
      filter(confirm => confirm),
      switchMap(() => this.transferRequestApiService.cancelReserved({ ids: [id] })),
      tap(() => {
        this.toastr.success('Reserved has been canceled');
      }),
      switchMap(() => this.getToPut()),
      switchMap(() => this.getToTake()),
    );
  }

  reserve(sourceLocationId: number, productSeriesId: number, id: number): Observable<void> {
    let theSameSeriesIds: number[] = [];

    return this.transferRequestApiService
      .getUnassigned(
        new Filters(
          [
            new TextFilterField('sourceLocation.id', 'sourceLocation.id')
              .setCondition(Conditions.EQ)
              .patchValue(sourceLocationId),
          ],
          new Order(['sourceLocation.id', SortDirection.ASC], []),
          new Paginator(1, 9999999),
        ),
      )
      .pipe(
        switchMap(({ items }) => {
          if (items.length > 0) {
            theSameSeriesIds = items
              .filter(({ product: { seriesId: id } }) => productSeriesId === id)
              .map(({ id }) => id);

            const relatedLocationsToShow = items
              .filter(({ id: itemId }) => itemId !== id)
              .map(
                ({
                  id,
                  quantity,
                  product: { name },
                  sourceLocation: { barcode: sourceLocation },
                  targetLocation: { barcode: targetLocation },
                }) => ({ id, quantity, name, sourceLocation, targetLocation }),
              );

            if (relatedLocationsToShow.length > 0) {
              const dialogRef = this.dialog.open(RelatedItemsComponent, {
                width: 'auto',
                maxHeight: '90vh',
                data: { items: relatedLocationsToShow, showTheSameSeries: theSameSeriesIds.length > 1 },
              });

              dialogRef.componentInstance.onReject
                .pipe(
                  switchMap(() =>
                    this.transferRequestApiService.reserve({ ids: [id] }).pipe(
                      tap(() => {
                        this.toastr.success('Request has been reserved!');
                        this.filtersService.emitRefreshSubject();
                      }),
                    ),
                  ),
                )
                .subscribe();

              dialogRef.componentInstance.onApproveAll
                .pipe(
                  switchMap(() =>
                    this.transferRequestApiService.reserve({ ids: items.map(({ id }) => id) }).pipe(
                      tap(() => {
                        this.toastr.success('Requests has been reserved!');
                        this.filtersService.emitRefreshSubject();
                      }),
                    ),
                  ),
                )
                .subscribe();

              dialogRef.componentInstance.onApproveTheSameSeries
                .pipe(
                  switchMap(() =>
                    this.transferRequestApiService.reserve({ ids: theSameSeriesIds }).pipe(
                      tap(() => {
                        this.toastr.success('Requests has been reserved!');
                        this.filtersService.emitRefreshSubject();
                      }),
                    ),
                  ),
                )
                .subscribe();

              return of(null);
            }
          }

          return this.confirmService.show('Do you want to reserve this request?').pipe(
            filter(decision => decision === true),
            switchMap(() => this.transferRequestApiService.reserve({ ids: [id] })),
            tap(() => {
              this.toastr.success('Request has been reserved');
              this.filtersService.emitRefreshSubject();
            }),
          );
        }),
      );
  }

  removeTransferRequest(id: number): Observable<void> {
    return this.confirmService.show('Do you want to remove this request?').pipe(
      filter(decision => decision === true),
      switchMap(() => this.transferRequestApiService.deleteTransferRequest(id)),
      tap(() => {
        this.toastr.success('Request has been deleted');
        this.filtersService.emitRefreshSubject();
      }),
    );
  }
}
