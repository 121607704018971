import { filter } from 'rxjs/operators';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { AuthorizationService } from './services/authorization.service';
import { UtilsModule } from '../utils/utils.module';
import { CoreModule } from '../core/core.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { SentryErrorHandlerService } from '../core/services/sentry-error-handler.service';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    UtilsModule,
    CoreModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    QRCodeModule,
  ],
  providers: [AuthorizationService, SentryErrorHandlerService],
  declarations: [LoginComponent],
})
export class SecurityModule {
  constructor(router: Router, authorizationService: AuthorizationService) {
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((path: NavigationStart) => {
      if (authorizationService.checkPrivilegePathForNotLoggedUser(path.url) === true) {
        return true;
      }
      if (authorizationService.isUserLoggedIn() === false) {
        router.navigateByUrl('login');
        return true;
      }
    });
  }
}
