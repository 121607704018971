import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterField } from './FilterField';
import { FieldTypesEnum } from './field-types.enum';

export class BooleanFilterField extends FilterField {
  valueControl: FormControl<boolean | string> = new FormControl(false);

  value: boolean | string;

  defaultValue = false;

  constructor(name: string, label: string, value: boolean | string = null) {
    super(name, label, FieldTypesEnum.BOOLEAN);
    this.value = value;
    if (value !== null) {
      this.patchValue(value);
    }
  }

  patchValue(value: boolean | string): FilterField {
    this.valueControl.patchValue(value);
    this.defaultValue = true;
    return this;
  }

  setValidators(): FilterField {
    console.warn(`setValidators not implemented ${new Error().stack.split('\n')[2].trim()}`);
    return this;
  }

  isValid(): boolean {
    return true;
  }

  clear(): void {
    if (this.visible === false) {
      this.patchValue(this.value);
    } else {
      this.patchValue(false);
    }
  }

  get fieldControl$(): Observable<FormControl> {
    return new BehaviorSubject<FormControl>(this.valueControl).asObservable();
  }
}
