import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ColumnInterface } from '../list/model/column.interface';
import { SortableColumn } from '../list/model/sortableColumn.model';

@Pipe({
  name: 'sortable',
})
@Injectable()
export class SortableFilter implements PipeTransform {
  transform(columns: ColumnInterface[], args: any[]): ColumnInterface[] {
    return columns.filter((column: ColumnInterface) => column instanceof SortableColumn);
  }
}
