import { MassActionPayload } from './mass-action-payload.model';

export class MassAction {
  private _name: string;

  private _action: (data: MassActionPayload, props?: unknown) => void;

  constructor(name: string, action: () => void) {
    this._name = name;
    this._action = action;
  }

  get name() {
    return this._name;
  }

  action(data: MassActionPayload, props?: unknown) {
    return this._action(data, props);
  }
}
