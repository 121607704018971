import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectItemModal } from './selectItemModal.model';

/**
 * @deprecated use Select Modal instead from shared modals
 */
@Component({
  selector: 'app-select-item-modal',
  templateUrl: './select-item-modal.component.html',
})
export class SelectItemModalComponent implements OnInit {
  @Output() onApprove = new EventEmitter();

  @Output() onReject = new EventEmitter();

  selectItem: SelectItemModal;

  constructor(
    public dialogRef: MatDialogRef<SelectItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectItemModal[],
  ) {}

  ngOnInit() {
    this.selectItem = this.data[0];
  }

  approve() {
    this.onApprove.emit(this.selectItem.value);
    this.dialogRef.close();
  }

  reject() {
    this.onReject.emit();
    this.dialogRef.close();
  }
}
