import { FormGroup, FormBuilder } from '@angular/forms';
import { switchMap, tap, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TakeUntilDestroy } from 'src/app/core/decorators/take-until-destroy';
import { Observable } from 'rxjs';
import { PimApiService } from '../../PIM/services/pim-api.service';
import { ScannerApiService } from '../services/scanner-api.service';
import { ComponentDestroy } from '../../core/decorators/take-until-destroy';

@Component({
  selector: 'app-product-details-form',
  templateUrl: './product-details-form.component.html',
  styleUrls: ['./product-details-form.component.scss'],
})
@TakeUntilDestroy
export class ProductDetailsFormComponent implements OnInit, OnDestroy, ComponentDestroy {
  componentDestroy: () => Observable<void>;

  @Input() zoomLevel: number;

  @Input() productBarcode: string;

  productForm: FormGroup;

  imgUrl: string;

  addExpectedProductForm: FormGroup = this.fb.group({
    name: [{ value: '', disabled: true }],
    ean: [{ value: '', disabled: true }],
  });

  constructor(
    private scannerApiService: ScannerApiService,
    private pimApiService: PimApiService,
    private fb: FormBuilder,
  ) {}

  getProductId() {
    if (this.productBarcode) {
      this.scannerApiService
        .getProduct(this.productBarcode)
        .pipe(
          switchMap(value => this.pimApiService.getProduct(value.id)),
          tap(({ image, ean, name }) => {
            image ? (this.imgUrl = image.url) : (this.imgUrl = '');
            this.addExpectedProductForm.patchValue({ name, ean });
          }),
          takeUntil(this.componentDestroy()),
        )
        .subscribe();
    }
  }

  ngOnInit() {
    this.getProductId();
  }

  ngOnDestroy() {}
}
