import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appCheckShortDate]',
})
export class CheckShortDateDirective implements AfterViewInit {
  @Input() shortDate: number;

  @Input() expiresAt: string;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.el?.nativeElement.classList.add('shortDate');
    if (this.shortDate) {
      const differenceInDays = (
        (new Date(this.expiresAt).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
      ).toFixed();

      if (parseInt(differenceInDays, 10) < this.shortDate) {
        this.el?.nativeElement.classList.add('error');
      }
    }
  }
}
