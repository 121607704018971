import { Injectable } from '@angular/core';
import { ReportsComponents } from '../enums/reports-components.enum';

@Injectable({
  providedIn: 'root',
})
export class ModulesService {
  getModuleByPath = path =>
    Object.keys(ReportsComponents).filter(key => path.toUpperCase().replace('-', '').includes(key.toUpperCase()))[0];
}
