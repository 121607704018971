import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { TakeUntilDestroy } from '../../core/decorators/take-until-destroy';
import { Observable } from 'rxjs';
import { ImportLocationFromCsvService } from './import-location-from-csv.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-import-location-from-csv',
  templateUrl: './import-location-from-csv.component.html',
  styleUrls: ['./import-location-from-csv.component.scss'],
})
@TakeUntilDestroy
export class ImportLocationFromCsvComponent implements OnDestroy {
  @Output() afterImport = new EventEmitter();

  @ViewChild('inputFile') inputFile: ElementRef;

  componentDestroy: () => Observable<void>;

  constructor(private importLocationFromCsvService: ImportLocationFromCsvService, private toastr: ToastrService) {}

  ngOnDestroy(): void {}

  importLocationsFromCSV(file: File): void {
    this.importLocationFromCsvService
      .importLocationsFromCsv(file)
      .pipe(
        tap(() => {
          this.toastr.success('Location has been imported!');
          this.afterImport.emit();
          this.inputFile.nativeElement.value = null;
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  getLocationCSVTemplate() {
    this.importLocationFromCsvService.getLocationCSVTemplate().pipe(takeUntil(this.componentDestroy())).subscribe();
  }
}
