import { ApiService } from 'src/app/core/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiRoutes } from 'src/environments/api-routes';
import { Observable } from 'rxjs';
import { Filters as MikuFilters } from 'src/app/core/services/list/models/Filters';
import { List } from 'src/app/core/models/list';
import { PimLevel } from 'src/app/settings/models/pim-level';
import { NewRepLevel } from '../models/new-replenishment-level.model';
import { ReplenishLevel } from '../models/replenishmentLevel.model';
import { ManufacturerList } from '../models/manufacturerList.model';
import { Product } from '../models/product.model';
import { DefaultLocation } from '../models/defaultLocation.model';
import { UpdateVariantForm } from '../models/updateVariantForm.model';
import { Filters } from '../../core/models/filters';
import { MikuGenericObject } from '../../utils/models/miku-generic-object';
import { UpdateProduct } from '../models/update-product.model';
import { CreateProduct } from '../models/create-product.model';
import { MaximumQuantity } from '../models/maximum-quantity.model';
import { Inventory } from '../models/inventory.model';
import { RelatedOrder } from '../models/related-order.model';
import { NewShortDateLevel } from '../models/new-short-date-level.model';
import { ProductDamaged } from '../models/product-damaged';
import { AddProductSeriesModel } from '../models/add-product-series.model';
import { Warehouse } from '../../orders/models/warehouse.model';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PimApiService {
  private apiRoutes = apiRoutes;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  getManufacturerList(filters: Filters): Observable<List<ManufacturerList>> {
    return this.http.get<List<ManufacturerList>>(this.apiService.getUrl(this.apiRoutes.pim.manufacturer.root, filters));
  }

  updateProduct(product: UpdateProduct, productId: number): Observable<Product> {
    return this.http.put<Product>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.product.idParam.replace('{id}', productId.toString())),
      product,
    );
  }

  createProduct(product: CreateProduct): Observable<Product> {
    return this.http.post<Product>(this.apiService.getMikuUrl(this.apiRoutes.pim.product.root), product);
  }

  getProductsDefaultLocations(productId: number): Observable<List<DefaultLocation>> {
    return this.http.get<List<DefaultLocation>>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.defaultLocation.get.replace('{productId}', productId.toString())),
    );
  }

  addDefaultLocation(productId: number, locationId: number): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.pim.defaultLocation.put
          .replace('{productId}', productId?.toString())
          .replace('{locationId}', locationId?.toString()),
      ),
      {},
    );
  }

  removeDefaultLocation(productId: number, locationId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.pim.defaultLocation.delete
          .replace('{productId}', productId.toString())
          .replace('{locationId}', locationId.toString()),
      ),
    );
  }

  putQuantityPerLocation(productId: number, locationId: number, maximumQuantity: number): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.product.maximumQuantity.putQuantityPerLocation
          .replace('{productId}', productId.toString())
          .replace('{locationId}', locationId.toString()),
      ),
      { maximumQuantity },
    );
  }

  getProducts(filters: MikuFilters): Observable<List<Product>> {
    const mappedFilters = cloneDeep(filters);
    const filterWeight = mappedFilters.fields.filter(field => field.key === 'weight')[0];
    if (filterWeight?.valueControl?.value) filterWeight.valueControl.value = filterWeight.valueControl.value * 1000;

    return this.http.get<List<Product>>(this.apiService.getMikuUrl(this.apiRoutes.pim.product.root, mappedFilters));
  }

  getProductLocations(productId: number): Observable<List<Inventory>> {
    return this.http.get<List<Inventory>>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.product.inventory.replace('{productId}', productId.toString())),
    );
  }

  getProduct(id: number): Observable<Product> {
    return this.http.get<Product>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.product.idParam.replace('{id}', id.toString())),
    );
  }

  updateVariant(product: UpdateVariantForm[]): Observable<Product> {
    return this.http.post<Product>(this.apiService.getUrl(this.apiRoutes.pim.product.update), product);
  }

  changeReplenishmentLevel(data: ReplenishLevel): Observable<ReplenishLevel> {
    return this.http.put<ReplenishLevel>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.massaction.replenishmentLevel),
      data,
    );
  }

  public changeReplenishmentLevelByFilters(data: ReplenishLevel, filters: MikuFilters): Observable<ReplenishLevel> {
    return this.http.put<ReplenishLevel>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.massaction.replenishmentLevelByFilters, filters),
      data,
    );
  }

  changeShortDateLevel(data: NewRepLevel): Observable<NewRepLevel> {
    return this.http.put<NewRepLevel>(this.apiService.getMikuUrl(this.apiRoutes.pim.massaction.shortDateLevel), data);
  }

  public shortDateLevelByFilters(data: NewShortDateLevel, filters: MikuFilters): Observable<ReplenishLevel> {
    return this.http.put<ReplenishLevel>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.massaction.shortDateLevelByFilters, filters),
      data,
    );
  }

  getShortDateLevels(): Observable<List<PimLevel>> {
    return this.http.get<List<PimLevel>>(this.apiService.getUrl(this.apiRoutes.pim.product.shortDate));
  }

  getListOfMaximumQuantity(productId: number): Observable<MikuGenericObject<MaximumQuantity>> {
    return this.http.get<MikuGenericObject<MaximumQuantity>>(
      this.apiService.getMikuUrl(
        this.apiRoutes.product.maximumQuantity.getListOfMaximumQuantity.replace('{productId}', productId.toString()),
      ),
    );
  }

  getProductRelatedItems(productId: number): Observable<List<RelatedOrder>> {
    return this.http.get<List<RelatedOrder>>(
      this.apiService.getMikuUrl(this.apiRoutes.product.assignedItems.replace('{productId}', productId?.toString())),
    );
  }

  getDamagedProducts(filters: MikuFilters): Observable<List<ProductDamaged>> {
    return this.http.get<List<ProductDamaged>>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.product.damagedProducts, filters),
    );
  }

  removeProduct(id: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.product.idParam.replace('{id}', id.toString())),
    );
  }

  addProductSeries(payload: AddProductSeriesModel): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.pim.product.addSeries), payload);
  }

  getProductByEan(ean: string): Observable<Product> {
    return this.http.get<Product>(this.apiService.getMikuUrl(this.apiRoutes.pim.product.ean.replace('{ean}', ean)));
  }

  getProductWarehouses(productId: number): Observable<List<Warehouse>> {
    return this.http.get<List<Warehouse>>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.product.warehouses.replace('{productId}', productId.toString())),
    );
  }

  addWarehouseToProduct(productId: number, warehouses: number[]): Observable<void> {
    return this.http.patch<void>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.product.warehouses.replace('{productId}', productId.toString())),
      { warehouses },
    );
  }

  removeWarehouseFromProduct(productId: number, warehouses: number[]): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.product.warehouses.replace('{productId}', productId.toString())),
      { body: { warehouses } },
    );
  }

  checkExistProductSeries(filters: MikuFilters, productId: number): Observable<any> {
    return this.http.get<any>(
      this.apiService.getMikuUrl(
        this.apiRoutes.pim.product.checkSeries.replace('{productId}', productId.toString()),
        filters,
      ),
    );
  }
}
