import { Injectable } from '@angular/core';
import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { eanRegex } from '../../barcodeInput/ean-regex';
import { GenericObject } from '../../models/generic-object';

@Injectable({
  providedIn: 'root',
})
export class FormValidationHelpersService {
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  checkPasswords(group: FormGroup) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;

    return password === confirmPassword ? null : { notSame: true };
  }

  public validateEan(formControl: FormControl): GenericObject {
    const eanMaxLength = 14;
    const addMissingZeros: (ean: string) => string = (code: string) => {
      const missingZeros = new Array(eanMaxLength - code.length).fill(0).join('');
      return missingZeros + code;
    };
    const ean = formControl.value;
    if (!ean) {
      return null;
    }

    if (!ean.match(eanRegex.lengthRegex)) {
      return { invalidEan: true };
    }

    const shortCode = addMissingZeros(ean).slice(0, -1);
    const sum = shortCode
      .split('')
      .map((x, index) => {
        return +x * (index % 2 === 0 ? 3 : 1);
      })
      .reduce((a, b) => a + b, 0);

    return (10 - (sum % 10)) % 10 === +ean[ean.length - 1] ? null : { invalidEan: true };
  }

  public validateSoft(control: FormControl): GenericObject {
    const code = control.value;
    if (!code) {
      return null;
    }
    return typeof code === 'undefined' || (code.length >= 7 && !!code.match(eanRegex.digitsRegex))
      ? null
      : { invalidSoftEan: true };
  }

  public isEanOrSkuProvidedValidator(control: FormGroup): ValidationErrors {
    const { ean, sku } = control.getRawValue();
    if (!ean && !sku) {
      return {
        invalidEanOrSku: true,
      };
    }
    return null;
  }
}
