import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Filters } from 'src/app/core/models/filters';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public activeFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public format(filters: any): Filters {
    const queryString: any = {};
    let i = 0;

    Object.getOwnPropertyNames(filters).forEach(condition => {
      if (
        filters[condition].cond === 'is_null' ||
        filters[condition].cond === 'is_not_null' ||
        filters[condition].value ||
        filters[condition].value === 0
      ) {
        Object.assign(queryString, this.createQueryString(filters, condition, i));
        i++;
      }
    });

    return queryString;
  }

  // TODO: add types
  private createQueryString(filters: any, condition: any, i: number): Filters {
    const query: Filters = {};
    const cond = filters[condition].cond;
    const clause = filters[condition].clause;

    query[`filters[${i}][field]`] = condition;
    query[`filters[${i}][cond]`] = cond ?? 'like';
    query[`filters[${i}][val]`] = `${filters[condition].value}`;
    if (clause) {
      query[`filters[${i}][clause]`] = clause;
    }
    return query;
  }

  public resultIsFiltered(filtered: boolean): void {
    this.activeFilters.next(filtered);
  }

  public ifFiltersAreActive(): Observable<boolean> {
    return this.activeFilters.asObservable();
  }

  public compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
