import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterField } from './FilterField';
import { FieldTypesEnum } from './field-types.enum';

export class DefaultComponentFilter extends FilterField {
  valueControl = new FormControl('');

  value: string;

  constructor(name: string, label: string, value: string) {
    super(name, label, FieldTypesEnum.DEFAULT_COMPONENT);
    this.value = value;
    this.patchValue(this.value);
  }

  patchValue(value: string): FilterField {
    this.valueControl.patchValue(value);
    this.defaultValue = true;
    return this;
  }

  setValidators(): FilterField {
    console.warn(`setValidators not implemented ${new Error().stack.split('\n')[2].trim()}`);
    return this;
  }

  isValid(): boolean {
    return true;
  }

  clear() {
    this.patchValue('');
  }

  get fieldControl$(): Observable<FormControl> {
    return new BehaviorSubject<FormControl>(this.valueControl).asObservable();
  }
}
