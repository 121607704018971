import { AfterContentInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Filters } from 'src/app/core/services/list/models/Filters';
import { Location } from 'src/app/location/models/location.model';
import { LocationApiService } from 'src/app/location/services/location-api.service';
import { FilterService } from '../../filter-button/filter-button.service';
import { Order } from '../../../core/services/list/models/Order';
import { Paginator } from '../../../core/services/list/models/Paginator';
import { LocationType } from '../../../location/models/type.model';
import { SortDirection } from '../../../core/services/list/models/sort-direction.enum';
import { TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { LocationPrickerService } from '../location-pricker.service';

export interface FilterParams {
  query: string;
  type: number;
}

@Component({
  templateUrl: './location-select-modal.component.html',
  styleUrls: ['./location-select-modal.component.scss'],
})
@TakeUntilDestroy
export class LocationSelectModalComponent implements OnInit, OnDestroy {
  isStorageNullable = false;

  canAssignProduct = false;

  isNotMasterPallet = false;

  filteredData$: Observable<Location[]>;

  queryControl: FormControl = new FormControl('');

  typeControl: FormControl = new FormControl('');

  columns = ['barcode', 'type', 'action'];

  types$: Observable<LocationType[]>;

  _search$: Subject<FilterParams> = new Subject();

  componentDestroy: () => Observable<void>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { warehouseId: number },
    private locationApiService: LocationApiService,
    private filtersService: FilterService,
    private dialogRef: MatDialogRef<LocationSelectModalComponent>,
    private locationPrickerService: LocationPrickerService,
  ) {}

  ngOnDestroy() {}

  ngOnInit(): void {
    this.types$ = this.locationApiService
      .getLocationTypes(new Filters([], new Order(['name', SortDirection.ASC], []), new Paginator(1, 99999)))
      .pipe(
        map(res => [...(res.items ?? []), new LocationType()]),
        takeUntil(this.componentDestroy()),
      );

    this.filteredData$ = this._search$.pipe(
      startWith({ query: '', type: '' }),
      switchMap(({ query, type }) => {
        if (this.data.warehouseId) {
          return this.locationApiService.getLocationsByWarehouse(
            this.locationPrickerService.prepareFilters(query, {
              typeId: type,
              isStorageNullable: this.isStorageNullable,
              canAssignProduct: this.canAssignProduct,
              isNotMasterPallet: this.isNotMasterPallet,
            }),
            this.data.warehouseId,
          );
        } else {
          return this.locationApiService.mikuGetLocations(
            this.locationPrickerService.prepareFilters(query, {
              typeId: type,
              isStorageNullable: this.isStorageNullable,
              canAssignProduct: this.canAssignProduct,
              isNotMasterPallet: this.isNotMasterPallet,
            }),
          );
        }
      }),
      map(res => res.items),
      takeUntil(this.componentDestroy()),
    );
    this.search();
  }

  selectLocation(location: Location): void {
    this.dialogRef.close(location);
  }

  search(): void {
    this._search$.next({ query: this.queryControl.value, type: this.typeControl.value?.id });
  }
}
