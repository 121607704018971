import { Component, Input } from '@angular/core';
import { TableHeaders } from '../../models/tableHeaders.model';
import { TableAlign } from '../../models/tableAlign.enum';

@Component({
  selector: 'app-table-headers, [tableHeaders]',
  templateUrl: './table-headers.component.html',
  styleUrls: ['./table-headers.component.scss'],
})
export class TableHeadersComponent {
  @Input() headers: TableHeaders[];

  @Input() data: any[];

  tableAlign = TableAlign;
}
