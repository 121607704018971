import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-number-of-packages-modal',
  templateUrl: './number-of-packages-modal.component.html',
  styleUrls: ['./number-of-packages-modal.component.scss'],
})
export class NumberOfPackagesModalComponent {
  @Output() onChange = new EventEmitter();
  @Output() onSave = new EventEmitter();

  count = 1;

  constructor(public dialogRef: MatDialogRef<NumberOfPackagesModalComponent>) {}

  onSpinnerChange(count: number) {
    this.onChange.emit(count);
    this.count = count;
  }

  save() {
    this.onSave.emit(this.count);
    this.dialogRef.close();
  }
}
