import { Injectable } from '@angular/core';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { PimApiService } from './pim-api.service';
import { Product } from '../models/product.model';
import { ProductSeries } from '../models/product-series.model';
import { Inventory } from '../models/inventory.model';
import { ServiceDestroyed } from '../../core/decorators/take-until-destroy';
import { Warehouse } from '../../orders/models/warehouse.model';
import { List } from '../../core/models/list';
import { ToastrService } from 'ngx-toastr';
import { Filters } from '../../core/services/list/models/Filters';
import { AddProductSeriesModel } from '../models/add-product-series.model';
import { ConfirmService } from '../../utils/confirm/confirm.service';
import { TextFilterField } from '../../core/services/list/models/TextFilterField';
import { SortDirection } from '../../core/services/list/models/sort-direction.enum';
import { Paginator } from '../../core/services/list/models/Paginator';
import { Order } from '../../core/services/list/models/Order';
import { SurveyLogService } from '../../core/services/survey-log.service';

@Injectable()
export class PimService implements ServiceDestroyed {
  serviceDestroyed = new Subject<void>();

  productId: number;

  productsReservedSeries: Inventory[] = [];

  productWarehouses: Warehouse[] = [];

  constructor(
    private pimApiService: PimApiService,
    private confirmService: ConfirmService,
    private surveyLogService: SurveyLogService,
    private toastr: ToastrService,
  ) {}

  getReservedVariants(productId: number): void {
    this.pimApiService
      .getProductLocations(productId)
      .pipe(
        map(({ items }) => items.filter(item => item.reserved > 0)),
        tap(items => (this.productsReservedSeries = items)),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  mapInventoryToProduct(product: Product) {
    return this.pimApiService.getProductLocations(product.id).pipe(
      map(({ items }) => {
        return items.map(({ quantity, location: { barcode }, product: { id, expiresAt, ean } }) => ({
          id,
          ean,
          barcode,
          expiresAt,
          quantity,
        }));
      }),
      tap(items => {
        const barcodes = [...new Set(items.map(item => item.barcode))];
        const mapItems: ProductSeries[] = barcodes.map(barcode => ({ barcode, series: [] }));
        items.forEach(({ barcode, expiresAt, quantity }) => {
          mapItems.forEach(mapItem => {
            if (mapItem.barcode === barcode) {
              mapItem.series.push({ expiresAt, quantity });
            }
          });
        });
        product.inventory = mapItems;
      }),
    );
  }

  getProductWarehouses(productId: number): Observable<List<Warehouse>> {
    return this.pimApiService.getProductWarehouses(productId).pipe(
      tap(({ items }) => (this.productWarehouses = items)),
      takeUntil(this.serviceDestroyed),
    );
  }

  addWarehouseToProduct(productId: number, warehouses: number[]): Observable<List<Warehouse>> {
    return this.pimApiService.addWarehouseToProduct(productId, warehouses).pipe(
      tap(() => this.toastr.success('Product has been assigned to warehouse!')),
      switchMap(() => this.getProductWarehouses(productId)),
      takeUntil(this.serviceDestroyed),
    );
  }

  removeWarehouseFromProduct(productId: number, warehouseId: number): Observable<List<Warehouse>> {
    return this.pimApiService.removeWarehouseFromProduct(productId, [warehouseId]).pipe(
      tap(() => this.toastr.success('Product has been removed from warehouse!')),
      switchMap(() => this.getProductWarehouses(productId)),
      takeUntil(this.serviceDestroyed),
    );
  }

  addProductSeries(payload: AddProductSeriesModel): Observable<any> {
    return this.pimApiService.addProductSeries(payload);
  }

  checkExistProductSeries(data: AddProductSeriesModel, actionAfterCheck: Observable<any> = of()): Observable<any> {
    const filters = new Filters(
      [new TextFilterField('expiresAt', 'expiresAt').patchValue(data.expiresAt)],
      new Order(['serialNumber', SortDirection.DESC], []),
      new Paginator(1, 9999),
    );

    return this.pimApiService.checkExistProductSeries(filters, data.product).pipe(
      switchMap(({ items: products }) => {
        const seriesExist =
          products.length &&
          products.some(({ serialNumber: productSerialNumber }) => productSerialNumber === data.serialNumber);

        if (products.length === 0) {
          return this.addProductSeries(data).pipe(switchMap(() => actionAfterCheck));
        }

        if (products.length > 0 && !seriesExist && data?.serialNumber && data?.expiresAt) {
          const question = `A product with an expiration date already exists and has a different LOT number. Is the specified LOT ${data.serialNumber} correct?`;
          return this.confirmService.show(question).pipe(
            switchMap(decision =>
              this.surveyLogService
                .createSurveyLog(question, decision ? 'yes' : 'no', data.product)
                .pipe(switchMap(() => of(decision))),
            ),
            filter(decision => decision),
            switchMap(() => this.addProductSeries(data)),
            switchMap(() => actionAfterCheck),
          );
        } else {
          return actionAfterCheck;
        }
      }),
    );
  }
}
