import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-placeholder',
  templateUrl: './table-placeholder.component.html',
  styleUrls: ['./table-placeholder.component.scss'],
})
export class TablePlaceholderComponent implements OnInit {
  @Input() rows: number;

  @Input() columns: string[];

  rowsPull: null[];

  ngOnInit(): void {
    this.rowsPull = new Array(this.rows);
  }
}
