import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-response-to-join',
  templateUrl: './response-to-join.component.html',
  styleUrls: ['./response-to-join.component.scss'],
})
export class ResponseToJoinComponent implements OnInit {
  @Output() onApprove = new EventEmitter();

  @Output() onReject = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<ResponseToJoinComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  approve() {
    this.onApprove.emit();
    this.dialogRef.close();
  }

  reject() {
    this.onReject.emit();
    this.dialogRef.close();
  }
}
