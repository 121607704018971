export class AutocompleteModel {
  label: string;

  value: number;

  constructor(label: string, value: number) {
    this.label = label;
    this.value = value;
  }
}
