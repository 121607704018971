import { ColumnInterface } from './column.interface';

export class HeadingColumn implements ColumnInterface {
  public name: string;
  public cssClass?: string;

  constructor(name = '', cssClass = '') {
    this.name = name;
    this.cssClass = cssClass;
  }
}
