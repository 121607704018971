import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InventoryCheckRequestModel } from '../../../inventory-check/models/inventory-check-request.interface';

@Component({
  selector: 'app-inventory-check-request-modal',
  templateUrl: './inventory-check-request-modal.component.html',
  styleUrls: ['./inventory-check-request-modal.component.scss', '../../table/table.component.scss'],
})
export class InventoryCheckRequestModalComponent implements OnInit {
  @Output() confirm = new EventEmitter<boolean>();

  products = [];

  location = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InventoryCheckRequestModel,
    private dialogRef: MatDialogRef<InventoryCheckRequestModalComponent>,
  ) {}

  ngOnInit(): void {
    this.products = this.data.products.items;
    this.location = this.data.location;
  }

  onConfirm(value: boolean) {
    this.confirm.emit(value);
    this.dialogRef.close();
  }
}
