import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-leave-process-modal',
  templateUrl: './leave-process-modal.component.html',
})
export class LeaveProcessModalComponent {
  @Output() action = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<LeaveProcessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
  }

  onAction(type: string): void {
    this.action.emit(type);
    this.dialogRef.close();
  }
}
