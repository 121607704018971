import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { WarehouseApiService } from 'src/app/warehouse/services/warehouse-api.service';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ConfirmService } from '../confirm/confirm.service';
import { Filters } from '../../core/services/list/models/Filters';
import { SortDirection } from '../../core/services/list/models/sort-direction.enum';
import { Paginator } from '../../core/services/list/models/Paginator';
import { Order } from '../../core/services/list/models/Order';
import { ExportService, Extension } from '../export/export.service';
import { LocationApiService } from '../../location/services/location-api.service';
import { TakeUntilDestroy } from '../../core/decorators/take-until-destroy';
import { PathItem } from './models/path-item.model';
import { TableHeaders } from '../table/models/tableHeaders.model';
import { TableAlign } from '../table/models/tableAlign.enum';
import { TableCell } from '../table/models/tableCell.model';
import { TableCellType } from '../table/models/tableCellType.enum';

@Component({
  selector: 'app-location-path-editor',
  templateUrl: 'location-path-editor.component.html',
  styleUrls: ['./location-path-editor.component.scss'],
})
@TakeUntilDestroy
export class LocationPathEditorComponent implements OnDestroy, OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() locationPath: PathItem[];

  @Input() unassignedLocation: PathItem[];

  @Input() saveSubject: (pickingPath) => Observable<void>;

  @Input() refreshSubject: () => any;

  @Input() resetSubject: () => Observable<void>;

  @Input() uploadNewPathSubject: (warehouseId, newPathFile) => Observable<void>;

  @Input() exportPath: string;

  @Input() onlyBarcodes = true;

  @Input() title = 'Path';

  @Input() hasEditAccess = true;

  @Input() hasShowAccess = false;

  @Input() saveToastrMessage: string;

  private componentDestroy: () => Observable<void>;

  private warehouseId: number;

  newPathFile: string;

  pathName = uuid();

  headers: TableHeaders[] = [{ value: 'Barcode' }, { value: 'Type', align: TableAlign.center }];

  cells: TableCell[] = [
    { type: TableCellType.text, key: 'barcode' },
    { type: TableCellType.text, key: 'type', align: TableAlign.center },
  ];

  constructor(
    public confirmService: ConfirmService,
    public dragulaService: DragulaService,
    public toastrService: ToastrService,
    private locationApiService: LocationApiService,
    private warehouseApiService: WarehouseApiService,
    private exportService: ExportService,
  ) {
    dragulaService.createGroup(this.pathName, {
      moves(el: any, container: any, handle: any): any {
        return !el.classList.contains('alert');
      },
    });
  }

  ngOnInit(): void {
    this.warehouseApiService
      .getWarehouses(new Filters([], new Order(['id', SortDirection.ASC], []), new Paginator(1, 1)))
      .pipe(
        tap(response => {
          this.warehouseId = response.items[0]?.id;
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.pathName);
  }

  addToLocationPath(location: PathItem): void {
    this.locationPath.push(location);

    const filteredLocationIndex = this.unassignedLocation.findIndex(el => el.id === location.id);
    if (filteredLocationIndex > -1) {
      this.unassignedLocation.splice(filteredLocationIndex, 1);
    }
  }

  save(): void {
    let payload;
    this.onlyBarcodes ? (payload = this.locationPath.map(location => location.barcode)) : (payload = this.locationPath);

    this.saveSubject(payload)
      .pipe(
        tap(() => {
          this.toastrService.success(this.saveToastrMessage);
          this.refreshSubject();
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  reset(): void {
    this.confirmService
      .show('Do you want to reset changes?')
      .pipe(
        filter(confirm => confirm),
        switchMap(() => this.resetSubject()),
        tap(() => {
          this.toastrService.success(this.saveToastrMessage);
          this.refreshSubject();
        }),
      )
      .subscribe();
  }

  uploadFile(): void {
    let payload;
    this.onlyBarcodes ? (payload = atob(this.newPathFile).split('\n')) : (payload = this.newPathFile);

    this.uploadNewPathSubject(this.warehouseId, payload)
      .pipe(
        tap(() => {
          this.refreshSubject();
          this.fileInput.nativeElement.value = '';
          this.newPathFile = '';
          this.toastrService.success('Path uploaded!');
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  fileChanged(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => (this.newPathFile = (reader.result as string).split(',')[1]);
    }
  }

  export(format: Extension = null): void {
    const filters = new Filters([], new Order(['id', SortDirection.ASC], []), new Paginator(1, 9999));

    this.exportService
      .mikuExport(filters, format, this.exportPath, { warehouse: this.warehouseId })
      .pipe(
        tap(response => this.exportService.saveFile('export_', response, format)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }
}
