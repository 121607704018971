import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PageRefreshDetectorService {
  count = 0;

  constructor(private localStorageService: LocalStorageService) {}

  observe() {
    const previousUrl = history?.state?.prevPage ?? null;
    const storageCount = parseInt(this.localStorageService.getItem('pageRefreshCount'), 10);

    if (storageCount) this.count = storageCount;
    if (!previousUrl) {
      this.count++;
      this.localStorageService.setItem('pageRefreshCount', this.count);
    }
  }
}
