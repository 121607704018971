import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addSpaceBetweenChar',
})
export class AddSpaceBetweenCharPipe implements PipeTransform {
  transform(value: string, char: string): string {
    return value.replace(char, ` ${char} `);
  }
}
