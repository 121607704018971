import { Component, Input } from '@angular/core';
import { MenuItem } from '../../models/menu-item';

@Component({
  selector: 'app-menu-grid-item',
  templateUrl: './menu-grid-item.component.html',
  styleUrls: ['./menu-grid-item.component.scss'],
})
export class MenuGridItemComponent {
  @Input() menuItem: MenuItem;
}
