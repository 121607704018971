import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[app-autofocus]',
})
/**
 * @deprecated
 * dont use this directive
 */
export class AutofocusDirective implements OnInit {
  constructor(public el: ElementRef) {}

  public ngOnInit() {
    this.el?.nativeElement.focus();
  }
}
