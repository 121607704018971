import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreloadService {
  private state = new BehaviorSubject<number>(0);

  public constructor() {}

  public increment(): void {
    let value = this.state.value;
    value++;
    this.state.next(value);
  }

  public decrement(): void {
    let value = this.state.value;
    if (value > 0) {
      value--;
    }

    this.state.next(value);
  }

  public getState(): Observable<number> {
    /**
     * Using delay to force emitting final value after all http calls finished.
     * Without delay() value could change before first change detection finished
     */
    return this.state.asObservable().pipe(delay(0));
  }

  public setState(number): void {
    this.state.next(number);
  }
}
