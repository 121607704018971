import { Injectable } from '@angular/core';
import {
  BehaviorSubject, Observable, of,
} from 'rxjs';

export interface SelectionModel {
  [key: string]: BehaviorSubject<number[]>;
}

@Injectable({
  providedIn: 'root',
})
export class MikuAutocompleteService {
  private initialOptions: SelectionModel = {};

  private selectedOptions: SelectionModel = {};

  init(uuid: string, data: number[]): void {
    this.initialOptions[uuid] = new BehaviorSubject<number[]>([...data]);
    this.selectedOptions[uuid] = new BehaviorSubject<number[]>([...data]);
  }

  toggleOption(uuid: string, id: number): void {
    if (uuid in this.selectedOptions) {
      const data = this.selectedOptions[uuid].value;
      const index = data.findIndex(el => el === id);
      if (index > -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
      this.selectedOptions[uuid].next(data);
    }
  }

  destroy(uuid: string): void {
    if (uuid in this.initialOptions) {
      delete this.initialOptions[uuid];
    }

    if (uuid in this.selectedOptions) {
      delete this.selectedOptions[uuid];
    }
  }

  getSelectedOptions$(uuid: string): Observable<number[]> {
    return uuid in this.selectedOptions ? this.selectedOptions[uuid].asObservable() : of([]);
  }

  getSelectedOptions(uuid: string): number[] {
    return uuid in this.selectedOptions ? this.selectedOptions[uuid].value : [];
  }

  getInitialOptions(uuid: string): number[] {
    return uuid in this.initialOptions ? this.initialOptions[uuid].value : [];
  }
}
