import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CollectItem } from '../../../collect/models/collectItem.model';

@Component({
  selector: 'app-show-product-modal',
  templateUrl: './show-product-modal.component.html',
})
export class ShowProductModalComponent {
  constructor(
    private dialogRef: MatDialogRef<ShowProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CollectItem,
    private router: Router,
  ) {}

  showProduct() {
    this.router.navigateByUrl(`pim/edit/${this.data.product.id}`).then(r => r);
    this.dialogRef.close();
  }
}
