import { Injectable } from '@angular/core';
import {
  CanActivate, UrlTree, Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../security/services/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authorizationService: AuthorizationService, private router: Router) {
  }

  canActivate(
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authorizationService.getToken()) {
      return this.router.parseUrl('/login');
    }

    return true;
  }
}
