import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { AdDirective } from '../../../../core/directives/ad.directive';

@Component({
  selector: 'app-table-custom-component, [tableCustom]',
  templateUrl: './table-custom-component.component.html',
  styleUrls: ['./table-custom-component.component.scss'],
})
export class TableCustomComponentComponent implements OnInit {
  @Input() component: ComponentType<any>;

  @Input() data: any[];

  @Input() className: string;

  @Input() type: string;

  @ViewChild(AdDirective, { static: true }) adHost!: AdDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    if (this.component) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
      const { viewContainerRef } = this.adHost;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      // @ts-ignore
      componentRef.instance.data = this.data;
      componentRef.instance.className = this.className;
      componentRef.instance.type = this.type;
    }
  }
}
