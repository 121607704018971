import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ScannerApiService } from '../../utils/services/scanner-api.service';

@Injectable({ providedIn: 'root' })
export class SkuExistCheckValidator implements AsyncValidator {
  constructor(private scannerApiService: ScannerApiService) {}

  validate = ({ value }: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    if (value !== '') {
      return this.scannerApiService.getProductBySku(value).pipe(
        map(() => null),
        catchError(() => of({ skuExist: true })),
      );
    }
    return of(null);
  };
}
