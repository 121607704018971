import { Injectable } from '@angular/core';
import { apiRoutes } from '../../../environments/api-routes';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { HttpClient } from '@angular/common/http';
import { List } from '../../core/models/list';
import { StoreInterface } from '../../orders/models/store.interface';
import { takeUntil, tap } from 'rxjs/operators';
import { ExportService } from '../export/export.service';

@Injectable({
  providedIn: 'root',
})
export class ImportOrderFromCsvService {
  serviceDestroyed = new Subject<void>();

  apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService, private exportService: ExportService) {}

  getStores(): Observable<List<StoreInterface>> {
    return this.http.get<List<StoreInterface>>(this.apiService.getMikuUrl(this.apiRoutes.stores.getStores));
  }

  importOrderFromCSV(file: File, warehouse: number, store: string): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('warehouse', warehouse.toString());
    formData.append('store', store);

    return this.http
      .post<void>(this.apiService.getMikuUrl(this.apiRoutes.sales.order.createOrderFromCSV), formData)
      .pipe(takeUntil(this.serviceDestroyed));
  }

  getOrderCSVTemplate(): Observable<File> {
    return this.exportService.getFiles(this.apiRoutes.sales.order.CSVTemplate).pipe(
      tap(csv => this.exportService.saveFile(`OrderCSVTemplate`, csv, 'csv')),
      takeUntil(this.serviceDestroyed),
    );
  }
}
