import { Component, Input, OnInit } from '@angular/core';
import { LocationInterface } from '../../../PIM/models/check-product/location.interface';

@Component({
  selector: 'app-check-product-mobile-table',
  templateUrl: './check-product-mobile-table.component.html',
  styleUrls: ['./check-product-mobile-table.component.scss'],
})
export class CheckProductMobileTableComponent {
  @Input() data: LocationInterface;

  @Input() className: string;

  collapse = true;

  changeCollapse() {
    this.collapse = !this.collapse;
  }
}
