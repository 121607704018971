import { Injectable, OnDestroy } from '@angular/core';
import { ReportsComponents } from '../enums/reports-components.enum';
import { TakeUntilDestroy } from '../decorators/take-until-destroy';
import { PusherService } from './pusher.service';
import { ModulesService } from '../helpers/modules.service';
import { Queue } from '../helpers/queue';
import { WarehouseService } from './warehouse.service';
import { SentryErrorHandlerService } from './sentry-error-handler.service';
import { LocalStorageService } from './local-storage.service';
import { AuthorizationService } from '../../security/services/authorization.service';
import { CheckLogTimeType } from '../enums/check-log-time-type.enum';
import { CheckLogTimeService } from './check-log-time.service';

@Injectable({
  providedIn: 'root',
})
@TakeUntilDestroy
export class LogTimeService implements OnDestroy {
  queueList: Queue[] = [];

  constructor(
    private warehouseService: WarehouseService,
    private pusherService: PusherService,
    private sentryErrorHandlerService: SentryErrorHandlerService,
    private localStorageService: LocalStorageService,
    private modulesService: ModulesService,
    private authorizationService: AuthorizationService,
    private checkLogTimeService: CheckLogTimeService,
  ) {}

  ngOnDestroy() {}

  logTime(
    stopPath: string,
    startPath: string,
    customStopAction: string = null,
    customStartAction: string = null,
  ): void {
    const stop = stopPath ? this.modulesService.getModuleByPath(stopPath) : null;
    const start = startPath ? this.modulesService.getModuleByPath(startPath) : null;

    if (stop && ReportsComponents[stop]) {
      this.send('stop', ReportsComponents[stop]);
    }

    if (start && ReportsComponents[start]) {
      this.send('start', ReportsComponents[start]);
    }

    if (customStopAction) {
      this.send('stop', customStopAction);
    }

    if (customStartAction) {
      this.send('start', customStartAction);
    }
  }

  send(action: string, type: ReportsComponents | string): void {
    if (!this.authorizationService.isUserLoggedIn()) {
      return;
    }

    const mappedType = type.includes(ReportsComponents.ADDITIONAL)
      ? 'additional.' + type.replace(`${ReportsComponents.ADDITIONAL}-`, '').replaceAll('_', '-')
      : type;

    const channel = this.pusherService.buildChannelName(null, 'presence-activity.', `.${mappedType}`);
    let queue = this.queueList.find(({ name }) => name === channel);

    if (!queue) {
      queue = new Queue(channel, task => this.authorizationService.isUserLoggedIn() && task[0]());
      queue.process();
      this.queueList.push(queue);
    }

    switch (action) {
      case 'start':
        this.checkLogTimeService.log('start', mappedType);
        queue.add(() => this.pusherService.subscribe(null, 'presence-activity.', `.${mappedType}`, true));
        break;

      case 'stop':
        this.checkLogTimeService.log('stop', mappedType);
        queue.add(() => this.pusherService.unsubscribe(null, 'presence-activity.', `.${mappedType}`));
        break;

      default:
        return;
    }
  }
}
