import { Component, Inject, OnDestroy } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CollectItem } from '../../../collect/models/collectItem.model';
import { CollectApiService } from '../../../collect/services/collect.api.service';
import { TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { BarcodeInputService } from '../../barcodeInput/barcodeInput.service';

@Component({
  selector: 'app-collecting-actions-modal',
  templateUrl: './collecting-actions-modal.component.html',
})
@TakeUntilDestroy
export class CollectingActionsModalComponent implements OnDestroy {
  componentDestroy: () => Observable<void>;

  constructor(
    private dialogRef: MatDialogRef<CollectingActionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CollectItem,
    private router: Router,
    private collectApiService: CollectApiService,
    private toastr: ToastrService,
    private barcodeInputService: BarcodeInputService,
  ) {}

  ngOnDestroy() {}

  showProduct() {
    this.router.navigateByUrl(`pim/edit/${this.data.product.id}`).then(r => r);
    this.dialogRef.close();
  }

  reportInventoryError() {
    const {
      product: { id: product, seriesId: series },
      location: { id: location },
    } = this.data;

    this.collectApiService
      .reportInventoryError({ product, series, location })
      .pipe(
        tap(() => {
          this.toastr.success('Inventory error reported!');
          this.dialogRef.close();
          this.barcodeInputService.focusScanInput();
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }
}
