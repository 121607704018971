import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-lazy-load',
  templateUrl: './image-lazy-load.component.html',
  styleUrls: ['./image-lazy-load.component.scss'],
})
export class ImageLazyLoadComponent {
  @Input() image: string = null;

  @Input() class = '';
}
