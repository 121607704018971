import { Component, Input, OnInit } from '@angular/core';
import { CheckLocationProductInterface } from '../../../location/models/checl-location-product.interface';

@Component({
  selector: 'app-check-location-mobile-table',
  templateUrl: './check-location-mobile-table.component.html',
  styleUrls: ['./check-location-mobile-table.component.scss'],
})
export class CheckLocationMobileTableComponent {
  @Input() data: CheckLocationProductInterface;

  @Input() className: string;

  collapse = true;

  changeCollapse() {
    this.collapse = !this.collapse;
  }
}
