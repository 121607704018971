import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { apiRoutes } from '../../../environments/api-routes';

@Injectable({
  providedIn: 'root',
})
export class SurveyLogService {
  private apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  createSurveyLog(question: string, answer: string, product: number): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.survey.root), { question, answer, product });
  }
}
