import { Component, OnInit } from '@angular/core';
import { PreloadService } from './preload.service';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'preload',
  templateUrl: './preload.component.html',
  styleUrls: ['./preload.component.scss'],
})
export class PreloadComponent implements OnInit {
  public showPreload$: Observable<boolean>;

  public constructor(private preloadService: PreloadService, public router: Router) {
    router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof RouteConfigLoadStart) {
        this.preloadService.increment();
      } else if (event instanceof RouteConfigLoadEnd) {
        this.preloadService.decrement();
      }
    });
  }

  ngOnInit(): void {
    this.showPreload$ = this.preloadService.getState().pipe(map(v => v > 0));
  }
}
