import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentEventsService {
  public selectionChange$: Observable<Selection>;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.selectionChange$ = fromEvent(this.document, 'selectionchange').pipe(map(() => this.document.getSelection()));
  }
}
