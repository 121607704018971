import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [BreadcrumbsComponent],
  imports: [CommonModule, BreadcrumbModule, RouterModule, CoreModule],
  exports: [BreadcrumbsComponent],
})
export class BreadcrumbsModule {}
