import { Injectable } from '@angular/core';
import { LogTimeService } from './logTime.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserFocusDetectorService {
  count = 0;

  currentUrl = '';

  constructor(private logTimeService: LogTimeService) {}

  observe() {
    document.addEventListener('visibilitychange', () => {
      switch (document.visibilityState) {
        case 'hidden':
          this.count++;
          this.logTimeService.logTime(this.currentUrl, null);
          break;

        case 'visible':
          this.logTimeService.logTime(null, this.currentUrl);
          break;

        default:
          break;
      }
    });
  }
}
