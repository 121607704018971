import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-miku-scanner-field',
  templateUrl: './miku-scanner-field.component.html',
  styleUrls: ['./miku-scanner-field.component.scss'],
})
export class MikuScannerFieldComponent implements OnInit {
  @Input() control: FormControl | AbstractControl;

  @Input() label: string;

  @Input() resetAfterSubmit = true;

  @Output() codeScanned: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public onSubmit(event: Event & { target: HTMLInputElement }): void {
    this.control.patchValue(event.target.value);
    this.codeScanned.emit(event.target.value);
    if (this.resetAfterSubmit) {
      this.control.patchValue('');
    }
  }
}
