import { Injectable } from '@angular/core';
import { CommentApiService } from './comment-api.service';
import { CommentTypeEnum } from '../../models/comment/comment.enum';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { List } from '../../../core/models/list';
import { CommentInterface } from '../../models/comment/comment.interface';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  comments: CommentInterface[] = [];

  constructor(private commentApiService: CommentApiService, private toastr: ToastrService) {}

  getComments(type: CommentTypeEnum): Observable<List<CommentInterface>> {
    return this.commentApiService.getComments(type).pipe(tap(({ items }) => (this.comments = items)));
  }

  addComment(type: CommentTypeEnum, comment: string): Observable<any> {
    return this.commentApiService
      .addComment(type, comment)
      .pipe(tap(() => this.toastr.success('Comment has been added!')));
  }

  updateComment(type: CommentTypeEnum, comment: string, id: number): Observable<any> {
    return this.commentApiService
      .updateComment(type, comment, id)
      .pipe(tap(() => this.toastr.success('Comment has been updated!')));
  }

  deleteComment(type: CommentTypeEnum, id: number): Observable<any> {
    return this.commentApiService
      .deleteComment(type, id)
      .pipe(tap(() => this.toastr.success('Comment has been deleted!')));
  }
}
