import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PackageService } from '../../services/package/package.service';
import { map, tap } from 'rxjs/operators';
import { SelectModel } from '../../miku-select/select.model';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-package-size-modal',
  templateUrl: './choose-package-size-modal.component.html',
})
export class ChoosePackageSizeModalComponent implements OnInit {
  @Output() onChoose = new EventEmitter();

  packageList$: Observable<
    SelectModel<{
      name: string;
      value: number;
    }>[]
  >;

  selectedPackage = new FormControl(null, [Validators.required]);

  constructor(
    public packageService: PackageService,
    private dialogRef: MatDialogRef<ChoosePackageSizeModalComponent>,
  ) {}

  ngOnInit(): void {
    this.packageList$ = this.packageService.getPackageList().pipe(
      map(({ items }) =>
        items.map(
          item =>
            ({ value: { name: item.name, id: item.id }, label: item.name } as SelectModel<{
              name: string;
              value: number;
            }>),
        ),
      ),
      tap(items => (this.selectedPackage = new FormControl(items[0].value, [Validators.required]))),
    );
  }

  approve() {
    this.onChoose.next(this.selectedPackage.value);
    this.dialogRef.close();
  }

  reject() {
    this.dialogRef.close();
  }
}
