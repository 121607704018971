import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ReplenishmentLevel } from '../../../settings/models/replenishment-level';
import { SubscriptionContainer } from '../../subscription/subscription.model';
import { SelectService } from '../select.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'replenishment-percentage-level',
  templateUrl: './replenishment-percentage-level.component.html',
})
export class ReplenishmentPercentageLevelComponent {
  @Input() public name = 'level';

  @Input() disabled = false;

  @Input() fieldControl: AbstractControl;

  public levels: ReplenishmentLevel[] = [];

  public useDefaultValue = false;

  private SubscriptionContainer: SubscriptionContainer = new SubscriptionContainer();

  constructor(private selectService: SelectService) {}

  ngOnInit(): void {
    this.SubscriptionContainer.add(
      this.selectService
        .getReplenishmentLevels()
        .pipe(
          tap(({ items }) => {
            this.levels = items;
            this.setInitialLevel();
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy() {
    this.SubscriptionContainer.clear();
  }

  public setInitialLevel(): void {
    if (this.disabled) {
      this.fieldControl.disable();
    } else {
      this.useDefaultValue = !this.fieldControl.value;
      this.useDefaultValue ? this.fieldControl.disable() : this.fieldControl.enable();
    }
  }

  public toggleSwitch(event: MatSlideToggleChange): void {
    this.fieldControl.patchValue(event.checked ? null : this.levels[0].id);
    this.useDefaultValue ? this.fieldControl.disable() : this.fieldControl.enable();
  }
}
