import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HandlingPalletsTask } from '../../../handling-pallets/components/models/handling-pallets-task.model';
import { StringHelpersService } from '../../../core/helpers/string-helpers.service';

@Component({
  selector: 'app-handling-pallets-action-modal',
  templateUrl: './handling-pallets-action-modal.component.html',
  styleUrls: ['./handling-pallets-action-modal.component.scss'],
})
export class HandlingPalletsActionModalComponent {
  @Output() onApprove = new EventEmitter();

  @Output() onReject = new EventEmitter();

  constructor(
    public stringHelpersService: StringHelpersService,
    public dialogRef: MatDialogRef<HandlingPalletsActionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { approveText: string; rejectText: string; task: HandlingPalletsTask; check: boolean },
  ) {}

  approve() {
    this.onApprove.emit();
    this.dialogRef.close();
  }

  reject() {
    this.onReject.emit();
    this.dialogRef.close();
  }
}
