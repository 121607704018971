import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UploadedFile } from './uploaded-file';

@Injectable()
export class UploadButtonService {
  onFilesRead: Observable<UploadedFile[]>;
  private _onFilesRead: Subject<UploadedFile[]> = new Subject();
  constructor() {
    this.onFilesRead = this._onFilesRead.asObservable();
  }

  public emitReadFiles(files: UploadedFile[]): void {
    this._onFilesRead.next(files);
  }
}
