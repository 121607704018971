import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ucfirst',
})
@Injectable()
export class UcfirstPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
