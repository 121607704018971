import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SelectModel } from './select.model';

@Component({
  selector: 'app-miku-select',
  templateUrl: './miku-select.component.html',
})
export class MikuSelectComponent {
  @Input() source$: Observable<SelectModel<unknown>[]>;

  @Input() control: FormControl;

  @Input() label: string;

  @Input() class: string;

  @Output() onChange = new EventEmitter();

  displayWith(object: any) {
    if (object?.name) {
      return object.name;
    } else if (object?.firstname && object?.lastname) {
      return `${object.lastname} ${object.firstname}`;
    } else if (object?.id === 1) {
      return 'Yes';
    } else if (object?.id === 0) {
      return 'No';
    }
  }

  change() {
    this.onChange.emit();
  }
}
