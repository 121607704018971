import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { AutocompleteModel } from './autocomplete.model';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
})
export class AutocompleteComponent implements OnInit {
  filteredList$: Observable<AutocompleteModel[]>;

  @Input() source$: (query) => Observable<AutocompleteModel[]>;

  @Input() control: FormControl;

  @Input() label: string;

  @Input() minTextLengthToComplete = 2;

  @Output() optionSelected = new EventEmitter();

  ngOnInit(): void {
    this.filteredList$ = this.control.valueChanges.pipe(
      filter(text => text && text.length > this.minTextLengthToComplete),
      debounceTime(300),
      switchMap(v => this.source$(v.trim())),
    );
  }

  getOptionLabel = (option: AutocompleteModel) => option?.label;

  getOptionSelected(event) {
    this.optionSelected.emit(event);
  }
}
