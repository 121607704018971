import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { result } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DateHelpersService {
  public static isDateValid(date: Date): boolean {
    if (date) return date.toString() !== 'Invalid Date';
    return false;
  }

  addLeadingZero(value: number | string): string {
    const v = value.toString();

    return `0${v}`.slice(-2);
  }

  formatDate(date: Date): string {
    return [date.getFullYear(), this.addLeadingZero(date.getMonth() + 1), this.addLeadingZero(date.getDate())].join(
      '-',
    );
  }

  isProductExpires(date: string, month: number) {
    return moment(date).subtract(month, 'months') < moment();
  }

  sortDescending(array: any[], key: string): any[] {
    return array.sort((a: any, b: any) => {
      // @ts-ignore
      return new Date(result(b, key)) - new Date(result(a, key));
    });
  }

  numberOfDaysBetweenDates(date1: string, date2: string): number {
    return (new Date(date1).getTime() - new Date(date2).getTime()) / (1000 * 3600 * 24);
  }
}
