import { v4 as uuidv4 } from 'uuid';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FieldTypesEnum } from './field-types.enum';
import { GenericObject } from '../../../../utils/models/generic-object';
import { FiltersSize } from '../../../enums/filters-size.enum';

export enum Conditions {
  LIKE = 'like',
  LLIKE = 'llike',
  LLIKEIN = 'llikein',
  RLIKE = 'rlike',
  EQ = 'eq',
  LT = 'lt',
  LTE = 'lte',
  GT = 'gt',
  GTE = 'gte',
  NULL = 'is_null',
  NOT_NULL = 'is_not_null',
  ANY_OF = 'anyOf',
}

export abstract class FilterField {
  /**
   * Conditions for standard field
   * Conditions[] for range field [from, to]
   */
  condition: Conditions | Conditions[] = Conditions.LIKE;

  key: string;

  label: string;

  uuid: string;

  visible: boolean;

  type: FieldTypesEnum;

  valueControl?: GenericObject;

  availableOptions$?;

  fromValue?: GenericObject;

  toValue?: GenericObject;

  dateFrom?: Date;

  dateTo?: Date;

  size?: string;

  defaultValue?: boolean;

  clause?: string;

  if = true;

  asParameter = false;

  protected constructor(name: string, label: string, type: FieldTypesEnum) {
    this.key = name;
    this.label = label;
    this.uuid = uuidv4();
    this.visible = true;
    this.type = type;
  }

  setCondition(condition: Conditions | Conditions[]): FilterField {
    this.condition = condition;
    return this;
  }

  setClause(clause: string): FilterField {
    this.clause = clause;
    return this;
  }

  setSize(size: FiltersSize, mobileSize: FiltersSize) {
    this.size = `size ${size} mobileSize mobile${mobileSize.charAt(0).toUpperCase() + mobileSize.slice(1)}`;
    return this;
  }

  abstract patchValue(...args): FilterField;

  abstract clear(): void;

  abstract setValidators(...args): FilterField;

  abstract isValid(): boolean;

  setVisible(isVisible: boolean): FilterField {
    this.visible = isVisible;
    return this;
  }

  /**
   * FormControl for standard field
   * FormControl[] for range field [from, to]
   */
  abstract get fieldControl$(): Observable<FormControl | FormControl[]>;

  valueChangeObserver(subject: Subject<unknown>): FilterField {
    this.valueControl.valueChanges.pipe(tap(value => subject.next(value))).subscribe();
    return this;
  }

  setIf(state: boolean): FilterField {
    this.if = state;
    return this;
  }

  setAsParameter(state: boolean): FilterField {
    this.asParameter = state;
    return this;
  }
}
