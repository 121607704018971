import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MikuProductModel } from '../../../PIM/models/miku-product.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddProductModalTypeEnum } from './add-product-modal-type.enum';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AutocompleteModel } from '../../miku-autocomplete/autocomplete.model';
import { Filters } from '../../../core/services/list/models/Filters';
import { TextFilterField } from '../../../core/services/list/models/TextFilterField';
import { Order } from '../../../core/services/list/models/Order';
import { SortDirection } from '../../../core/services/list/models/sort-direction.enum';
import { Paginator } from '../../../core/services/list/models/Paginator';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { List } from '../../../core/models/list';
import { ManufacturerInterface as Manufacturer } from '../../../core/models/manufacturer.interface';
import { SelectModel } from '../../miku-select/select.model';
import { TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import moment from 'moment';
import omit from 'lodash/omit';
import { ManufacturerService } from '../../../core/services/manufacturer.service';
import { CommentService } from '../../services/comments/comment.service';

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss'],
})
@TakeUntilDestroy
export class AddProductModalComponent implements OnInit, OnDestroy {
  @Output() onApprove = new EventEmitter();

  @Output() onReject = new EventEmitter();

  componentDestroy: () => Observable<void>;

  form: FormGroup = this.fb.group({
    quantity: [1, Validators.min(1)],
    expiresAt: [null],
    name: [this.data.product.name, Validators.required],
    sku: [this.data.product.sku, Validators.required],
    ean: [this.data.product.ean, Validators.required],
    serialNumber: [this.data.product.serialNumber || null],
    manufacturer: [null],
    comment: [null, [Validators.required]],
    commentArea: [null, [Validators.required, Validators.minLength(20)]],
  });

  noExpireDate = false;

  zoomLevel = 11;

  addProductModalType = AddProductModalTypeEnum;

  manufacturerList$: (query: string) => Observable<AutocompleteModel[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { product: MikuProductModel; type: AddProductModalTypeEnum },
    public commentService: CommentService,
    public dialogRef: MatDialogRef<AddProductModalComponent>,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private manufacturerService: ManufacturerService,
  ) {}

  ngOnInit(): void {
    if (this.data.product.name) this.form.get('name').disable();
    if (this.data.product.ean) this.form.get('ean').disable();
    if (this.data.product.sku) this.form.get('sku').disable();

    if (this.data.type === AddProductModalTypeEnum.VARIANT || this.data.type === AddProductModalTypeEnum.PRODUCT) {
      this.form
        .get('comment')
        .valueChanges.pipe(
          takeUntil(this.componentDestroy()),
          tap(value => {
            if (value === 'own') {
              this.form.addControl(
                'commentArea',
                new FormControl(null, [Validators.required, Validators.minLength(20)]),
              );
            } else {
              this.form.removeControl('commentArea');
            }
          }),
        )
        .subscribe();
    }

    if (this.data.type === AddProductModalTypeEnum.NEW_PRODUCT)
      this.form.get('manufacturer').setValidators(Validators.required);

    this.manufacturerList$ = (query: string) => {
      return this.manufacturerService
        .getManufacturers(
          new Filters(
            [new TextFilterField('name', 'Manufacturer').patchValue(query)],
            new Order(['name', SortDirection.ASC], []),
            new Paginator(1, 9999),
          ),
        )
        .pipe(
          map(({ items }: List<Manufacturer>) => [
            new SelectModel<Manufacturer>(null, 'Empty'),
            ...items.map((el: Manufacturer) => new AutocompleteModel(el.name, el.id)),
          ]),
          shareReplay(),
          takeUntil(this.componentDestroy()),
        ) as Observable<AutocompleteModel[]>;
    };
  }

  ngOnDestroy() {}

  approve() {
    if (this.form.valid) {
      if (this.form.get('expiresAt').value) {
        this.form.get('expiresAt').patchValue(moment(this.form.get('expiresAt').value).format('YYYY-MM-DD'));
      }

      if (this.form.get('comment').value === 'own') {
        this.form.get('comment').patchValue(this.form.get('commentArea').value);
      }

      this.onApprove.emit(omit(this.form.value, ['commentArea']));
    } else {
      this.toastr.error('Fill in the form correctly!');
    }
  }

  reject() {
    this.dialogRef.close();
  }

  toggleDatePicker(statement: boolean) {
    this.noExpireDate = statement;
    if (statement) this.form.get('expiresAt').patchValue(null);
  }

  changeZoomLevel(zoomLevel: number) {
    this.zoomLevel = zoomLevel;
  }

  changeDate(date: string) {
    this.form.get('expiresAt').patchValue(date);
  }

  changeManufacturer(event) {
    this.form.patchValue({
      manufacturer: event?.option?.value,
    });
  }
}
