import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { PreloadService } from 'src/app/utils/preload/preload.service';
import { ApiService } from 'src/app/core/api.service';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';
import { CorrelationService } from '../../core/services/correlation.service';

const AUTHORIZATION_HEADER = 'x-auth-token';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  private prevUrl: string;

  private errorCode: number | boolean = null;

  private isTokenRefreshing = false;

  constructor(
    private authorizationService: AuthorizationService,
    private preloadService: PreloadService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private correlationService: CorrelationService,
  ) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          this.prevUrl = event.url.replace('/', ' ');
        }),
      )
      .subscribe();
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i > -1) {
      this.requests.splice(i, 1);
    }
    this.preloadService.setState(this.requests.length);
  }

  addRequest(req: HttpRequest<any>, preloader: boolean | string) {
    this.requests.push(req);
    if (Boolean(preloader)) this.preloadService.setState(this.requests.length);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authorizationService.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          [AUTHORIZATION_HEADER]: token,
          'X-Correlation-ID': this.correlationService.id,
        },
      });
    }

    const preloader: boolean =
      (request.params.get('preloader') && request.params.get('preloader').includes('true')) ?? true;
    const errorNotification: boolean =
      (request.params.get('errorNotification') && request.params.get('errorNotification').includes('true')) ?? true;
    const isSessionToken: boolean =
      request.params.get('isSessionToken') && request.params.get('isSessionToken').includes('true');

    if (isSessionToken) {
      request = request.clone({
        setHeaders: {
          [AUTHORIZATION_HEADER]: this.authorizationService.getSessionToken(),
        },
      });
    }

    this.addRequest(request, preloader);
    return new Observable(observer => {
      const subscription = next
        .handle(request)
        .pipe(
          catchError(err => {
            let errorMessage;
            if (err) {
              errorMessage = this.apiService.handleError(err);
              this.errorCode = err.status;

              switch (err.status) {
                case 404:
                  if (errorNotification) this.toastr.error(errorMessage);
                  return;

                case 403:
                  this.router.navigateByUrl('').then(r => r);
                  return;

                case 401:
                  return this.refreshToken(request, next);

                default:
                  break;
              }
            } else {
              this.errorCode = false;
              errorMessage = 'An undefined error occurred.';
            }

            if (!errorNotification) return;

            this.toastr.error(errorMessage);
            return throwError(errorMessage);
          }),
        )
        .subscribe(
          event => observer.next(event),
          err => observer.error(err),
          () => observer.complete(),
        )
        .add(() => {
          this.removeRequest(request);
          observer.complete();
        });
      return () => {
        subscription.unsubscribe();
      };
    });
  }

  refreshToken(request: HttpRequest<any>, next: HttpHandler) {
    this.isTokenRefreshing = true;

    return this.authorizationService.refreshToken().pipe(
      switchMap(({ token }) => {
        this.isTokenRefreshing = false;

        request = request.clone({
          setHeaders: {
            [AUTHORIZATION_HEADER]: token,
            'X-Correlation-ID': this.correlationService.id,
          },
        });
        return next.handle(request);
      }),
      catchError(err => {
        this.isTokenRefreshing = false;

        const errorMessage = this.apiService.handleError(err);
        const errorNotification: boolean | string = request.params.get('errorNotification') ?? true;

        if (errorNotification) {
          this.toastr.error(`Permissions denied to ${this.prevUrl}`);
          this.toastr.error(errorMessage);
        }

        this.authorizationService.logout();
        return throwError(errorMessage);
      }),
    );
  }
}
