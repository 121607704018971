import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFormat',
})
export class PriceFormatPipe implements PipeTransform {
  transform(value: string | number): number {
    return value ? parseInt(value.toString(), 10) / 100 : null;
  }
}
