import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'range',
  templateUrl: 'range.component.html',
  styleUrls: ['range.component.scss'],
})
export class RangeComponent implements OnChanges {
  @Input() public label = '';
  @Input() public from: number = null;
  @Input() public to: number = null;
  @Input() public min: number = null;
  @Input() public max: number = null;

  @Output() public fromChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public toChange: EventEmitter<number> = new EventEmitter<number>();

  public showError = false;
  public errorMessage = '';

  public ngOnChanges(changes: SimpleChanges) {
    if (typeof changes.from !== 'undefined') {
      this.from = parseInt(changes.from.currentValue, 10);
    }

    if (typeof changes.to !== 'undefined') {
      this.to = parseInt(changes.to.currentValue, 10);
    }
  }

  public fromChanged(): void {
    if (!isNaN(this.min) && this.from < this.min) {
      this.from = this.min;
    }

    if (this.isToSet() && this.from > this.to) {
      this.from = null;
      this.showError = true;
      this.errorMessage = '"from" value can not be greater than "to" value';
    } else {
      this.showError = false;
    }

    this.fromChange.emit(this.from);
  }

  public toChanged(): void {
    if (!isNaN(this.min) && this.to < this.min) {
      this.to = this.min;
    }

    if (this.isFromSet() && this.to < this.from) {
      this.to = null;
      this.showError = true;
      this.errorMessage = '"to" value can not be lower than "from" value';
    } else {
      this.showError = false;
    }

    this.toChange.emit(this.to);
  }

  public isFromSet(): boolean {
    return !isNaN(this.from);
  }

  public isToSet(): boolean {
    return !isNaN(this.to);
  }
}
