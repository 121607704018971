import { takeUntil, tap } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TakeUntilDestroy } from 'src/app/core/decorators/take-until-destroy';
import { Observable } from 'rxjs';
import { WarehouseApiService } from '../../../warehouse/services/warehouse-api.service';
import { Warehouse } from '../../../warehouse/models/warehouse.model';
import { Filters } from '../../../core/services/list/models/Filters';
import { Paginator } from '../../../core/services/list/models/Paginator';
import { Order } from '../../../core/services/list/models/Order';
import { SortDirection } from '../../../core/services/list/models/sort-direction.enum';

@TakeUntilDestroy
@Component({
  selector: 'app-select-warehouse',
  templateUrl: 'warehouse.component.html',
})
@TakeUntilDestroy
export class SelectWarehouseComponent implements OnInit, OnDestroy {
  @Input() public required = false;

  @Input() public control: FormControl = new FormControl();

  componentDestroy: () => Observable<void>;

  warehouses: Warehouse[];

  constructor(public warehouseApiService: WarehouseApiService) {}

  ngOnDestroy() {}

  ngOnInit(): void {
    const filters = new Filters([], new Order(['code', SortDirection.ASC], []), new Paginator(1, 25));
    this.warehouseApiService
      .getWarehouses(filters)
      .pipe(
        tap(({ items }) => (this.warehouses = items)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }
}
