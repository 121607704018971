import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchValue: string): any[] {
    if (!items || !items.length || !searchValue || !searchValue.length) return items;
    return items.filter(item => {
      return JSON.stringify(item).toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    });
  }
}
