import {
  Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { DatepickerService } from '../datepicker.service';
import { DatepickerModalComponent } from '../datepicker-modal/datepickerModal.component';

@Component({
  selector: 'app-datepicker-input',
  templateUrl: 'datepickerInput.component.html',
})
export class DatepickerInputComponent implements OnChanges {
  @Input() public embedded = false;

  @Input() public disabled = false;

  @Input() public required = false;

  @Input() public name = '';

  @Input() public date: string;

  @Input() public time = false;

  @Input() public format = 'YYYY-MM-DD';

  @Input() public outputFormat = 'timestamp';

  @Input() public selectors: string[] = ['year', 'month', 'day'];

  @Input() public showRemoveButton = true;

  @Output() public dateChange: EventEmitter<string> = new EventEmitter();


  public timestamp = 0;

  public formatChanged = false;

  constructor(
    public modalService: BsModalService,
    public datepickerService: DatepickerService,
  ) {
    this.datepickerService.getDate().subscribe(datepickerServiceDate => {
      if (datepickerServiceDate !== null) {
        if (datepickerServiceDate.name === this.name) {
          this.timestamp = datepickerServiceDate.timestamp || 0;
          this.dateChange.emit(this.formatOutput());
        }
      } else {
        this.timestamp = 0;
        this.dateChange.emit(this.formatOutput());
      }
    });
  }

  public onDateEventEmit($event) {
    this.datepickerService.setDate($event.getTime(), this.name);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (typeof changes.format !== 'undefined') {
      this.formatChanged = true;

      if (this.timestamp > 0) {
        setTimeout(
          () => {
            this.dateChange.emit(this.formatOutput());
          },
        );
      }
    } else if (typeof changes.date !== 'undefined') {
      if (typeof changes.date.currentValue !== 'undefined') {
        if (changes.date.currentValue !== null && changes.date.currentValue.toString().match(/^[\d]+$/)) {
          return;
        }
        const date = new Date(changes.date.currentValue);
        this.timestamp = date.getTime();
      }
    }
  }

  public clear() {
    this.clearDate();
  }

  public chooseDate(): void {
    if (!this.embedded) {
      const datepickerModal = this.modalService.show(DatepickerModalComponent, {
        class: 'modal-default',
        initialState: {
          date: this.getDateObj(),
        },
      });
      datepickerModal.content.setDate(this.timestamp);
      datepickerModal.content.setName(this.name);
      datepickerModal.content.setWithTime(this.time);
      datepickerModal.content.setSelectors(this.selectors);
    }
  }

  public clearDate(): void {
    this.datepickerService.setDate(null, this.name);
  }

  public getDate(): string {
    if (typeof this.date !== 'undefined' && this.date !== null && this.date.length) {
      return moment(this.timestamp).format(this.getInputFormat());
    }
    return '';
  }

  public getDateObj(): Date {
    const result = new Date();

    if (this.timestamp) {
      result.setTime(this.timestamp);
    }

    return result;
  }

  public formatOutput(): string {
    if (this.timestamp === 0) {
      return null;
    }

    if (this.outputFormat === 'timestamp') {
      return Math.round(this.timestamp / 1000).toString();
    }
    return moment(this.timestamp).format(this.outputFormat);
  }

  public getInputFormat(): string {
    if (this.formatChanged === true) {
      return this.format;
    }

    if (this.time === true) {
      return 'YYYY-MM-DD HH:mm';
    }

    return this.format;
  }
}
