import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { FiltersService } from '../../services/filters.service';
import { FieldTypesEnum } from '../../../../core/services/list/models/field-types.enum';
import { SelectFilterField } from '../../../../core/services/list/models/SelectFilterField';
import { FilterField } from '../../../../core/services/list/models/FilterField';
import { ComponentDestroy, TakeUntilDestroy } from '../../../../core/decorators/take-until-destroy';
import { BehaviorFilters } from '../../models/BehaviorFilters.model';
import { AutocompleteFilterField } from '../../../../core/services/list/models/AutocompleteFilterField';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
@TakeUntilDestroy
export class FiltersComponent implements OnInit, ComponentDestroy, OnDestroy {
  @Input() filterName: string;

  @Input() restoreFilters = true;

  @Input() hide = false;

  @Output() filtered = new EventEmitter();

  componentDestroy: () => Observable<void>;

  filters$: Observable<BehaviorFilters>;

  search$ = new Subject();

  constructor(public filtersService: FiltersService) {}

  ngOnInit(): void {
    this.filtersService.currentComponentName = this.filterName;
    if (this.restoreFilters) {
      this.filtersService.restoreFilters();
    }
    this.filters$ = this.filtersService.filtersObservable;
    this.search$
      .pipe(
        switchMap(({ keepPage, isSaveFilters }) => {
          if (this.filtersService.filters) {
            this.filtersService.filters.paginator.page = 1;
          }
          return this.filtersService.runFilters(keepPage, isSaveFilters);
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
    this.filtersService.emitRefreshSubject();
  }

  ngOnDestroy() {}

  clear() {
    this.filtersService.clearValues();
    this.search(false, false);
    this.filtersService.changeMobileFiltersState(false);
    this.filtered.emit();
  }

  @HostListener('window:keyup', ['$event'])
  handleEnter({ keyCode }: KeyboardEvent) {
    if (keyCode === 13 && !this.hide) this.search(false, true);
  }

  search(keepPage?: boolean, isSaveFilters = false) {
    if (this.filtersService.showMobileFilters) {
      this.filtersService.changeMobileFiltersState();
      this.filtered.emit();
      this.search$.next({ keepPage, isSaveFilters });
    }

    if (window.innerWidth > 992) {
      this.filtered.emit();
      this.search$.next({ keepPage, isSaveFilters });
    }
  }

  get getFilterFieldTypes(): typeof FieldTypesEnum {
    return FieldTypesEnum;
  }

  asSelectFilterField(obj: FilterField): SelectFilterField {
    return obj as SelectFilterField;
  }

  asAutocompleteFilterField(obj: FilterField): AutocompleteFilterField {
    return obj as AutocompleteFilterField;
  }

  displayWith(object: any) {
    if (object.firstname && object.lastname) {
      return `${object.firstname} ${object.lastname}`;
    }

    if ((!object.firstname || !object.lastname) && object.username) {
      return object.username;
    }

    if (object.name) {
      return object.name;
    }
    if (object.id === 1) {
      return 'Yes';
    }
    if (object.id === 0) {
      return 'No';
    }
    return 'Any';
  }
}
