import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SelectModel } from '../miku-select/select.model';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiselectComponent implements OnInit {
  @Input() label: string;

  @Input() control: FormControl;

  @Input() availableOptions$: Observable<any[]>;

  options: SelectModel<any>[];

  ngOnInit(): void {
    this.availableOptions$.pipe(tap(res => (this.options = res))).subscribe();
  }
}
