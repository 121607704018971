import { catchError, takeUntil, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Component, Input, OnDestroy } from '@angular/core';
import { TakeUntilDestroy } from 'src/app/core/decorators/take-until-destroy';
import { ComponentDestroy } from '../../core/decorators/take-until-destroy';
import { AuthorizationService } from '../../security/services/authorization.service';
import { PusherService } from '../../core/services/pusher.service';
import { User } from '@sentry/types';
import { SessionAnalyzerService } from '../../core/services/session-analyzer.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['../../security/styles/security.scss'],
})
@TakeUntilDestroy
export class LogoutComponent implements ComponentDestroy, OnDestroy {
  componentDestroy: () => Observable<void>;

  @Input() showUsername = true;

  constructor(
    public authorizationService: AuthorizationService,
    private pusherService: PusherService,
    private sessionAnalyzerService: SessionAnalyzerService,
  ) {}

  public logoutAction(event: Event): void {
    event.stopPropagation();

    this.pusherService.unsubscribeAll();

    this.authorizationService
      .logOut()
      .pipe(
        tap(() => {
          this.disconnectServices();
        }),
        catchError(err => {
          this.disconnectServices();
          return throwError(err);
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  disconnectServices() {
    this.authorizationService.logout();
    this.pusherService.pusher.disconnect();
    this.sessionAnalyzerService.disconnect();
  }

  getUsername(): string {
    const user: User = this.authorizationService.getUser() as User;
    return user?.username ?? '';
  }

  ngOnDestroy() {}
}
