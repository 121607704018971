import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TableHeaders } from '../../table/models/tableHeaders.model';
import { TableAlign } from '../../table/models/tableAlign.enum';
import { TableCell } from '../../table/models/tableCell.model';
import { TableCellType } from '../../table/models/tableCellType.enum';
import { ActionsCellComponent } from './table-cells/actions-cell/actions-cell.component';
import { DeliveryService } from '../../../delivery/services/delivery.service';

@Component({
  selector: 'app-document-list-modal',
  templateUrl: './document-list-modal.component.html',
  styleUrls: ['./document-list-modal.component.scss'],
})
export class DocumentListModalComponent {
  headers: TableHeaders[] = [{ value: 'File name' }, { value: 'Employee' }, { value: 'Created at' }, { value: '' }];

  cells: TableCell[] = [
    { type: TableCellType.text, key: 'document.name' },
    { type: TableCellType.text, key: 'document.employee.name' },
    { type: TableCellType.text, key: 'createdAt' },
    {
      type: TableCellType.customComponent,
      component: ActionsCellComponent,
      key: '',
      align: TableAlign.right,
    },
  ];

  constructor(public dialogRef: MatDialogRef<DocumentListModalComponent>, public deliveryService: DeliveryService) {}
}
