export enum TableCellType {
  text = 'text',
  link = 'link',
  info = 'info',
  date = 'date',
  image = 'image',
  collapseCellTrigger = 'collapseCellTrigger',
  collapseTrigger = 'collapseTrigger',
  customComponent = 'customComponent',
  list = 'list',
  price = 'price',
  empty = 'empty',
}
