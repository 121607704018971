import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PackageModel } from '../../models/package.model';
import convertWeight from '../../../core/helpers/convert-weight';
import { WeightUnitsEnum } from '../../../core/enums/weight-units.enum';
import { ShippingMethodEnum } from '../../../orders/models/shipping-method.enum';

@Component({
  selector: 'app-create-package-size-modal',
  templateUrl: './create-package-size-modal.component.html',
})
export class CreatePackageSizeModalComponent implements OnInit {
  @Output() onApprove = new EventEmitter<PackageModel>();

  SHIPPING_METHOD = ShippingMethodEnum;

  form: FormGroup = this.fb.group({
    name: ['', Validators.required],
    width: ['', Validators.required],
    length: ['', Validators.required],
    height: ['', Validators.required],
    maxWeight: ['', Validators.required],
    isDefault: [0, Validators.required],
    shippingMethod: ['pallets', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<CreatePackageSizeModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: PackageModel,
  ) {}

  ngOnInit() {
    if (this.data) {
      this.form.patchValue(this.data);
      this.form.patchValue({
        maxWeight: convertWeight(this.data.maxWeight, WeightUnitsEnum.G, WeightUnitsEnum.KG),
      });
      this.form.controls.shippingMethod.disable();
    }
  }

  approve(): void {
    if (this.form.valid) {
      const payload: PackageModel = {
        ...this.form.value,
        maxWeight: convertWeight(this.form.value.maxWeight, WeightUnitsEnum.KG, WeightUnitsEnum.G),
      };

      this.onApprove.emit(payload);
      this.dialogRef.close();
    }
  }

  reject(): void {
    this.dialogRef.close();
  }

  get getShippingMethodOptions() {
    return Object.values(this.SHIPPING_METHOD);
  }
}
