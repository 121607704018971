import { Component, EventEmitter, Output } from '@angular/core';
import { Extension } from './export.service';

@Component({
  selector: 'export',
  templateUrl: './export.component.html',
  styleUrls: ['export.component.scss'],
})
export class ExportComponent {
  desiredFormat: Extension = 'csv';

  @Output() public submit: EventEmitter<Extension> = new EventEmitter();

  constructor() {}

  exportButtonClick() {
    this.submit.emit(this.desiredFormat);
  }
}
