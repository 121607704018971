import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmService } from './confirm.service';
import { BarcodeInputService } from '../barcodeInput/barcodeInput.service';

@Component({
  selector: 'confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  public showOnlyOk$: Observable<boolean>;

  constructor(public confirmService: ConfirmService, private barcodeScanner: BarcodeInputService) {}

  ngOnInit(): void {
    this.showOnlyOk$ = this.confirmService.getShowOnlyOk$();
  }

  public getMessage() {
    return this.confirmService.getMessage();
  }

  public isVisible() {
    return this.confirmService.getIsVisible();
  }

  public accept() {
    this.confirmService.accept();
    this.barcodeScanner.focusScanInput();
  }

  public decline() {
    this.confirmService.decline();
    this.barcodeScanner.focusScanInput();
  }
}
