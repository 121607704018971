import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { BarcodeService } from './barcode.service';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements AfterViewInit, OnDestroy {
  @Input() value: string;

  @Input() saveButton = false;

  @Input() forPrint = false;

  @ViewChild('barcodeContainer') barcodeContainer;

  constructor(private barcodeService: BarcodeService) {}

  ngAfterViewInit(): void {
    this.barcodeService.init(this.barcodeContainer?.nativeElement, this.value);
  }

  ngOnDestroy() {
    this.barcodeService.reset();
  }

  async generateDocument() {
    await this.barcodeService.generateDocument(this.value, this.value, this.forPrint);
  }
}
