import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WriteSeriesAndQuantity } from './write-series-and-quantity.model';
import { BarcodeInputService } from '../../barcodeInput/barcodeInput.service';
import { ScannerApiService } from '../../services/scanner-api.service';
import { TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { OrderService } from '../../../orders/services/order.service';
import { Order } from '../../../orders/models/order.model';

@Component({
  selector: 'app-write-series-and-quantity',
  templateUrl: './write-series-and-quantity.component.html',
  styleUrls: ['./write-series-and-quantity.component.scss'],
})
@TakeUntilDestroy
export class WriteSeriesAndQuantityComponent implements OnInit, OnDestroy {
  componentDestroy: () => Observable<void>;

  @Output() onSave = new EventEmitter();

  @Output() onCancel = new EventEmitter();

  noExpireDate = false;

  zoomLevel = 11;

  scannedOrder: Order = null;

  orderNumberError = false;

  serialNumberError = false;

  expiresAtError = false;

  form: FormGroup = this.fb.group({
    quantity: [null, [Validators.required, Validators.min(0)]],
    expiresAt: [null],
    order: [null],
    comment: [''],
    serialNumber: [null],
  });

  constructor(
    public dialogRef: MatDialogRef<WriteSeriesAndQuantityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WriteSeriesAndQuantity,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private changeDetector: ChangeDetectorRef,
    private barcodeInputService: BarcodeInputService,
    private scannerApiService: ScannerApiService,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    const { quantity } = this.data;
    if (quantity) this.form.patchValue({ quantity });

    if (this.data.edit) {
      const {
        quantity: existQuantity,
        product: { serialNumber, expiresAt },
      } = this.data?.returnItem;

      this.form.patchValue({
        quantity: existQuantity,
        serialNumber,
        expiresAt,
        order: this.data.returnItem?.order || '',
      });

      if (!expiresAt) {
        this.noExpireDate = true;
      }
    }

    if (this.data?.returnItem?.order?.number) {
      this.scanOrder(this.data?.returnItem?.order?.number?.toString());
    }

    this.changeDetector.detectChanges();

    this.barcodeInputService.showScanInput('scannerOrder');
  }

  ngOnDestroy() {}

  toggleDatePicker(statement: boolean) {
    this.noExpireDate = statement;
  }

  scanOrder(ean: string) {
    this.scannerApiService
      .getOrder(ean)
      .pipe(
        filter(order => !!order),
        tap(order => this.form.patchValue({ order })),
        switchMap(order => this.orderService.getOrder(order.id)),
        tap(order => (this.scannedOrder = order)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  save() {
    const expiresAt = this.noExpireDate ? null : moment(this.form.get('expiresAt').value).format('YYYY-MM-DD');
    this.form.patchValue({ expiresAt });
    this.resetErrors();

    if (this.form.value.order && this.scannedOrder) {
      const products = this.scannedOrder.items.filter(
        ({ product }) => product.id === this.data?.product?.id || product.id === this.data?.returnItem?.product?.id,
      );
      if (products.length > 0) {
        const assignedItems = [];
        products.forEach(({ assignedItems: items }) => {
          assignedItems.push(...items);
        });

        if (assignedItems.length > 0) {
          const { serialNumber } = this.form.value;
          const mappedSerialNumber = serialNumber === '' ? null : serialNumber;
          const series = assignedItems.filter(({ product: { serialNumber: lot } }) => lot === mappedSerialNumber);
          if (series.length > 0) {
            if (
              series.some(
                ({ product: { expiresAt: expiry } }) =>
                  moment(expiry).format('YYYY-MM-DD') === moment(this.form.value.expiresAt).format('YYYY-MM-DD'),
              )
            ) {
              this.sendForm();
            } else {
              this.expiresAtError = true;
              this.checkValidityAndSendForm('Product with this expires date not found in order.');
            }
          } else {
            this.serialNumberError = true;
            this.checkValidityAndSendForm('Product with this series not found in order.');
          }
        }
      } else {
        this.orderNumberError = true;
        this.checkValidityAndSendForm('Product not found in this order.');
      }
    } else {
      this.orderNumberError = true;
      this.checkValidityAndSendForm();
    }
  }

  checkValidityAndSendForm(errorMessage = '') {
    if (!this.data?.edit) {
      this.form.get('comment').setValidators([Validators.required, Validators.minLength(10)]);
      this.form.get('comment').updateValueAndValidity();
    }

    if (this.form.valid) {
      this.sendForm();
    } else {
      this.toastr.error(`${errorMessage} Enter the correct data or complete the comment.`);
    }
  }

  sendForm() {
    this.onSave.emit(this.form.value);
    this.dialogRef.close();
  }

  resetErrors() {
    this.orderNumberError = false;
    this.serialNumberError = false;
    this.expiresAtError = false;
    this.form.get('comment').setValidators([]);
    this.form.get('comment').updateValueAndValidity();
  }

  cancel() {
    this.dialogRef.close();
  }

  changeZoomLevel(zoomLevel: number) {
    this.zoomLevel = zoomLevel;
  }
}
