import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiRoutes } from 'src/environments/api-routes';
import { Product } from 'src/app/PIM/models/product.model';
import { Observable } from 'rxjs';
import { Location } from '../../PIM/models/location.model';
import { ApiService } from '../../core/api.service';
import { Order } from '../../orders/models/order.model';
import { MikuProductModel } from '../../PIM/models/miku-product.model';

@Injectable({
  providedIn: 'root',
})
export class ScannerApiService {
  private apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getLocation(barcode: string): Observable<Location> {
    return this.http.get<Location>(
      this.apiService.getMikuUrl(this.apiRoutes.scanner.location.replace('{barcode}', barcode.toString())),
    );
  }

  getOrder(barcode: string): Observable<Order> {
    return this.http.get<Order>(
      this.apiService.getMikuUrl(this.apiRoutes.scanner.order.replace('{barcode}', barcode.toString())),
    );
  }

  getProduct(barcode: string, errorNotification = true): Observable<Product & MikuProductModel> {
    return this.http.get<Product & MikuProductModel>(
      this.apiService.getMikuUrl(this.apiRoutes.scanner.product.replace('{barcode}', barcode.toString())),
      { params: { errorNotification } },
    );
  }

  getProductBySku(sku: string): Observable<Product> {
    return this.http.get<Product>(
      this.apiService.getMikuUrl(this.apiRoutes.scanner.productBySku.replace('{barcode}', sku.toString())),
    );
  }
}
