import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentDestroy, TakeUntilDestroy } from 'src/app/core/decorators/take-until-destroy';
import { ConfirmService } from '../confirm/confirm.service';

/**
 * @Deprecated use [NumberSpinner]{@link NumberSpinner}
 */
@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpinnerComponent),
      multi: true,
    },
  ],
})
@TakeUntilDestroy
export class SpinnerComponent implements ControlValueAccessor, ComponentDestroy, OnDestroy {
  public state: number = 0;
  componentDestroy: () => Observable<void>;
  @Input() public name: string = 'quantity';
  @Input() public label: string = '';
  @Input() public step: number = 1;
  @Input() public required: boolean = false;
  @Input() public minimumValue: number = 0;
  @Input() public maximumValue: number = null;
  @Input() public disabled: boolean = false;

  @Output() public changeValueEvent: EventEmitter<any> = new EventEmitter();

  public registerOnTouched(fn: () => void): void {}

  constructor(private snackBar: MatSnackBar, private confirmService: ConfirmService) {}

  public increment(): void {
    if (this.canBeIncremented()) {
      this.state = Number(this.state) + Number(this.step);
      this.stateChanged();
    }
  }

  public decrement(): void {
    if (this.canBeDecremented()) {
      this.state = Number(this.state) - Number(this.step);
      this.stateChanged();
    }
  }

  get value(): number {
    return this.state;
  }

  public registerOnChange(fn: (state: number) => void): void {
    this.propagateChange = fn;
  }

  public stateChanged(): void {
    if (this.maximumValue !== null && this.maximumValue < this.state) {
      this.confirmService.setShowOnlyOk(true);
      this.confirmService
        .show(this.getAlertMsg(this.state, this.maximumValue))
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe();
      this.state = this.maximumValue;
    }

    if (this.minimumValue !== null && this.minimumValue > this.state) {
      this.confirmService.setShowOnlyOk(true);
      this.confirmService
        .show(this.getAlertMsg(this.state, this.minimumValue))
        .pipe(takeUntil(this.componentDestroy()))
        .subscribe();
      this.state = this.minimumValue;
    }

    this.propagateChange(this.state);
    this.changeValueEvent.emit(this.state);
    return;
  }

  public writeValue(state: number): void {
    if (isNaN(state)) {
      state = this.minimumValue;
    }

    this.state = state;
  }

  public canBeIncremented(): boolean {
    return this.maximumValue === null || Number(this.state) + Number(this.step) <= this.maximumValue;
  }

  public canBeDecremented(): boolean {
    return this.minimumValue === null || Number(this.state) - Number(this.step) >= this.minimumValue;
  }

  public propagateChange = (a: any) => {};

  private getAlertMsg(from: number, to: number): string {
    return `Value has changed from ${from} to ${to}.`;
  }

  ngOnDestroy() {}
}
