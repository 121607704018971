import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Conditions, FilterField } from './FilterField';
import { FieldTypesEnum } from './field-types.enum';
import { SelectModel } from '../../../../utils/miku-select/select.model';
import { MikuGenericObject } from '../../../../utils/models/miku-generic-object';

export class MultiselectFilterField extends FilterField {
  availableOptions$: Observable<SelectModel<unknown>[]>;

  valueControl = new FormControl(null);

  constructor(
    name: string,
    label: string,
    availableOptions$: Observable<SelectModel<unknown>[]>,
    value: MikuGenericObject<number> = null,
  ) {
    super(name, label, FieldTypesEnum.MULTISELECT);
    this.availableOptions$ = availableOptions$;
    this.setCondition(Conditions.LLIKEIN);
    if (value) {
      this.patchValue(value);
    }
  }

  patchValue(v: MikuGenericObject<number>): FilterField {
    this.valueControl.patchValue(v);
    return this;
  }

  setValidators(): FilterField {
    console.warn(`setValidators not implemented ${new Error().stack.split('\n')[2].trim()}`);
    return this;
  }

  isValid(): boolean {
    return true;
  }

  clear(): void {
    this.patchValue(null);
  }

  get fieldControl$(): Observable<FormControl> {
    return new BehaviorSubject<FormControl>(this.valueControl).asObservable();
  }
}
