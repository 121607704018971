import { WeightUnitsEnum } from '../enums/weight-units.enum';

const convertWeight = (value: number | string, fromUnit = WeightUnitsEnum.G, toUnit = WeightUnitsEnum.KG): number => {
  const weight = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
  if (fromUnit === toUnit) return weight;
  if (fromUnit === WeightUnitsEnum.G && toUnit === WeightUnitsEnum.KG) {
    return weight / 1000;
  }
  if (fromUnit === WeightUnitsEnum.KG && toUnit === WeightUnitsEnum.G) {
    return weight * 1000;
  }
  return weight;
};

export default convertWeight;
