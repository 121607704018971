import { jsPDF } from 'jspdf';
import JsBarcode from 'jsbarcode';

export const PAGE_MARGINS = {
  x: 20,
  y: 20,
};

export const generateBarcode = (element: HTMLElement, value: string) => {
  JsBarcode(element, value, {
    height: 70,
  });
};

export const readDataUrl = (blob): Promise<string> => {
  return new Promise(resolve => {
    const file = new FileReader();
    file.onload = function (e) {
      resolve(e.target.result as string);
    };
    file.readAsDataURL(blob);
  });
};

export const pixelsToMm = (value: number): number => {
  return (value * 0.2645833333) / 2;
};

export const calcSize = (rootElement: string, instance: jsPDF): Promise<{ height: number; width: number }> => {
  const docSize = { height: instance.internal.pageSize.getHeight(), width: instance.internal.pageSize.getWidth() };

  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      resolve({
        height: pixelsToMm(image.height > docSize.height ? docSize.height : image.height),
        width: pixelsToMm(image.width > docSize.width ? docSize.width : image.width),
      });
    };
    image.src = rootElement;
  });
};

export const createDocument = async (data, forPrint = false) => {
  const { canvas, filename, value } = data;

  if (!value || (typeof document !== 'undefined' && !filename)) return null;

  const doc = new jsPDF();

  if (Array.isArray(value)) {
    let positionKey = 0;

    for (let key = 0; key < value.length; key++) {
      const item = value[key];
      generateBarcode(canvas, item);

      const dataUrl = await readDataUrl(await data.canvas.convertToBlob());

      if (pixelsToMm(canvas.height) * positionKey + 2 * PAGE_MARGINS.y > doc.internal.pageSize.getHeight()) {
        doc.addPage();
        positionKey = 0;
      }
      doc.addImage(
        dataUrl,
        'image/png',
        PAGE_MARGINS.x,
        pixelsToMm(canvas.height) * positionKey + PAGE_MARGINS.y,
        pixelsToMm(canvas.width),
        pixelsToMm(canvas.height),
      );

      positionKey += 1;
    }
  } else if (forPrint) {
    JsBarcode(canvas, value, { fontSize: 200, format: 'CODE128', width: 10, height: 70 });

    const dataUrl = await readDataUrl(await data.canvas.convertToBlob());

    doc.addImage(dataUrl, 'image/png', 0, -195, pixelsToMm(2200), pixelsToMm(1500), null, null, 270);
  } else {
    JsBarcode(canvas, value);

    const dataUrl = await readDataUrl(await data.canvas.convertToBlob());

    doc.addImage(
      dataUrl,
      'image/png',
      PAGE_MARGINS.x,
      PAGE_MARGINS.y,
      pixelsToMm(canvas.width),
      pixelsToMm(canvas.height),
    );
  }

  if (typeof document !== 'undefined') {
    doc.save(`${filename}.pdf`);
  } else {
    postMessage(doc.output('blob'));
  }
};
