import { Component, Input } from '@angular/core';
import { MassactionService } from '../massaction.service';

@Component({
  selector: 'app-massaction-dropdown',
  templateUrl: './massaction-dropdown.component.html',
  styleUrls: ['./massaction-dropdown.component.scss'],
})
export class MassactionDropdownComponent {
  @Input() btnText = 'Ok';

  constructor(public massActionService: MassactionService) {}
}
