import { Directive, HostListener, Inject, Input, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { isArray } from 'lodash';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[link]',
})
export class LinkDirective {
  @Input() link: string;

  @Optional() @Input() callback: () => void;

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {}

  public static getLink(link: string | any[]): any[] {
    if (!isArray(link)) {
      return [link];
    }
    return link;
  }

  public static getUrl(link: string | any[]): string {
    return isArray(link) ? link[0] : link;
  }

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent) {
    // prevent routing while selecting text
    if (this.document.getSelection().type === 'Range') {
      return;
    }
    if ($event.ctrlKey || $event.metaKey) {
      $event.preventDefault();
      window.open(LinkDirective.getUrl(this.link), '_blank');
    } else {
      this.router.navigate(LinkDirective.getLink(this.link));
      if (this.callback) {
        this.callback();
      }
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp($event: MouseEvent) {
    if ($event.button === 1) {
      $event.preventDefault();
      $event.stopPropagation();
      window.open(LinkDirective.getUrl(this.link), '_blank');
    }
  }
}
