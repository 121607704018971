import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiRoutes } from 'src/environments/api-routes';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { SeriesModel } from '../models/product/series.model';

@Injectable({
  providedIn: 'root',
})
export class SeriesApiService {
  private apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getProductSeries(productId: number): Observable<SeriesModel> {
    return this.http.get<SeriesModel>(
      this.apiService.getMikuUrl(this.apiRoutes.pim.product.series.replace('{productId}', productId.toString())),
    );
  }

  getAvailableStocks(productId: number): Observable<SeriesModel> {
    return this.http.get<SeriesModel>(
      this.apiService.getMikuUrl(
        this.apiRoutes.pim.product.availableStocks.replace('{productId}', productId.toString()),
      ),
    );
  }

  createAssignedItem(orderId: number, itemId: number, body): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.series.createAssignedItem
          .replace('{orderId}', orderId.toString())
          .replace('{itemId}', itemId.toString()),
      ),
      body,
    );
  }

  updateAssignedItem(orderId: number, itemId: number, assignedItemId: number, body): Observable<void> {
    return this.http.patch<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.series.updateAssignedItem
          .replace('{orderId}', orderId.toString())
          .replace('{itemId}', itemId.toString())
          .replace('{assignedItemId}', assignedItemId.toString()),
      ),
      body,
    );
  }

  deleteAssignedItem(orderId: number, itemId: number, assignedItemId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.series.deleteAssignedItem
          .replace('{orderId}', orderId.toString())
          .replace('{itemId}', itemId.toString())
          .replace('{assignedItemId}', assignedItemId.toString()),
      ),
    );
  }
}
