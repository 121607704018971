import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatepickerInputComponent } from '../datepicker-input/datepickerInput.component';
import { DatepickerRangeConfig } from '../models/datepickerRangeConfig.model';
import { DatepickerConfig } from '../models/datepickerConfig.model';

@Component({
  selector: 'app-range-datepicker',
  templateUrl: 'datepickerRange.component.html',
})
export class DatepickerRangeComponent implements OnInit {
  @ViewChild('dateFrom') dateFromDatepicker: DatepickerInputComponent;
  @ViewChild('dateTo') dateToDatepicker: DatepickerInputComponent;

  @Input() public options: DatepickerRangeConfig;
  @Input() public name: string = '';
  @Input() public from: string;
  @Output() public fromChange: EventEmitter<string> = new EventEmitter();
  @Input() public to: string;
  @Output() public toChange: EventEmitter<string> = new EventEmitter();

  public dateFromConfig: DatepickerConfig = new DatepickerConfig();
  public dateToConfig: DatepickerConfig = new DatepickerConfig();

  public showDateFromError = false;
  public showDateToError = false;

  public ngOnInit() {
    if (typeof this.options !== 'undefined') {
      this.dateFromConfig = this.options.getDateFromConfig();
      this.dateToConfig = this.options.getDateToConfig();
    } else {
      throw new Error('No config supplied');
    }
  }

  public clear(): void {
    this.dateFromDatepicker.clear();
    this.dateToDatepicker.clear();
    this.showDateFromError = false;
    this.showDateToError = false;
  }

  public dateFromChanged(newValue: string): void {
    if (this.isDateToSet() && newValue > this.to) {
      this.showDateFromError = true;
      this.from = '';
    } else {
      this.showDateFromError = false;
      this.from = newValue;
    }
    this.fromChange.emit(this.from);
  }

  public dateToChanged(newValue: string): void {
    if (this.isDateFromSet() && newValue < this.from) {
      this.showDateToError = true;
      this.to = '';
    } else {
      this.showDateToError = false;
      this.to = newValue;
    }

    this.toChange.emit(this.to);
  }

  public isDateFromSet(): boolean {
    return typeof this.from !== 'undefined' && this.from !== null && this.from.length > 0;
  }

  public isDateToSet(): boolean {
    return typeof this.to !== 'undefined' && this.to !== null && this.to.length > 0;
  }
}
