import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from 'src/environments/api-routes';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { TransferRequest } from '../models/transferRequest.model';
import { Filters } from '../../core/services/list/models/Filters';
import { CreateTransferRequest } from '../models/createTransferRequest.model';

@Injectable({
  providedIn: 'root',
})
export class TransferRequestApiService {
  private apiRoutes = apiRoutes;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  getUnassigned(filter: Filters): Observable<TransferRequest> {
    return this.http.get<TransferRequest>(
      this.apiService.getMikuUrl(this.apiRoutes.transferRequest.unassigned, filter),
    );
  }

  getAssigned(filter: Filters): Observable<TransferRequest> {
    return this.http.get<TransferRequest>(this.apiService.getMikuUrl(this.apiRoutes.transferRequest.assigned, filter));
  }

  getDone(filter: Filters): Observable<TransferRequest> {
    return this.http.get<TransferRequest>(this.apiService.getMikuUrl(this.apiRoutes.transferRequest.done, filter));
  }

  createTransferRequest(payload: CreateTransferRequest): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.transferRequest.root), payload);
  }

  deleteTransferRequest(id: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.transferRequest.details.replace('{id}', id.toString())),
    );
  }

  reserve(payload): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.transferRequest.reserved, null, payload), {});
  }

  cancelReserved(payload): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.transferRequest.reserved, null, payload),
      {},
    );
  }

  getToTake(): Observable<TransferRequest> {
    return this.http.get<TransferRequest>(
      this.apiService.getMikuUrl(this.apiRoutes.transferRequest.getToTake, null, {
        perPage: 9999999,
        page: 1,
        sortBy: 'sourceLocation.pickingPathOrder',
        sortDir: 'asc',
      }),
    );
  }

  getToPut(): Observable<TransferRequest> {
    return this.http.get<TransferRequest>(
      this.apiService.getMikuUrl(this.apiRoutes.transferRequest.getToPut, null, {
        perPage: 9999999,
        page: 1,
        sortBy: 'targetLocation.pickingPathOrder',
        sortDir: 'asc',
      }),
    );
  }

  toTake(id: number, quantity: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.transferRequest.toTake.replace('{transferId}', id.toString())),
      { quantity },
    );
  }

  putIt(id: number, location: number, quantity: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.transferRequest.putIt.replace('{transferId}', id.toString())),
      { location, quantity },
    );
  }
}
