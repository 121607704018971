import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { List } from 'src/app/core/models/list';
import { apiRoutes } from 'src/environments/api-routes';
import { FormGroup } from '@angular/forms';
import { Warehouse } from '../models/warehouse.model';
import { Filters } from '../../core/services/list/models/Filters';

@Injectable({
  providedIn: 'root',
})
export class WarehouseApiService {
  private apiRoutes = apiRoutes;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  public getWarehouses(filters: Filters): Observable<List<Warehouse>> {
    return this.http.get<List<Warehouse>>(this.apiService.getMikuUrl(this.apiRoutes.storage.warehouse.root, filters));
  }

  public getWarehouse(id: number): Observable<Warehouse> {
    return this.http.get<Warehouse>(
      this.apiService.getUrl(this.apiRoutes.storage.warehouse.idParam.replace('{id}', id.toString())),
    );
  }

  public addWarehouse(body: FormGroup): Observable<List<Warehouse>> {
    return this.http.post<List<Warehouse>>(this.apiService.getUrl(this.apiRoutes.storage.warehouse.root), body);
  }

  public updateWarehouse(id: number, body: FormGroup): Observable<List<Warehouse>> {
    return this.http.put<List<Warehouse>>(
      this.apiService.getUrl(this.apiRoutes.storage.warehouse.idParam.replace('{id}', id.toString())),
      body,
    );
  }
}
