import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-activity-modal',
  templateUrl: './add-activity-modal.component.html',
})
export class AddActivityModalComponent implements OnInit {
  @Output() onApprove = new EventEmitter<FormGroup>();

  form: FormGroup = this.fb.group({
    shortName: ['', [Validators.required]],
    description: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddActivityModalComponent>,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {}

  approve() {
    if (!this.form.invalid) {
      this.onApprove.emit(this.form);
      this.dialogRef.close();
    } else {
      this.toastr.error('Complete the form correctly!');
    }
  }

  reject() {
    this.dialogRef.close();
  }
}
