import { FormControl, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Conditions, FilterField } from './FilterField';
import { FieldTypesEnum } from './field-types.enum';

export class NumberFilterField extends FilterField {
  valueControl = new FormControl(null);

  constructor(name: string, label: string, value: number = null) {
    super(name, label, FieldTypesEnum.NUMBER);
    this.setCondition(Conditions.EQ);
    if(value !== null){
      this.patchValue(value);
    }
  }

  patchValue(value: number): FilterField {
    this.valueControl.patchValue(value);
    return this;
  }

  setValidators(validators: ValidatorFn[]): FilterField {
    this.valueControl.setValidators(validators);
    return this;
  }

  isValid(): boolean {
    return this.valueControl.valid;
  }

  clear(): void {
    this.patchValue(null);
  }

  get fieldControl$(): Observable<FormControl> {
    return new BehaviorSubject<FormControl>(this.valueControl).asObservable();
  }
}
