import { Component, Input, OnDestroy } from '@angular/core';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DeliveryApiService } from '../../../../../delivery/services/delivery-api.service';
import { ConfirmService } from '../../../../confirm/confirm.service';
import { DocumentItem } from '../../../../../delivery/models/document-item.model';
import { TakeUntilDestroy } from '../../../../../core/decorators/take-until-destroy';
import { DeliveryService } from '../../../../../delivery/services/delivery.service';
import { apiRoutes } from '../../../../../../environments/api-routes';

@Component({
  selector: 'app-actions-cell',
  templateUrl: './actions-cell.component.html',
  styleUrls: ['./actions-cell.component.scss'],
})
@TakeUntilDestroy
export class ActionsCellComponent implements OnDestroy {
  @Input() data: DocumentItem;

  componentDestroy: () => Observable<void>;

  apiRoutes = apiRoutes;

  constructor(
    private deliveryApiService: DeliveryApiService,
    private confirmService: ConfirmService,
    private toastr: ToastrService,
    private deliveryService: DeliveryService,
  ) {}

  ngOnDestroy() {}

  download() {
    const { deliveryId, id: documentId, document } = this.data;
    this.deliveryApiService
      .downloadDocument(deliveryId, documentId, document)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe();
  }

  remove() {
    this.confirmService
      .show('Do you really want to delete document?')
      .pipe(
        filter(confirm => confirm === true),
        switchMap(() => this.deliveryApiService.removeDeliveryDocument(this.data.deliveryId, this.data.id)),
        tap(() => {
          this.toastr.success('Document has been deleted!');
        }),
        switchMap(() => this.deliveryService.getDocuments(this.data.deliveryId)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }
}
