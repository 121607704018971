import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatepickerService {
  public timestamp: Subject<any> = new Subject();

  public setDate(timestamp: number, name: string = 'datepicker'): void {
    this.timestamp.next({
      name,
      timestamp,
    });
  }

  public getDate(): Observable<any> {
    return this.timestamp.asObservable();
  }
}
