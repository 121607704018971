import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkValueNa',
})
export class CheckValueNaPipe implements PipeTransform {
  transform(value: string | number, text: string = '-'): string | number {
    return value || text;
  }
}
