import { AfterContentChecked, Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgressBar } from './progress-bar.model';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent implements AfterContentChecked {
  @Input() uploadStatus = 0;

  progress = 0;

  constructor(
    private dialogRef: MatDialogRef<ProgressBarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProgressBar,
  ) {}

  ngAfterContentChecked() {
    this.progress = Math.ceil((this.uploadStatus / this.data.total) * 100);

    if (this.progress >= 100) {
      setTimeout(() => this.dialogRef.close(), 1000);
    }
  }
}
