import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { AutocompleteModel } from './autocomplete.model';
import { MikuAutocompleteService } from './miku-autocomplete.service';

@Component({
  selector: 'app-miku-autocomplete',
  templateUrl: './miku-autocomplete.component.html',
  styleUrls: ['./miku-autocomplete.component.scss'],
})
/**
 * @deprecated Should use utils/autocomplete
 */
export class MikuAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() source$: (query) => Observable<AutocompleteModel[]>;

  @Input() uuid: string;

  control = new FormControl('');

  filteredList$: Observable<AutocompleteModel[]>;

  constructor(private mikuAutocompleteService: MikuAutocompleteService) {}

  ngOnInit(): void {
    this.filteredList$ = this.control.valueChanges.pipe(
      debounceTime(300),
      switchMap(v => this.source$(v)),
    );
  }

  ngAfterViewInit(): void {
    this.control.patchValue('');
  }

  ngOnDestroy() {
    this.mikuAutocompleteService.destroy(this.uuid);
  }

  isSelected$(itemId: number): Observable<boolean> {
    return this.mikuAutocompleteService.getSelectedOptions$(this.uuid).pipe(map(data => data.includes(itemId)));
  }

  toggleSelection(item: AutocompleteModel) {
    this.mikuAutocompleteService.toggleOption(this.uuid, item.value);
  }

  optionSelected(event: MouseEvent, item: AutocompleteModel) {
    event.stopPropagation();
    this.toggleSelection(item);
  }
}
