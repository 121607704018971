import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[selectClickable]',
})
export class SelectClickableDirective {
  @Input() elementClicked$: Subject<void>;

  @HostListener('click')
  onClick(event: Event) {
    if (this.document.getSelection().type !== 'Range') {
      this.elementClicked$.next();
    } else {
    }
  }

  constructor(@Inject(DOCUMENT) private document: Document) {}
}
