import { FormControl } from '@angular/forms';
import { SortDirection } from './sort-direction.enum';
import { SortOption } from './SortOption';


export type OrderValue = [string, SortDirection];

export class Order {
  orderFields: SortOption[];

  key: FormControl;

  sortDirection: FormControl;

  sortDirectionOptions = Object.values(SortDirection);

  constructor([field, direction]: OrderValue, orderFields: SortOption[]) {
    this.key = new FormControl(field);
    this.sortDirection = new FormControl(direction);
    this.orderFields = orderFields;
  }
}
