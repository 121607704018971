import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { Observable, defer, of } from 'rxjs';
import { ConfirmService } from 'src/app/utils/confirm/confirm.service';
import { SettingsService } from 'src/app/settings/services/settings.service';
import * as moment from 'moment';
import { Delivery } from '../../../../delivery/models/delivery.model';
import { Location } from '../../../../location/models/location.model';
import { DeliveryVerifyDialogInitData } from './delivery-verify-dialog-init-data';
import { Product } from '../../../../delivery/models/product.model';
import { ItemInDelivery } from '../../../../delivery/models/itemInDelivery.model';
import { DeliveryItemToAcceptanceInterface } from '../../../../delivery/models/delivery-item-to-acceptance.interface';

@Component({
  selector: 'app-delivery-verify-item-modal',
  templateUrl: 'delivery-verify-item-modal.component.html',
  styleUrls: ['delivery-verify-item-modal.component.scss'],
})
export class DeliveryVerifyItemModalComponent implements OnInit {
  @Output() onVerify = new EventEmitter();

  public ean: string;

  public sku: string;

  public product: Product;

  public expectedItem: ItemInDelivery;

  public delivery: Delivery;

  public location: Location;

  public noExpireDate = false;

  public MIN_QTY = 0;

  public maxQtyPerProduct$: Observable<number>;

  zoomLevel = 11;

  public verifyItemForm: FormGroup = this.fb.group({
    comment: [''],
    quantity: [1],
    expiresAt: [null],
    serialNumber: [null],
  });

  public warning$ = defer(() =>
    of(
      this.toastr.warning(
        'The expected quantity exceeds maximum quantity definied in general configuration - contact your manager',
      ),
    ),
  );

  constructor(
    private fb: FormBuilder,
    public toastr: ToastrService,
    public confirmService: ConfirmService,
    @Inject(MAT_DIALOG_DATA) public initData: DeliveryVerifyDialogInitData,
    private dialogRef: MatDialogRef<DeliveryVerifyItemModalComponent>,
    private settingsService: SettingsService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const { delivery, location, product, expectedItem } = this.initData;
    this.delivery = delivery;
    this.location = location;
    this.product = product;
    this.expectedItem = expectedItem;
    this.verifyItemForm.patchValue({
      quantity: this.MIN_QTY,
    });

    this.maxQtyPerProduct$ = this.settingsService
      .getSettingValue('max_delivery_quantity_per_product')
      .pipe(switchMap(max => of(parseInt(max, 10))));

    this.changeDetector.detectChanges();
  }

  verify(maxQty: number): void {
    const { comment, expiresAt, quantity, serialNumber } = this.verifyItemForm.value;

    if (this.verifyItemForm.valid) {
      if (quantity > maxQty) {
        this.toastr.warning(
          'The expected quantity exceeds maximum quantity definied in general configuration - contact your manager',
        );
        return;
      }

      const formattedDate = this.noExpireDate ? null : moment(expiresAt).format('YYYY-MM-DD');

      if ((!expiresAt && !this.noExpireDate) || formattedDate === 'Invalid date') {
        this.toastr.warning('The expiry date of the product has not been determined.');
        return;
      }

      const payload: DeliveryItemToAcceptanceInterface = {
        location: this.location?.id,
        product: this.product?.id,
        expiresAt: formattedDate,
        quantity,
        comment,
        serialNumber: serialNumber === '' ? null : serialNumber,
        expectedItem: this.expectedItem?.id,
      };

      this.onVerify.emit(payload);
    }
  }

  public toggleDatePicker(statement: boolean) {
    this.noExpireDate = statement;
  }

  changeZoomLevel(zoomLevel: number) {
    this.zoomLevel = zoomLevel;
  }

  cancel() {
    this.dialogRef.close();
  }
}
