import { Injectable } from '@angular/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { PackageApiService } from './package-api.service';
import { PackageModel } from '../../models/package.model';
import { ServiceDestroyed } from '../../../core/decorators/take-until-destroy';
import { Observable, Subject } from 'rxjs';
import { List } from '../../../core/models/list';

@Injectable({
  providedIn: 'root',
})
export class PackageService implements ServiceDestroyed {
  serviceDestroyed = new Subject<void>();

  packageList: PackageModel[];

  constructor(private packageApiService: PackageApiService) {}

  getPackageList(): Observable<List<PackageModel>> {
    return this.packageApiService.getPackageSizesList().pipe(
      tap(({ items }) => (this.packageList = items)),
      takeUntil(this.serviceDestroyed),
    );
  }
}
