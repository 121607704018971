import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Location } from 'src/app/location/models/location.model';
import { LocationApiService } from 'src/app/location/services/location-api.service';
import { FilterService } from '../filter-button/filter-button.service';
import { LocationSelectModalComponent } from './location-select-modal/location-select-modal.component';
import { TakeUntilDestroy } from '../../core/decorators/take-until-destroy';
import { LocationPrickerService } from './location-pricker.service';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
})
@TakeUntilDestroy
export class LocationPickerComponent implements OnInit, OnDestroy {
  @Input() isNotMasterPallet = false;

  @Input() showSearchBtn = true;

  @Input() isStorageNullable = false;

  @Input() canAssignProduct = false;

  @Input() locationControl: FormControl | AbstractControl;

  @Input() control: FormControl = null;

  @Input() label = 'Location';

  @Input() disabled = false;

  @Input() class = '';

  @Input() warehouseId: number;

  locationScanned$: Subject<string> = new Subject();

  queryControl: FormControl = new FormControl('');

  componentDestroy: () => Observable<void>;

  constructor(
    private locationApiService: LocationApiService,
    private locationPrickerService: LocationPrickerService,
    private filtersService: FilterService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.queryControl.validator = this.locationControl.validator;

    this.locationControl.valueChanges
      .pipe(
        tap(value => {
          if (value) {
            this.queryControl.patchValue(value.barcode);
          } else {
            this.queryControl.patchValue(null);
          }
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();

    if (this.disabled) {
      this.queryControl.disable();
    }

    this.locationScanned$
      .pipe(
        switchMap(barcode => {
          return this.locationApiService
            .mikuGetLocations(
              this.locationPrickerService.prepareFilters(barcode, {
                isStorageNullable: this.isStorageNullable,
                canAssignProduct: this.canAssignProduct,
                isNotMasterPallet: this.isNotMasterPallet,
              }),
            )
            .pipe(
              map(res => res.items),
              takeUntil(this.componentDestroy()),
            );
        }),
        tap(res => {
          if (res.length > 0) {
            this.setValue(res[0]);
          } else {
            this.toastrService.error('Location not found');
          }
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.clear();
  }

  displayWith(v: string | Location): string {
    if (typeof v === 'string') {
      return v;
    }
    return v.barcode;
  }

  private setValue(v: Location): void {
    this.locationControl.patchValue(v);
  }

  public onSubmit(event: Event & { target: HTMLInputElement }) {
    this.locationScanned$.next(event.target.value);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LocationSelectModalComponent, {
      width: '700px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      autoFocus: false,
      data: { warehouseId: this.warehouseId ?? null },
    });

    dialogRef.componentInstance.isStorageNullable = this.isStorageNullable;
    dialogRef.componentInstance.canAssignProduct = this.canAssignProduct;
    dialogRef.componentInstance.isNotMasterPallet = this.isNotMasterPallet;

    dialogRef
      .afterClosed()
      .pipe(
        filter(res => !!res),
        tap(location => this.setValue(location)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  clear() {
    this.locationControl.patchValue(null);
  }
}
