import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PackingActionsModal } from './packing-actions-modal.model';
import { PackService } from '../../../pack/services/pack.service';

@Component({
  selector: 'app-packing-actions-modal',
  templateUrl: './packing-actions-modal.component.html',
  styleUrls: ['./packing-actions-modal.component.scss'],
})
export class PackingActionsModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PackingActionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PackingActionsModal,
    private packService: PackService,
  ) {}

  reportWrongDate() {
    this.packService.wrongExpiryDate(this.data.packingOrderId, this.data.id);
    this.dialogRef.close();
  }
}
