import { Component, Input, OnInit } from '@angular/core';
import { WriteSeriesAndQuantityComponent } from '../../../write-series-and-quantity/write-series-and-quantity.component';
import { ReturnItem } from '../../../../../returns/models/return-item.model';
import { MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { AddProductSeriesModel } from '../../../../../PIM/models/add-product-series.model';
import { PimService } from '../../../../../PIM/services/pim.service';
import { Observable, defer, of } from 'rxjs';

@Component({
  selector: 'app-edit-return-cell',
  templateUrl: './edit-return-cell.component.html',
})
export class EditReturnCellComponent implements OnInit {
  @Input() data: ReturnItem;

  constructor(private dialog: MatDialog, private pimService: PimService) {}

  ngOnInit(): void {}

  editReturn() {
    const dialogRef = this.dialog.open(WriteSeriesAndQuantityComponent, {
      width: '700px',
      data: { returnItem: this.data, edit: true, scanner: false },
    });

    dialogRef.componentInstance.onSave
      .pipe(
        switchMap(({ expiresAt, quantity, serialNumber, comment }) => {
          this.data.comment = comment;
          const productPayload: AddProductSeriesModel = {
            product: this.data.product.id,
            serialNumber: serialNumber ?? null,
            expiresAt,
          };

          const changeItemAction = defer(() => {
            this.data.product.expiresAt = expiresAt;
            this.data.quantity = quantity;
            this.data.product.serialNumber = serialNumber;
            dialogRef.close();
          });

          return this.pimService.checkExistProductSeries(productPayload, changeItemAction);
        }),
      )
      .subscribe();
  }
}
