import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-card-row',
  templateUrl: './info-card-row.component.html',
  styleUrls: ['./info-card-row.component.scss'],
})
export class InfoCardRowComponent {
  @Input() title: string;
}
