import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShippingAddressInterface } from '../../../orders/models/shipping-address.interface';

@Component({
  selector: 'app-pack-list',
  templateUrl: './scan-order-number-modal.component.html',
})
export class ScanOrderNumberModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ScanOrderNumberModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; description?: string; inputLabel?: string },
  ) {}

  @Output() onOrderNumberScan = new EventEmitter();

  public control: FormControl = new FormControl('');

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  public scanOrderNumber(value: string): void {
    this.onOrderNumberScan.emit(value);
  }
}
