import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-monitoring-mobile-table',
  templateUrl: './monitoring-mobile-table.component.html',
  styleUrls: ['./monitoring-mobile-table.component.scss'],
})
export class MonitoringMobileTableComponent {
  @Input() data;

  @Input() className: string;

  @Input() info = true;

  changeCollapse(data, key: string) {
    data[key] = !data[key];
  }
}
