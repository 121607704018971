import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { Conditions, FilterField } from './FilterField';
import { FieldTypesEnum } from './field-types.enum';
import { SelectModel } from '../../../../utils/miku-select/select.model';
import { MikuGenericObject } from '../../../../utils/models/miku-generic-object';
import { TakeUntilDestroy } from '../../../decorators/take-until-destroy';

@TakeUntilDestroy
export class SelectFilterField extends FilterField implements OnDestroy {
  private componentDestroy: () => Observable<void>;

  availableOptions$: Observable<SelectModel<unknown>[]>;

  valueControl = new FormControl({ id: null });

  constructor(
    name: string,
    label: string,
    availableOptions$: Observable<SelectModel<unknown>[]> = null,
    value: MikuGenericObject<number> = null,
    optionsArray: SelectModel<unknown>[] = null,
  ) {
    super(name, label, FieldTypesEnum.SELECT);
    if (availableOptions$) this.availableOptions$ = availableOptions$;
    this.setCondition(Conditions.EQ);
    if (value) this.patchValue(value);
    if (!availableOptions$ && optionsArray) {
      this.availableOptions$ = of(optionsArray);
    }
  }

  ngOnDestroy() {}

  patchValue(v: any): FilterField {
    this.valueControl.patchValue(v);
    return this;
  }

  setValidators(): FilterField {
    console.warn(`setValidators not implemented ${new Error().stack.split('\n')[2].trim()}`);
    return this;
  }

  isValid(): boolean {
    return true;
  }

  clear(): void {
    this.patchValue({ id: null });
  }

  get fieldControl$(): Observable<FormControl> {
    return new BehaviorSubject<FormControl>(this.valueControl).asObservable();
  }

  checkValueChange(subject: Subject<unknown>): FilterField {
    subject
      .pipe(
        tap((values: Observable<SelectModel<unknown>[]>) => {
          // @ts-ignore
          this.patchValue('');
          this.availableOptions$ = of(values) as Observable<any>;
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
    return this;
  }
}
