import { Component, Input } from '@angular/core';
import { MassActionItem } from '../models/mass-action-item.model';
import { MassactionService } from '../massaction.service';

@Component({
  selector: 'app-massaction-select-all',
  templateUrl: './massaction-select-all.component.html',
})
export class MassactionSelectAllComponent {
  @Input() items: MassActionItem[];

  constructor(public massActionService: MassactionService) {}

  changeState(checked: boolean) {
    this.items.map(item => (item.massAction = checked));
    this.massActionService.selectAll.items = this.items;
    this.massActionService.selectByFilters.reset();
  }
}
