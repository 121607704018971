import { Injectable } from '@angular/core';
import { catchError, delay, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Observable, Subject, iif, of } from 'rxjs';
import { DeliveryApiService } from './delivery-api.service';
import { ServiceDestroyed } from '../../core/decorators/take-until-destroy';
import { DocumentItem } from '../models/document-item.model';
import { ExportService, Extension } from '../../utils/export/export.service';
import { FiltersService } from '../../utils/filters/services/filters.service';
import { List } from '../../core/models/list';
import { apiRoutes } from '../../../environments/api-routes';
import { Filters, Filters as MikuFilters } from '../../core/services/list/models/Filters';
import { NewDeliveries } from '../models/newDelivery.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfirmService } from '../../utils/confirm/confirm.service';
import { LocationApiService } from '../../location/services/location-api.service';
import { Location } from '../../location/models/location.model';
import { ItemExpected } from '../models/item-expected';
import { ScannerApiService } from '../../utils/services/scanner-api.service';
import { AuthorizationService } from '../../security/services/authorization.service';
import { Delivery } from '../models/delivery.model';
import { TypeOfIcon } from '../enums/type-of-icon.enum';
import { TypeOfOrder } from '../enums/type-of-order.enum';
import { DeliveryColor } from '../enums/delivery-color.enum';
import { Meta } from '../../core/models/meta';
import { ItemInDelivery } from '../models/itemInDelivery.model';
import { IconColorItem } from '../enums/icon-color-item.enum';
import { DateHelpersService } from '../../core/services/date-helpers.service';
import { UploadedItem } from '../models/uploadedItems.model';
import { MatDialogRef } from '@angular/material/dialog';
import { DeliveryItemToAcceptanceInterface } from '../models/delivery-item-to-acceptance.interface';
import { PimService } from '../../PIM/services/pim.service';
import { AddProductSeriesModel } from '../../PIM/models/add-product-series.model';
import { Order } from '../../core/services/list/models/Order';
import { SortDirection } from '../../core/services/list/models/sort-direction.enum';
import { Paginator } from '../../core/services/list/models/Paginator';
import { DeliveryEditExpectedItemInterface } from '../../utils/modals/delivery/delivery-edit-expected-item/delivery-edit-expected-item.interface';

type DeliveryIconItem = ItemInDelivery & IconColorItem;

@Injectable({
  providedIn: 'root',
})
export class DeliveryService implements ServiceDestroyed {
  private apiRoutes = apiRoutes;

  delivery: Delivery = null;

  location: Location = null;

  serviceDestroyed = new Subject<void>();

  documentList: DocumentItem[];

  deliveryList: NewDeliveries[] = [];

  deliveryItems: ItemInDelivery[] & IconColorItem[] = [];

  deliveryDoneItems: ItemInDelivery[] & IconColorItem[] = [];

  allDeliveryItems: ItemInDelivery[] & IconColorItem[] = [];

  constructor(
    private deliveryApiService: DeliveryApiService,
    private exportService: ExportService,
    private filtersService: FiltersService,
    private toastr: ToastrService,
    private router: Router,
    private confirmService: ConfirmService,
    private locationApiService: LocationApiService,
    private scannerApiService: ScannerApiService,
    private authorizationService: AuthorizationService,
    private dateHelpersService: DateHelpersService,
    private pimService: PimService,
  ) {}

  getDeliveryList(filters: Filters): Observable<List<NewDeliveries>> {
    return this.deliveryApiService.getDeliveries(filters).pipe(tap(({ items }) => (this.deliveryList = items)));
  }

  getDocuments(deliveryId: number): Observable<List<DocumentItem>> {
    return this.deliveryApiService.getDeliveryDocuments(deliveryId).pipe(
      tap(({ items }) => {
        this.documentList = items;
        this.documentList.map(document => (document.deliveryId = deliveryId));
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  exportDeliveryList(format: Extension): Observable<File> {
    return this.exportService
      .mikuExport(this.filtersService.filters, format, this.apiRoutes.inventory.delivery.export)
      .pipe(
        tap(response => this.exportService.saveFile('export_', response, format)),
        takeUntil(this.serviceDestroyed),
      );
  }

  exportSingleDelivery(format: Extension, id: number): Observable<File> {
    return this.exportService
      .mikuExport(null, format, this.apiRoutes.inventory.delivery.pdfCsv.replace('{id}', id.toString()), { format })
      .pipe(
        tap(response => this.exportService.saveFile(`delivery-${id}-`, response, format)),
        takeUntil(this.serviceDestroyed),
      );
  }

  createDelivery(payload): Observable<any> {
    return this.deliveryApiService.createDelivery(payload).pipe(
      tap(() => {
        this.toastr.success('Delivery has been created!');
        this.router.navigateByUrl('/delivery').then(r => r);
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  finalCloseDelivery(deliveryId: number): Observable<void> {
    return this.confirmService.show('Do you want to final close the delivery?').pipe(
      mergeMap(decision => iif(() => decision, this.deliveryApiService.acceptDelivery(deliveryId, true))),
      tap(() => {
        this.toastr.success('Delivery has been final close!');
        this.router.navigateByUrl(`/delivery/delivery-archive/${deliveryId}`).then(r => r);
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  removeDelivery(deliveryId: number): Observable<void> {
    return this.confirmService.show('Do you really want to delete this delivery?').pipe(
      mergeMap((response: boolean) => iif(() => response, this.deliveryApiService.deleteDelivery(deliveryId))),
      tap(() => {
        this.toastr.success('Delivery has been deleted!');
        this.router.navigateByUrl('/delivery').then(r => r);
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  approveDelivery(message: string, deliveryId: number): Observable<any> {
    return this.confirmService.show(message).pipe(
      filter(decision => decision),
      switchMap(() => {
        return this.deliveryApiService.acceptDelivery(deliveryId).pipe(
          tap(() => {
            this.toastr.success('Delivery has been approved!');
            this.location = null;
          }),
        );
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  getDeliveryLocation(barcode: string): Observable<Location> {
    return this.locationApiService.getStorableLocation(barcode).pipe(
      tap(location => (this.location = location)),
      takeUntil(this.serviceDestroyed),
    );
  }

  checkScannedProductInDelivery(ean: string) {
    return this.scannerApiService.getProduct(ean).pipe(
      switchMap(product => {
        if (!product) {
          this.toastr.error('Selected item does not exist');
          return EMPTY;
        }

        return of({
          product,
          expectedItem: this.allDeliveryItems.filter((item: DeliveryIconItem) => item.product?.id === product.id)[0],
        });
      }),
    );
  }

  startDeliverySession(deliveryId: number): Observable<Delivery> {
    return this.deliveryApiService.getDelivery(deliveryId.toString()).pipe(
      switchMap(delivery => {
        this.delivery = delivery;
        return this.deliveryApiService
          .addEmployeeToDelivery(deliveryId, this.authorizationService.getLoggedUserId())
          .pipe(map(() => delivery));
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  getItemDetails(expectedItem: DeliveryIconItem, month: number, isDone: boolean): void {
    if (expectedItem.items.some(el => this.dateHelpersService.isProductExpires(el.expiresAt, month))) {
      expectedItem.icon = TypeOfIcon.IconShortDate;
      expectedItem.color = TypeOfOrder.ShortDateAccepted;
      expectedItem.trBackgroundColor = DeliveryColor.ShortDateDelivery;
    } else if (
      (!isDone && expectedItem.expectedQuantity === expectedItem.unapprovedQuantity) ||
      (isDone && expectedItem.expectedQuantity === expectedItem.approvedQuantity)
    ) {
      expectedItem.icon = TypeOfIcon.IconAccepted;
      expectedItem.color = TypeOfOrder.FullyAccepted;
      expectedItem.trBackgroundColor = DeliveryColor.FullDelivery;
    } else if (
      (!isDone &&
        expectedItem.expectedQuantity > expectedItem.unapprovedQuantity &&
        expectedItem.unapprovedQuantity !== 0) ||
      (isDone && expectedItem.expectedQuantity > expectedItem.approvedQuantity && expectedItem.approvedQuantity !== 0)
    ) {
      expectedItem.icon = TypeOfIcon.IconPartlyAccepted;
      expectedItem.color = TypeOfOrder.PartlyAccepted;
      expectedItem.trBackgroundColor = DeliveryColor.PartlyDelivery;
    } else if (
      (!isDone && expectedItem.unapprovedQuantity > expectedItem.expectedQuantity) ||
      (isDone && expectedItem.expectedQuantity < expectedItem.approvedQuantity)
    ) {
      expectedItem.icon = TypeOfIcon.IconOverloaded;
      expectedItem.color = TypeOfOrder.ToHighAccepted;
      expectedItem.trBackgroundColor = DeliveryColor.ToHighDelivery;
    }
  }

  checkExistEan(items, isFormGroup: boolean = false): void {
    const mapItems = isFormGroup ? [items.value] : items;
    mapItems.forEach(item => {
      if (item.ean === '') {
        item.ean = null;
      }

      isFormGroup ? items.get('eanValidate').patchValue(false) : (item.eanValidate = false);
      if (item.ean !== '' && item.ean !== null) {
        this.scannerApiService
          .getProduct(item.ean)
          .pipe(
            tap(product => {
              if (product) {
                if (isFormGroup) {
                  items.get('eanValidate').patchValue(true);
                  items.get('sku').patchValue(product.sku);
                  items.get('ean').patchValue(product.ean);
                  items.get('skuForEan').patchValue(product.sku);
                  items.get('skuValidate').patchValue(true);
                } else {
                  item.eanValidate = true;
                  item.skuForEan = product.sku;
                }
              }
            }),
            takeUntil(this.serviceDestroyed),
          )
          .subscribe();
      } else if (item.ean === '' || (item.ean === null && item.sku === '')) {
        isFormGroup ? items.get('eanValidate').patchValue(true) : (item.eanValidate = true);
        this.toastr.error('Ean or Sku is required');
      }
    });
  }

  checkExistSku(items, isFormGroup: boolean = false): void {
    const mapItems = isFormGroup ? [items.value] : items;
    mapItems.forEach(item => {
      if (item.sku === '') {
        item.sku = null;
      }

      isFormGroup ? items.get('skuValidate').patchValue(false) : (item.skuValidate = false);
      if (item.sku !== '' && item.sku !== null) {
        this.scannerApiService
          .getProductBySku(item.sku)
          .pipe(
            tap(product => {
              if (product) {
                if (isFormGroup) {
                  item.sku !== ''
                    ? items.get('skuValidate').patchValue(true)
                    : items.get('skuValidate').patchValue(false);
                  items.get('ean').patchValue(product.ean);
                  items.get('sku').patchValue(product.sku);
                } else {
                  item.skuValidate = true;
                }
              }
            }),
            takeUntil(this.serviceDestroyed),
          )
          .subscribe();
      }
    });
  }

  mapItems(deliveryId: number, isDoneItemsList: boolean, shortDate: number, items: ItemExpected[], meta: Meta) {
    const newActivityItems: DeliveryIconItem[] = items.map((item: ItemExpected) => {
      return {
        deliveryId,
        shortDate,
        id: item.id,
        ean: item.ean,
        sku: item.sku,
        name: item.product?.name,
        expectedQuantity: item.expectedQuantity,
        unapprovedQuantity: item.unapprovedQuantity,
        approvedQuantity: item.approvedQuantity,
        defaultLocationStock: item.defaultLocationStock,
        defaultLocationCount: item.defaultLocationCount,
        product: item?.product,
        isDoneItemsList,
        showExpected: false,
        comment: item.comment,
        items: item.items.map(verifiedItem => ({
          barcode: verifiedItem.location.barcode,
          expiresAt: verifiedItem.product.expiresAt,
          productId: verifiedItem.product.id,
          locationId: verifiedItem.location.id,
          itemId: verifiedItem.id,
          qty: verifiedItem.quantity,
          ean: item.ean,
          deliveryId,
          comment: verifiedItem.comment,
          product: verifiedItem.product,
        })),
      };
    });

    newActivityItems.forEach((el: DeliveryIconItem) => {
      this.getItemDetails(el, shortDate, isDoneItemsList);
    });
    return { items: newActivityItems, meta } as { items: DeliveryIconItem[]; meta: Meta };
  }

  getItems(filters: Filters, deliveryId: number, isDoneItemsList: boolean, shortDate: number) {
    return this.deliveryApiService.getDeliveryItems(isDoneItemsList, deliveryId, filters).pipe(
      map(({ items, meta }) => this.mapItems(deliveryId, isDoneItemsList, shortDate, items, meta)),
      tap(({ items }) => {
        if (!isDoneItemsList) {
          this.deliveryItems = items;
        } else {
          this.deliveryDoneItems = items;
        }
      }),
      catchError(e => of(e)),
    );
  }

  getAllItems(deliveryId: number) {
    return this.deliveryApiService
      .getAllDeliveryItems(
        deliveryId,
        new Filters([], new Order(['lastUpdatedAt', SortDirection.ASC], []), new Paginator(1, 9999)),
      )
      .pipe(
        map(({ items, meta }) => this.mapItems(deliveryId, null, null, items, meta)),
        tap(({ items }) => (this.allDeliveryItems = items)),
        takeUntil(this.serviceDestroyed),
      );
  }

  addNewItemToDelivery(deliveryId: number, uploadedItems: UploadedItem): Observable<any> {
    return this.deliveryApiService.addExpectedItemToDelivery(deliveryId, uploadedItems).pipe(
      tap(() => this.toastr.success('Product has been added successfully.')),
      takeUntil(this.serviceDestroyed),
    );
  }

  getDeliveryItems(isDone: boolean, deliveryId: number, filters: MikuFilters): Observable<any> {
    return this.deliveryApiService.getDeliveryItems(isDone, deliveryId, filters).pipe(takeUntil(this.serviceDestroyed));
  }

  editItemInDelivery(
    data: DeliveryEditExpectedItemInterface,
    productData: ItemInDelivery & IconColorItem,
    payload,
  ): Observable<{ items: DeliveryIconItem[]; meta: Meta }> {
    return this.deliveryApiService.editExpecteditemInDelivery(data.expectedItemId, payload, data.deliveryId).pipe(
      switchMap(() => this.getAllItems(data.deliveryId)),
      switchMap(() =>
        this.getItems(
          new Filters(
            [],
            new Order(['lastUpdatedAt', SortDirection.DESC], []),
            new Paginator(1, 9999, [1, 5, 25, 50, 100, 250, 500]),
          ),
          productData.deliveryId,
          productData.isDoneItemsList,
          productData.shortDate,
        ),
      ),
      tap(() => this.toastr.success(`Product with EAN ${payload.ean} has been updated successfully!`)),
      takeUntil(this.serviceDestroyed),
    );
  }

  editVariant(data, productData: ItemInDelivery & IconColorItem, payload) {
    return this.deliveryApiService.editVariant(payload, productData.deliveryId, data.itemId).pipe(
      tap(() => this.toastr.success(`Product has been updated successfully!`)),
      switchMap(() => this.getAllItems(productData.deliveryId)),
      switchMap(() => {
        return this.getItems(
          new Filters(
            [],
            new Order(['lastUpdatedAt', SortDirection.DESC], []),
            new Paginator(1, 9999, [1, 5, 25, 50, 100, 250, 500]),
          ),
          productData.deliveryId,
          productData.isDoneItemsList,
          productData.shortDate,
        );
      }),
    );
  }

  addItemExpected(
    deliveryId: number,
    data: DeliveryItemToAcceptanceInterface,
    barcode: string,
    modal: MatDialogRef<any> = null,
  ): Observable<void> {
    const { product, serialNumber, expiresAt } = data;
    const productPayload: AddProductSeriesModel = {
      product,
      serialNumber: serialNumber ?? null,
      expiresAt,
    };

    const addItemAction = this.deliveryApiService.addItemExpected(deliveryId, data).pipe(
      tap(() => {
        this.toastr.success(`Product with EAN ${barcode} has been added successfully!`);
        if (modal) modal.close();
      }),
      takeUntil(this.serviceDestroyed),
    );

    return this.pimService.checkExistProductSeries(productPayload, addItemAction);
  }
}
