import { Component, Input } from '@angular/core';
import { GenericObject } from '../models/generic-object';

@Component({
  selector: 'app-button-hide-table',
  templateUrl: './button-hide-table.component.html',
  styleUrls: ['./button-hide-table.component.scss'],
})
export class ButtonHideTableComponent {
  @Input() text: string;

  @Input() state: GenericObject;

  @Input() key: string;

  changeState() {
    this.state[this.key] = !this.state[this.key];
  }
}
