import { MassActionItem } from './mass-action-item.model';

export class MassActionSelectAll {
  private _state = false;

  private _items: MassActionItem[] = null;

  set state(state: boolean) {
    this._state = state;
  }

  get state() {
    return this._state;
  }

  set items(items: MassActionItem[]) {
    this._items = items;
  }

  get items() {
    return this._items;
  }

  reset() {
    this._state = false;
    if (this._items) {
      this._items.map(item => (item.massAction = false));
      this._items = null;
    }
  }
}
