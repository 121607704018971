import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public message: string;
  public isVisible = false;
  public response: Subject<boolean> = new Subject<boolean>();

  public show(message: string): Observable<boolean> {
    return new Observable(observer => {
      this.response = new Subject<boolean>();
      this.message = message;
      this.isVisible = true;

      this.response.subscribe(value => {
        this.isVisible = false;
        return observer.next(value);
      });
    });
  }

  public getMessage() {
    return this.message;
  }

  public getIsVisible() {
    return this.isVisible;
  }

  public accept() {
    this.response.next(true);
  }
}
