import { Component } from '@angular/core';
import { MassactionService } from '../massaction.service';
import { FiltersService } from '../../filters/services/filters.service';

@Component({
  selector: 'app-massaction-select-by-filters',
  templateUrl: './massaction-select-by-filters.component.html',
})
export class MassactionSelectByFiltersComponent {
  constructor(public massActionService: MassactionService, private filtersService: FiltersService) {}

  changeState(checked: boolean) {
    this.massActionService.resetItems();
    if (checked) {
      this.massActionService.selectByFilters.filters = this.filtersService.filters;
    } else {
      this.massActionService.selectByFilters.filters = null;
    }
  }
}
