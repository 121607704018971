import { Component, Input } from '@angular/core';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent {
  @Input() text: string;

  tippyProps: NgxTippyProps = {
    arrow: true,
    placement: 'bottom',
    allowHTML: true,
    appendTo: 'parent',
    interactive: true,
    interactiveBorder: 50,
    trigger: 'click',
  };
}
