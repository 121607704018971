import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { EanValidatorService } from '../../barcodeInput/eanValidator.service';

export const EAN_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EANValidator),
  multi: true,
};

@Directive({
  selector: '[ean][formControlName],[ean][formControl],[ean][ngModel]',
  providers: [EAN_VALIDATOR],
})
export class EANValidator implements Validator {
  public eanValidatorService: EanValidatorService;

  constructor(eanValidatorService: EanValidatorService) {
    this.eanValidatorService = eanValidatorService;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (!c.value) {
      return null;
    }

    return this.eanValidatorService.validate(c.value) ? null : ['eanInvalid'];
  }

  registerOnValidatorChange(fn: () => void): void {}
}
