import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../core/api.service';
import { apiRoutes } from '../../../../environments/api-routes';
import { Observable } from 'rxjs';
import { CommentTypeEnum } from '../../models/comment/comment.enum';
import { CommentInterface } from '../../models/comment/comment.interface';
import { List } from '../../../core/models/list';

@Injectable({
  providedIn: 'root',
})
export class CommentApiService {
  private apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getComments(type: CommentTypeEnum): Observable<List<CommentInterface>> {
    return this.http.get<List<CommentInterface>>(
      this.apiService.getMikuUrl(this.apiRoutes.commentList.root.replace('{type}', type)),
    );
  }

  addComment(type: CommentTypeEnum, comment: string): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.commentList.root.replace('{type}', type)), {
      value: comment,
    });
  }

  updateComment(type: CommentTypeEnum, comment: string, id: number): Observable<void> {
    return this.http.patch<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.commentList.edit.replace('{type}', type).replace('{id}', id.toString()),
      ),
      {
        value: comment,
      },
    );
  }

  deleteComment(type: CommentTypeEnum, id: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.commentList.edit.replace('{type}', type).replace('{id}', id.toString()),
      ),
    );
  }
}
