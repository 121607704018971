import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './security/components/login/login.component';
import { AclGuard } from './core/services/acl.guard';
import { AuthGuard } from './core/services/auth.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: {
        info: { path: '/' },
      },
    },
  },
  {
    path: 'document',
    loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
    canActivate: [AclGuard, AuthGuard],
    data: {
      acl: ['document.list'],
      breadcrumb: {
        info: { path: '/document' },
      },
    },
  },
  {
    path: 'pick',
    loadChildren: () => import('./collect/collect.module').then(m => m.CollectModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['order.collect'],
      breadcrumb: {
        info: { path: '/pick' },
      },
    },
  },
  {
    path: 'delivery',
    loadChildren: () => import('./delivery/delivery.module').then(m => m.DeliveryModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['delivery.list'],
      breadcrumb: {
        info: { path: '/delivery' },
      },
    },
  },
  {
    path: 'monitoring',
    loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['logs.list'],
      breadcrumb: {
        info: { path: '/monitoring' },
      },
    },
  },
  {
    path: 'pack',
    loadChildren: () => import('./pack/pack.module').then(m => m.PackModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['order.pack'],
      breadcrumb: {
        info: { path: '/pack' },
      },
    },
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['order.list'],
      breadcrumb: {
        info: { path: '/orders' },
      },
    },
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['system.settings.update'],
      breadcrumb: {
        info: { path: '/settings' },
      },
    },
  },
  {
    path: 'staff',
    loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['employee.list'],
      breadcrumb: {
        info: { path: '/staff' },
      },
    },
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['reports.list'],
      breadcrumb: {
        info: { path: '/reports' },
      },
    },
  },
  {
    path: 'report-activity',
    loadChildren: () => import('./report-activity/report-activity.module').then(m => m.ReportActivityModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: {
        info: { path: '/report-activity' },
      },
    },
  },
  {
    path: 'statistics',
    loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['reports.list'],
      breadcrumb: {
        info: { path: '/statistics' },
      },
    },
  },
  {
    path: 'handling-pallets',
    loadChildren: () => import('./handling-pallets/handling-pallets.module').then(m => m.HandlingPalletsModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['reports.list'],
      breadcrumb: {
        info: { path: '/handling-pallets' },
      },
    },
  },
  {
    path: 'locations',
    loadChildren: () => import('./location/location.module').then(m => m.LocationModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['location.list'],
      breadcrumb: {
        info: { path: '/locations' },
      },
    },
  },
  {
    path: 'inventory-check',
    loadChildren: () => import('./inventory-check/inventory-check.module').then(m => m.InventoryCheckModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['inventory_check.handle'],
      breadcrumb: {
        info: { path: '/inventory-check' },
      },
    },
  },
  {
    path: 'goods-movement',
    loadChildren: () => import('./goods-movement/goods-movement.module').then(m => m.GoodsMovementModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['goods_movement.handle'],
      breadcrumb: {
        info: { path: '/goods-movement' },
      },
    },
  },
  {
    path: 'warehouse',
    loadChildren: () => import('./warehouse/warehouse.module').then(m => m.WarehouseModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['warehouse.list'],
      breadcrumb: {
        info: { path: '/warehouse' },
      },
    },
  },
  {
    path: 'masterpallets',
    loadChildren: () => import('./masterpallet/masterpallet.module').then(m => m.MasterpalletModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['masterpallet.list'],
      breadcrumb: {
        info: { path: '/masterpallets' },
      },
    },
  },
  {
    path: 'replenishment',
    loadChildren: () => import('./replenishment/replenishment.module').then(m => m.ReplenishmentModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['replenishment.list'],
      breadcrumb: {
        info: { path: '/replenishment' },
      },
    },
  },
  {
    path: 'transfer-request',
    loadChildren: () => import('./transferRequest/transferRequest.module').then(m => m.TransferRequestModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['transfer_request.list'],
      breadcrumb: {
        info: { path: '/transfer-request' },
      },
    },
  },
  {
    path: 'returns',
    loadChildren: () => import('./returns/returns.module').then(m => m.ReturnsModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['return.handle'],
      breadcrumb: {
        info: { path: '/returns' },
      },
    },
  },
  {
    path: 'pim',
    loadChildren: () => import('./PIM/pim.module').then(m => m.PIMModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['product.list'],
      breadcrumb: {
        info: { path: '/pim' },
      },
    },
  },
  {
    path: 'labeling',
    loadChildren: () => import('./sticker/sticker.module').then(m => m.StickerModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['sticker.list', 'sticker.create', 'sticker.delete'],
      breadcrumb: {
        info: { path: '/labeling' },
      },
    },
  },
  {
    path: 'label-printer',
    loadChildren: () => import('./label-printer/label-printer.module').then(m => m.LabelPrinterModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['label_printer.list'],
      breadcrumb: {
        info: { path: '/label-printer' },
      },
    },
  },
  {
    path: 'acl',
    loadChildren: () => import('./acl/acl.module').then(m => m.AclModule),
    canActivate: [AuthGuard],
    data: {
      acl: ['system.settings.update'],
      breadcrumb: {
        info: { path: '/acl' },
      },
    },
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
