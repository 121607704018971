import { Component, Input } from '@angular/core';
import { PackingMobileTableHeader } from './packing-mobile-table-header.model';

@Component({
  selector: 'app-packing-mobile-table-header',
  templateUrl: './packing-mobile-table-header.component.html',
  styleUrls: ['./packing-mobile-table-header.component.scss'],
})
export class PackingMobileTableHeaderComponent {
  @Input() data: PackingMobileTableHeader;
}
