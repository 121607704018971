import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-miku-calendar-datepicker',
  templateUrl: './miku-calendar-datepicker.component.html',
  styleUrls: ['./miku-calendar-datepicker.component.scss'],
})
export class MikuCalendarDatepickerComponent {
  @Input() dateControl: FormControl;
  @Input() label: string;
}
