import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WriteSeriesAndQuantity } from '../write-series-and-quantity/write-series-and-quantity.model';
import { TableHeaders } from '../../table/models/tableHeaders.model';
import { TableAlign } from '../../table/models/tableAlign.enum';
import { TableCell } from '../../table/models/tableCell.model';
import { TableCellType } from '../../table/models/tableCellType.enum';
import { EditReturnCellComponent } from './tabl-cells/edit-return-cell/edit-return-cell.component';

@Component({
  selector: 'app-return-all-order-modal',
  templateUrl: './return-all-order-modal.component.html',
  styleUrls: ['./return-all-order-modal.component.scss'],
})
export class ReturnAllOrderModalComponent {
  @Output() save = new EventEmitter();

  @Output() cancel = new EventEmitter();

  headers: TableHeaders[] = [
    { value: 'Quantity', align: TableAlign.center },
    { value: 'Name' },
    { value: 'EAN' },
    { value: 'SKU' },
    { value: 'Serial number', align: TableAlign.center },
    { value: 'Expires at', align: TableAlign.center },
    { value: '' },
  ];

  cells: TableCell[] = [
    { type: TableCellType.text, key: 'quantity', align: TableAlign.center },
    { type: TableCellType.text, key: 'product.name' },
    { type: TableCellType.text, key: 'product.ean' },
    { type: TableCellType.text, key: 'product.sku' },
    { type: TableCellType.text, key: 'product.serialNumber', align: TableAlign.center },
    {
      type: TableCellType.date,
      key: 'product.expiresAt',
      dateFormat: 'YYYY-MM-DD',
      align: TableAlign.center,
      class: 'text-nowrap',
    },
    {
      type: TableCellType.customComponent,
      component: EditReturnCellComponent,
      key: '',
      align: TableAlign.center,
      class: 'text-nowrap',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<ReturnAllOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WriteSeriesAndQuantity[],
  ) {}

  onSave() {
    const filteredData = this.data.filter(({ quantity }) => quantity > 0);
    this.save.emit(filteredData);
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }
}
