import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DatepickerService } from '../datepicker.service';

@Component({
  templateUrl: 'datepickerModal.component.html',
  styleUrls: ['../datepicker.scss'],
})
export class DatepickerModalComponent {
  public time: boolean;
  public date: Date;
  public selectors: string[] = ['year', 'month', 'day'];
  public name = '';

  constructor(public modal: BsModalRef, public datepickerService: DatepickerService) {}

  public onDateEventEmit($event) {
    this.datepickerService.setDate($event.getTime(), this.name);
  }

  public setDate(date: number): void {
    if (isNaN(date) || date === 0 || date === null) {
      this.date = new Date();
    } else {
      this.date = new Date(date);
    }
  }

  public setName(name: string): void {
    this.name = name;
  }

  public setWithTime(withTime: boolean): void {
    this.time = withTime;
  }

  public setSelectors(selectors: string[] = ['year', 'month', 'day']): void {
    this.selectors = selectors;
  }

  public save(): void {
    this.modal.hide();
  }

  public reset(): void {
    this.datepickerService.setDate(null, this.name);
    this.modal.hide();
  }
}
