import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringHelpersService {
  trimMimeType(blob: string): string {
    return blob.split(',')[1];
  }

  toFirstLetterLowerCase(value: string): string {
    return value.charAt(0).toLowerCase() + value.slice(1);
  }

  toFirstLetterUpperCase(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
