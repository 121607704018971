import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-inventory-check-request-modal',
  templateUrl: './add-inventory-check-request-modal.component.html',
})
export class AddInventoryCheckRequestModalComponent {
  @Output() confirm = new EventEmitter();

  form = this.formBuilder.group({
    scannedCode: ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<AddInventoryCheckRequestModalComponent>,
    private formBuilder: FormBuilder,
  ) {}

  onConfirm() {
    if (this.form.valid) {
      this.confirm.emit(this.form.controls.scannedCode.value);
      this.dialogRef.close();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
