import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgressBar } from '../progress-bar/progress-bar.model';
import { CommentInterface } from '../../models/comment/comment.interface';

@Component({
  selector: 'app-add-comment-modal',
  templateUrl: './add-comment-modal.component.html',
  styleUrls: ['./add-comment-modal.component.scss'],
})
export class AddCommentModalComponent implements OnInit {
  @Output() approve = new EventEmitter();

  comment = new FormControl('', [Validators.required, Validators.minLength(20)]);

  constructor(
    private dialogRef: MatDialogRef<AddCommentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommentInterface,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.comment.patchValue(this.data.value);
    }
  }

  onApprove() {
    if (this.comment.valid) {
      this.approve.emit(this.comment.value);
      this.dialogRef.close();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
