import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { Conditions, FilterField } from './FilterField';
import { FieldTypesEnum } from './field-types.enum';
import { DateHelpersService } from '../../date-helpers.service';

export class RangeDateFilterField extends FilterField {
  fromValue = new FormControl(null);

  toValue = new FormControl(null);

  constructor(name: string, label: string, dateFrom: Date = null, dateTo: Date = null) {
    super(name, label, FieldTypesEnum.RANGE_DATEPICKER);
    this.setCondition([Conditions.GTE, Conditions.LTE]);
    if (dateFrom !== null && dateTo !== null) {
      this.patchValue(dateFrom, dateTo);
    }
  }

  patchValue(valueFrom: Date, valueTo: Date): FilterField {
    if (!DateHelpersService.isDateValid(valueFrom) || !DateHelpersService.isDateValid(valueTo)) {
      console.warn(`Invalid date provided ${new Error().stack.split('\n')[2].trim()}`);
      return this;
    }

    if (valueFrom > valueTo) {
      console.warn(`Date from cannot be greater then date to ${new Error().stack.split('\n')[2].trim()}`);
      this.fromValue.patchValue(moment(valueFrom));
      this.toValue.patchValue(moment(valueFrom));
      return this;
    }
    this.toValue.patchValue(moment(valueTo));
    this.fromValue.patchValue(moment(valueFrom));
    return this;
  }

  setValidators(): FilterField {
    console.warn(`setValidators not implemented ${new Error().stack.split('\n')[2].trim()}`);
    return this;
  }

  isValid(): boolean {
    return true;
  }

  clear() {
    this.fromValue.patchValue(null);
    this.toValue.patchValue(null);
  }

  get fieldControl$(): Observable<FormControl[]> {
    return new BehaviorSubject<FormControl[]>([this.fromValue, this.toValue]).asObservable();
  }
}
