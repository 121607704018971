import {
  Component, Inject, OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DeleteDialogData } from './DeleteDialogData';
import { ComponentDestroy, TakeUntilDestroy } from '../../core/decorators/take-until-destroy';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
@TakeUntilDestroy
export class DeleteModalComponent implements OnDestroy, ComponentDestroy {
  componentDestroy: () => Observable<void>;

  validationFields = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DeleteDialogData,
    private dialogRef: MatDialogRef<DeleteModalComponent>,
  ) { }

  ngOnDestroy(): void {
  }

  delete() {
    this.dialogData.deleteObservable.pipe(
      tap(() => {
        this.dialogData.refreshSubject.next();
        this.dialogRef.close();
      }),
      takeUntil(this.componentDestroy()),
    ).subscribe();
  }
}
