import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { WarehouseService } from '../../../core/services/warehouse.service';
import { takeUntil, tap } from 'rxjs/operators';
import { TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { Observable } from 'rxjs';
import { WarehouseListInterface } from '../../../core/models/warehouse-list.interface';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-warehouse-to-product',
  templateUrl: './add-warehouse-to-product.component.html',
})
@TakeUntilDestroy
export class AddWarehouseToProductComponent implements OnInit, OnDestroy {
  @Output() approve = new EventEmitter<number>();

  private componentDestroy: () => Observable<void>;

  form = this.formBuilder.group({
    selectedWarehouse: [null, [Validators.required]],
  });

  warehouses: WarehouseListInterface[] = [];

  constructor(
    private warehouseService: WarehouseService,
    private dialogRef: MatDialogRef<AddWarehouseToProductComponent>,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.warehouseService
      .getWarehouseList()
      .pipe(
        tap(({ items }) => {
          this.warehouses = items;
          // @ts-ignore
          this.form.patchValue({ selectedWarehouse: items[0].id });
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  onApprove(): void {
    if (this.form.valid) {
      this.approve.emit(this.form.value.selectedWarehouse);
      this.dialogRef.close();
    }
  }

  onReject(): void {
    this.dialogRef.close();
  }
}
