import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { OrderStatusEnum } from '../../core/enums/order-status.enum';
import { OrderStatusApiService } from './order-status-api.service';

@Component({
  selector: 'app-change-order-status',
  templateUrl: './change-order-status.component.html',
  styleUrls: ['./change-order-status.component.scss'],
})
export class ChangeOrderStatusComponent implements OnInit {
  @Input() changeItems: { id: number }[];

  @Output() changeSuccess = new EventEmitter();

  orderStatusEnum = OrderStatusEnum;

  status: OrderStatusEnum;

  constructor(private toastr: ToastrService, private orderStatusApiService: OrderStatusApiService) {}

  ngOnInit(): void {
    this.status = this.orderStatusEnum.DISPATCHED;
  }

  changeStatus(): void {
    if (this.changeItems && this.changeItems.length > 0) {
      switch (this.status) {
        case this.orderStatusEnum.DISPATCHED:
          this.orderStatusApiService
            .postDispatched(this.changeItems.map(item => item.id))
            .pipe(
              tap(() => {
                this.changeSuccess.emit();
                this.toastr.success('Success!');
              }),
            )
            .subscribe();
          break;
        default:
          this.toastr.error('Something went wrong!');
          break;
      }
    } else {
      this.toastr.error('You need to select items!');
    }
  }
}
