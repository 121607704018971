import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertSecondsToMinutes',
})
export class ConvertSecondsToMinutesPipe implements PipeTransform {
  transform(timeInSeconds: number): string | number {
    if (timeInSeconds) {
      const minutes = Math.floor(timeInSeconds / 60).toString();
      const seconds = Math.floor(timeInSeconds % 60).toString();

      return `${minutes.length < 2 ? '0' + minutes : minutes}:${seconds.length < 2 ? '0' + seconds : seconds}`;
    }
    return timeInSeconds;
  }
}
