import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/api.service';
import { apiRoutes } from 'src/environments/api-routes';
import { Order } from '../models/order.model';
import { Image } from '../models/image.model';
import { List } from '../../core/models/list';
import { Replenishment } from '../../replenishment/models/replenishment.model';
import { Stock } from '../models/stock.model';
import { Status } from '../models/status.model';
import { Type } from '../models/type.model';
import { Filters } from '../../core/services/list/models/Filters';
import { ShippingInfoInterface } from '../models/shipping-info.interface';
import { ShippingDocumentInterface } from '../models/shipping-document.interface';
import { OrderSearchInterface } from '../models/order-search.interface';
import { ShippingAddressInterface } from '../models/shipping-address.interface';
import { ShippingMethodInterface } from '../models/shipping-method.interface';
import { InvalidAddressesInterface } from '../models/invalid-addresses.interface';
import { ConvertedAddressPayloadInterface } from '../models/converted-address-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService {
  private apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getOrders(filters: Filters): Observable<Order> {
    return this.http.get<Order>(this.apiService.getMikuUrl(this.apiRoutes.sales.order.root, filters));
  }

  changeOrderStatus(orderId: number, status: string): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.getOrder.replace('{id}', orderId.toString())),
      {
        status,
      },
    );
  }

  changeOrderComment(orderId: number, shippingComment: string): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.getOrder.replace('{id}', orderId.toString())),
      {
        shippingComment,
      },
    );
  }

  getOrder(id: number): Observable<Order> {
    return this.http.get<Order>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.getOrder.replace('{id}', id.toString()), null, {
        includeStatistics: true,
      }),
    );
  }

  getOrderImages(orderId: number): Observable<List<Image>> {
    return this.http.get<List<Image>>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.images.get.replace('{orderId}', orderId.toString())),
    );
  }

  getBlockedReplenishments(orderId: number): Observable<List<Replenishment>> {
    return this.http.get<List<Replenishment>>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.blockedReplenishments.replace('{id}', orderId.toString())),
    );
  }

  orderAvailableStock(orderId: number): Observable<List<Stock>> {
    return this.http.get<List<Stock>>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.availableStock.replace('{orderId}', orderId.toString())),
    );
  }

  getOrderStatuses(): Observable<List<Status>> {
    return this.http.get<List<Status>>(this.apiService.getMikuUrl(this.apiRoutes.sales.order.status.root));
  }

  getOrderType(): Observable<List<Type>> {
    return this.http.get<any>(this.apiService.getMikuUrl(this.apiRoutes.sales.order.type));
  }

  setNoImagesToLogs(orderId: number): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.noImagesLog.replace('{id}', orderId.toString())),
      null,
    );
  }

  getShippingInfo(orderId: number): Observable<ShippingInfoInterface> {
    return this.http.get<ShippingInfoInterface>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.shippingInfo.replace('{orderId}', orderId.toString())),
    );
  }

  getShippingDocuments(orderId: number): Observable<List<ShippingDocumentInterface>> {
    return this.http.get<List<ShippingDocumentInterface>>(
      this.apiService.getMikuUrl(
        this.apiRoutes.sales.order.documents.getByOrder.replace('{orderId}', orderId.toString()),
      ),
    );
  }

  downloadMassDocuments(orderDocuments: number[]): Observable<any> {
    return this.http.post(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.documents.massDownload),
      {
        orderDocuments,
      },
      { responseType: 'blob' as 'json' },
    );
  }

  downloadWaybill(packageOrderId: number | string): Observable<any> {
    return this.http.get(
      this.apiService.getMikuUrl(
        this.apiRoutes.sales.order.documents.waybillDownload.replace('{id}', packageOrderId?.toString()),
      ),
      { responseType: 'blob' as 'json' },
    );
  }

  markSpecialPackage(orderId: number | string): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.packing.specialPacking.toggleStatus.replace('{id}', orderId.toString()),
      ),
      {},
    );
  }

  removeSpecialPackageMark(orderId: number | string): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.packing.specialPacking.toggleStatus.replace('{id}', orderId.toString()),
      ),
    );
  }

  changeTrackingNumber(orderId: number | string, trackingNumber: string): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.changeTrackingNumber.replace('{id}', orderId.toString())),
      {
        tracking_number: trackingNumber,
      },
    );
  }

  changeShippingMethod(data: ShippingMethodInterface, orderId: number | string): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.changeShippingMethod.replace('{id}', orderId.toString())),
      data,
    );
  }

  getShippingMethods(warehouseId: number): Observable<List<ShippingMethodInterface>> {
    return this.http.get<List<ShippingMethodInterface>>(
      this.apiService.getMikuUrl(
        this.apiRoutes.sales.order.shippingMethodList.replace('{warehouseId}', warehouseId.toString()),
      ),
    );
  }

  filterOrderByTrackingNumber(trackingNumber: string): Observable<OrderSearchInterface> {
    return this.http.get<OrderSearchInterface>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.search, null, { trackingNumber }),
    );
  }

  autoPackOrder(orderId: number | string): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.autoPack.replace('{orderId}', orderId.toString())),
      null,
    );
  }

  getUnresolvedInvalidAddressesList(): Observable<List<InvalidAddressesInterface>> {
    return this.http.get<List<InvalidAddressesInterface>>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.invalidAddresses.unresolved),
    );
  }

  getResolvedInvalidAddressesList(filters: Filters): Observable<List<InvalidAddressesInterface>> {
    return this.http.get<List<InvalidAddressesInterface>>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.invalidAddresses.resolve, filters),
    );
  }

  mapInvalidAddress(payload: ConvertedAddressPayloadInterface): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.invalidAddresses.resolve),
      payload,
    );
  }

  cancelShipment(orderId: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.cancelShipment.replace('{orderId}', orderId.toString())),
      {},
    );
  }

  refreshShipmentStatus(orderId: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.sales.order.refreshShipmentStatus.replace('{orderId}', orderId.toString()),
      ),
      {},
    );
  }
}
