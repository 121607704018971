import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from 'src/environments/api-routes';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { List } from '../../core/models/list';
import { Filters } from '../../core/services/list/models/Filters';
import { ApiService } from '../../core/api.service';
import { Document } from '../models/document.model';
import { DocumentType } from '../models/document-type.model';
import { SelectModel } from '../../utils/miku-select/select.model';
import { DocumentView } from '../models/document-view.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiService {
  private apiRoutes = apiRoutes;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  getDocumentList(filters: Filters): Observable<List<Document>> {
    return this.http.get<List<Document>>(this.apiService.getMikuUrl(this.apiRoutes.document.root, filters));
  }

  getDocumentTypesSelect(): Observable<SelectModel<DocumentType>[]> {
    return this.http.get(this.apiService.getMikuUrl(this.apiRoutes.document.types)).pipe(
      map(({ items }: List<DocumentType>) => [
        new SelectModel<DocumentType>({ code: null } as DocumentType, 'Any'),
        ...items.map((el: DocumentType) => new SelectModel<DocumentType>(el, el.name)),
      ]),
      shareReplay(),
    );
  }

  getDocument(code: string, id: number): Observable<DocumentView> {
    return this.http.get<DocumentView>(
      this.apiService.getMikuUrl(this.apiRoutes.document.id).replace('{code}', code).replace('{id}', id.toString()),
    );
  }
}
