import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class CorrelationService {
  correlationId: string;

  constructor() {
    this.correlationId = uuid();
  }

  get id() {
    return this.correlationId;
  }

  generateId(): void {
    this.correlationId = uuid();
  }
}
