import { AfterViewInit, Directive, ElementRef, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { Route, Router } from '@angular/router';

@Directive({
  selector: '[appTestId]',
})
export class TestIdDirective implements AfterViewInit {
  @Input('appTestId') testId: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    if (this.testId) {
      this.renderer?.setAttribute(
        this.el.nativeElement,
        'data-test-id',
        this.testId.replace(/\s+/g, '-').toLowerCase(),
      );
    }
  }
}
