import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Filters } from './models/filters';
import { Filters as MikuFilters } from './services/list/models/Filters';
import { RelationList } from './models/RelationList';
import { TextFilterField } from './services/list/models/TextFilterField';
import { FieldTypesEnum } from './services/list/models/field-types.enum';
import { Conditions } from './services/list/models/FilterField';
import { BooleanFilterField } from './services/list/models/BooleanFilterField';
import { SelectFilterField } from './services/list/models/SelectFilterField';
import { RangeNumberFilterField } from './services/list/models/RangeNumberFilterField';
import { NumberFilterField } from './services/list/models/NumberFilterField';
import { MikuGenericObject } from '../utils/models/miku-generic-object';
import { DateFilterField } from './services/list/models/DateFilterField';
import { RangeDateFilterField } from './services/list/models/RangeDateFilterField';
import { DefaultComponentFilter } from './services/list/models/DefaultComponentFilter';
import { ManualParameters } from './services/list/models/ManualParameters.model';
import { AutocompleteFilterField } from './services/list/models/AutocompleteFilterField';
import { MultiselectFilterField } from './services/list/models/MultiselectFilterField';
import * as moment from 'moment';
import { SelectModelValue } from '../utils/miku-select/select.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * @deprecated
   * use getMikuUrl
   */
  public getUrl(path: string, parameters?: Filters): string {
    let url = environment.wms_api_url + path;
    if (parameters) {
      const parametersArray = [];
      Object.entries(parameters).forEach(([key, value]: [string, string]) => {
        if (value !== undefined) {
          parametersArray.push(`${key}=${encodeURIComponent(value)}`);
        }
      });
      url += `?${parametersArray.join('&')}`;
    }
    return url;
  }

  // @TODO: do sprawdzenia cała funkcja
  public getMikuUrl(
    path: string,
    parameters?: MikuFilters,
    genericParams: MikuGenericObject<any> = {},
    manualParams?: ManualParameters[],
  ): string {
    let url = environment.wms_api_url + path;
    const parameterArray = [];
    let index = 0;

    if (parameters) {
      const { fields, order, paginator } = parameters;
      parameterArray.push(
        `page=${paginator.page}&perPage=${paginator.perPage}&sortBy=${
          order.key.value?.key ?? order.key.value
        }&sortDir=${order.sortDirection.value?.key ?? order.sortDirection.value}`,
      );

      fields.forEach(field => {
        if (field.asParameter) genericParams[field.key] = field.valueControl.value;

        let newField;
        switch (field.type) {
          case FieldTypesEnum.TEXT:
            newField = field as TextFilterField;
            if (newField.valueControl.value && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition,
                  newField.valueControl.value.toString(),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            break;

          case FieldTypesEnum.NUMBER:
            newField = field as NumberFilterField;
            if (newField.valueControl.value != null && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition,
                  newField.valueControl.value.toString(),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            break;

          case FieldTypesEnum.BOOLEAN:
            newField = field as BooleanFilterField;
            if (newField.valueControl.value != null && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition,
                  typeof newField.valueControl.value !== 'string'
                    ? (+newField.valueControl.value).toString()
                    : newField.valueControl.value,
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            break;

          case FieldTypesEnum.MULTISELECT:
            newField = field as MultiselectFilterField;
            if (newField.valueControl.value != null && newField.if && !field.asParameter) {
              const value = newField.valueControl.value.map((el: SelectModelValue<any>) => el.id).join(',');
              parameterArray.push(
                this.buildFilterQuery(newField.key, newField.condition, value, index, newField.clause),
              );
              index += 1;
            }

            break;

          case FieldTypesEnum.AUTOCOMPLETE:
            newField = field as AutocompleteFilterField;
            if (newField.valueControl.value?.value != null && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition,
                  newField.valueControl.value.value.toString(),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            break;

          case FieldTypesEnum.SELECT:
            newField = field as SelectFilterField;
            if (newField.valueControl.value?.id != null && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition,
                  newField.valueControl.value.id.toString(),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            break;

          case FieldTypesEnum.RANGE_NUMBER:
            newField = field as RangeNumberFilterField;
            if (newField.fromValue.value != null && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition[0],
                  newField.fromValue.value.toString(),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            if (newField.toValue.value != null && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition[1],
                  newField.toValue.value.toString(),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            break;

          case FieldTypesEnum.DATEPICKER:
            newField = field as DateFilterField;
            if (newField.valueControl.value !== null && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition,
                  newField.valueControl.value.format('YYYY-MM-DD'),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            break;

          case FieldTypesEnum.RANGE_DATEPICKER:
            newField = field as RangeDateFilterField;
            if (newField.fromValue.value !== null && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition[0],
                  moment(newField.fromValue.value).format('YYYY-MM-DD'),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            if (newField.toValue.value !== null && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition[1],
                  moment(newField.toValue.value).format('YYYY-MM-DD'),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }

            break;

          case FieldTypesEnum.DEFAULT_COMPONENT:
            newField = field as DefaultComponentFilter;
            if (newField.valueControl.value && newField.if && !field.asParameter) {
              parameterArray.push(
                this.buildFilterQuery(
                  newField.key,
                  newField.condition,
                  newField.valueControl.value.toString(),
                  index,
                  newField.clause,
                ),
              );
              index += 1;
            }
            break;

          default:
            throw new Error(`Invalid fieldType: ${field.type}`);
        }
      });
    }

    if (genericParams) {
      Object.entries(genericParams).forEach(([key, value]: [string, string]) => {
        if (value !== undefined) {
          parameterArray.push(`${key}=${encodeURIComponent(value)}`);
        }
      });
    }

    if (parameterArray.length > 0) {
      url += `?${parameterArray.join('&')}`;
    }

    if (manualParams) {
      manualParams.forEach(({ condition, key, value, clause }) => {
        if (value !== undefined) {
          url += `&${this.buildFilterQuery(key, condition, value, index, clause)}`;
          index += 1;
        }
      });
    }
    return url;
  }

  private buildFilterQuery(key: string, condition: Conditions, value: string, i: number, clause: string): string {
    // eslint-disable-next-line max-len
    const path = `filters[${i}][field]=${key}&filters[${i}][cond]=${condition}&filters[${i}][val]=${encodeURIComponent(
      value,
    )}`;

    return clause ? `${path}&filters[${i}][clause]=${clause}` : path;
  }

  public handleError(err: HttpErrorResponse): string {
    let errorMessage: string;
    if (err?.error?.message) {
      errorMessage = err.error.message;
    } else {
      errorMessage = 'Unexpected error occurs.';
    }
    return errorMessage;
  }

  public getFormDataHeaders(): HttpHeaders {
    return new HttpHeaders({ enctype: 'multipart/form-data' });
  }

  calculateRelationList(oldIds: number[], newIds: number[]): RelationList {
    const data = new RelationList();

    data.setAdd(newIds.filter(el => !oldIds.includes(el)));
    data.setDelete(oldIds.filter(el => !newIds.includes(el)));

    return data;
  }
}
