import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PimApiService } from '../../PIM/services/pim-api.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Product } from '../../PIM/models/product.model';
import { TakeUntilDestroy } from '../../core/decorators/take-until-destroy';
import { Observable } from 'rxjs';

export interface InvalidEanScannedInitData {
  ean: string;
}

@Component({
  templateUrl: './invalid-ean-scanned.component.html',
  styleUrls: ['./invalid-ean-scanned.component.scss'],
})
@TakeUntilDestroy
export class InvalidEanScannedComponent implements OnInit, OnDestroy {
  componentDestroy: () => Observable<void>;

  scannedProduct: Product = null;

  eanControl: FormControl = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public initData: InvalidEanScannedInitData,
    private dialogRef: MatDialogRef<InvalidEanScannedComponent>,
    private toastr: ToastrService,
    private pimApiService: PimApiService,
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.pimApiService
      .getProductByEan(this.initData.ean)
      .pipe(
        tap(product => (this.scannedProduct = product)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  onCodeScan(value: string): void {
    if (this.initData.ean !== value) {
      this.toastr.error('Invalid ean provided');
    } else {
      this.toastr.success('Success!');
      this.dialogRef.close(1);
    }
  }
}
