import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CollectItem } from '../../../collect/models/collectItem.model';
import { AddProductSeriesModel } from '../../../PIM/models/add-product-series.model';

@Component({
  selector: 'app-add-product-series-modal',
  templateUrl: './add-product-series-modal.component.html',
  styleUrls: ['./add-product-series-modal.component.scss'],
})
export class AddProductSeriesModalComponent implements OnInit {
  @Output() onApprove = new EventEmitter<AddProductSeriesModel>();

  @Output() onReject = new EventEmitter();

  form: FormGroup = this.fb.group({
    serialNumber: [null],
    producedAt: [null],
    expiresAt: [null],
  });

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<AddProductSeriesModalComponent>) {}

  ngOnInit(): void {}

  approve() {
    this.onApprove.emit(this.form.value);
    this.dialogRef.close();
  }

  reject() {
    this.onReject.emit();
    this.dialogRef.close();
  }
}
