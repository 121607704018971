import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../core/api.service';
import { Observable } from 'rxjs';
import { apiRoutes } from '../../../../environments/api-routes';
import { PackageModel } from '../../models/package.model';
import { List } from '../../../core/models/list';
import { SetNumberOfPackageInterface } from '../../../pack/model/set-number-of-package.interface';

@Injectable({
  providedIn: 'root',
})
export class PackageApiService {
  private apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getPackageSizesList(): Observable<List<PackageModel>> {
    return this.http.get<List<PackageModel>>(this.apiService.getMikuUrl(this.apiRoutes.package.packageSize));
  }

  addPackageSize(payload: PackageModel): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.package.packageSize), payload);
  }

  editPackageSize(packageId: number, payload: PackageModel): Observable<void> {
    return this.http.patch<void>(
      this.apiService.getUrl(this.apiRoutes.package.singlePackage.replace('{id}', packageId.toString())),
      payload,
    );
  }

  removePackage(id: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.package.singlePackage.replace('{id}', id.toString())),
    );
  }

  setNumberOfPackageToPack(payload: SetNumberOfPackageInterface): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.package.setNumberOfPackageToPack), payload);
  }
}
