import { Component, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplenishmentApiService } from 'src/app/replenishment/services/replenishment-api.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Replenishment } from '../../../models/replenishment.model';

@Component({
  selector: 'app-replenishment-related-edit-modal',
  templateUrl: './replenishment-related-edit-modal.component.html',
  styleUrls: ['./replenishment-related-edit-modal.component.scss'],
})
export class ReplenishmentRelatedEditModalComponent implements OnDestroy {
  replenishmentId: number;

  relatedLocations = [];

  relatedLocationsToShow: Replenishment[] = [];

  private destroy$: Subject<void> = new Subject();

  constructor(
    public modal: BsModalRef,
    public toastrService: ToastrService,
    public modalService: BsModalService,
    private replenishmentApiService: ReplenishmentApiService,
    public router: Router,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public reserveOne(): void {
    this.replenishmentApiService
      .postReserveReplenishment([this.replenishmentId])
      .pipe(
        tap(() => {
          this.showSuccessAlertAndClose();
          const currentUrl = this.router.url;
          this.reloadComponent();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public reserveRelated(): void {
    const replenishments = this.relatedLocations.map(location => location.id);
    this.replenishmentApiService
      .postReserveReplenishment(replenishments)
      .pipe(
        tap(() => {
          this.showSuccessAlertAndClose();
          this.reloadComponent();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private showSuccessAlertAndClose(): void {
    this.toastrService.success('Product has been reserved');
    this.modalService.setDismissReason('product_reserved');
    this.modal.hide();
  }

  // @TODO: move to service / refresh after click 'reserved'
  reloadComponent() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
}
