import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() public page = 0;
  @Input() public totalItems = 0;
  @Input() public itemsPerPage = 0;

  @Output() public pageChange: EventEmitter<number> = new EventEmitter();
  @Output() public change: EventEmitter<any> = new EventEmitter();

  public totalPages = 0;

  ngOnChanges(data) {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  public inputChanged(event): void {
    event.stopPropagation();

    this.pageChanged();
  }

  public pageChanged(): void {
    if (isNaN(this.page)) {
      this.page = 1;
    }

    if (this.page > this.totalPages) {
      this.page = this.totalPages;
    }

    this.pageChange.emit(this.page);
    this.change.emit({ page: this.page });
  }

  public nextPage(): void {
    if (this.page === this.totalPages) {
      return;
    }

    this.page++;
    this.pageChanged();
  }

  public previousPage(): void {
    if (this.page === 1) {
      return;
    }

    this.page--;
    this.pageChanged();
  }
}
