import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-number-spinner',
  templateUrl: './number-spinner.component.html',
  styleUrls: ['./number-spinner.component.scss'],
})
export class NumberSpinnerComponent implements OnInit {
  @Input() min: number = null;

  @Input() max: number = null;

  @Input() formControlInput: FormControl = new FormControl(0, Validators.required);

  @Input() defaultValue = 0;

  @Input() showReset = false;

  @Input() showSetAll = false;

  @Input() resetText = 'Reset';

  @Input() showSetAllText = 'All';

  @Input() showSetAllCount: number = null;

  @Output() onChange = new EventEmitter();

  count = new FormControl(0, Validators.required);

  ngOnInit(): void {
    if (this.formControlInput) this.count = this.formControlInput;
    if (
      (this.min && this.max && this.defaultValue >= this.min && this.max >= this.defaultValue) ||
      (this.min && this.defaultValue >= this.min) ||
      (this.max && this.max >= this.defaultValue)
    ) {
      this.count.patchValue(this.defaultValue);
    } else if (this.min) {
      this.count.patchValue(this.min);
    }
  }

  increment() {
    if (this.max && this.count.value < this.max) {
      this.incrementAction();
    } else if (!this.max) {
      this.incrementAction();
    }
  }

  incrementAction() {
    this.count.patchValue(parseInt(String(this.count.value), 10) + 1);
    this.onChangeAction();
  }

  decrement() {
    if (this.min && this.count.value > this.min) {
      this.decrementAction();
    } else if (!this.min) {
      this.decrementAction();
    }
  }

  decrementAction() {
    this.count.patchValue(parseInt(String(this.count.value), 10) - 1);
    this.onChangeAction();
  }

  manualChange(count: string | number) {
    const parseCount = typeof count === 'string' ? parseInt(count, 10) : count;
    if (this.max && parseCount > this.max) {
      this.count.patchValue(this.max);
    } else if (this.min && parseCount < this.min) {
      this.count.patchValue(this.min);
    } else {
      this.count.patchValue(parseCount);
    }
    if (isNaN(this.count.value)) this.count.patchValue(this.min);
    this.onChangeAction();
  }

  resetAction() {
    this.count.patchValue(0);
    this.onChangeAction();
  }

  onChangeAction() {
    this.onChange.emit(this.count.value);
  }

  onSetAllAction() {
    this.manualChange(this.showSetAllCount.toString());
  }
}
