import { ScanSubject } from '../type/ScanSubject';

export class ScanInputAction {
  public static SCANNED_SUBJECT_PRODUCT: ScanSubject = 'product';

  public static SCANNED_SUBJECT_SKU: ScanSubject = 'sku';

  public static SCANNED_SUBJECT: ScanSubject = 'other';

  public subject: ScanSubject;

  public action: (code: string) => void;

  constructor(subject: ScanSubject, action: (code: string) => void) {
    this.subject = subject;
    this.action = action;
  }
}
