import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReplenishmentLevel } from '../../settings/models/replenishment-level';
import { ReplenishmentApiService } from '../../replenishment/services/replenishment-api.service';
import { List } from '../../core/models/list';

@Injectable({
  providedIn: 'root',
})
export class SelectService {
  constructor(private replenishmentApiService: ReplenishmentApiService) {}

  getReplenishmentLevels(): Observable<List<ReplenishmentLevel>> {
    return this.replenishmentApiService.getReplenishmentLevels();
  }
}
