import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ItemFromCsv } from '../../../delivery/models/item-from-csv';
import { Manufacturer } from '../../../delivery/models/manufacturer.model';
import { TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { PimApiService } from '../../../PIM/services/pim-api.service';
import { Filters } from '../../../core/services/list/models/Filters';
import { Order } from '../../../core/services/list/models/Order';
import { SortDirection } from '../../../core/services/list/models/sort-direction.enum';
import { Paginator } from '../../../core/services/list/models/Paginator';
import { ManufacturerService } from '../../../core/services/manufacturer.service';

@Component({
  selector: 'app-create-prodcut-modal',
  templateUrl: './create-prodcut-modal.component.html',
  styleUrls: ['./create-prodcut-modal.component.scss'],
})
@TakeUntilDestroy
export class CreateProdcutModalComponent implements OnInit, OnDestroy {
  productForm: FormGroup = this.fb.group({
    ean: ['', [Validators.required]],
    sku: ['', [Validators.required]],
    manufacturer: ['', [Validators.required]],
    name: ['', Validators.required],
  });

  manufacturers: Manufacturer[];

  componentDestroy: () => Observable<void>;

  constructor(
    public dialogRef: MatDialogRef<CreateProdcutModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ItemFromCsv,
    private fb: FormBuilder,
    private manufacturerService: ManufacturerService,
    private toastrService: ToastrService,
    private pimApiService: PimApiService,
  ) {}

  ngOnInit(): void {
    this.getManufacturers();
    const { ean, sku } = this.data;
    this.productForm.patchValue({
      ean,
      sku,
    });
  }

  ngOnDestroy() {}

  getManufacturers() {
    return this.manufacturerService
      .getManufacturers(new Filters([], new Order(['name', SortDirection.ASC], []), new Paginator(1, 9999)))
      .pipe(
        tap(response => (this.manufacturers = response.items)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  createProduct() {
    if (this.productForm.valid) {
      this.pimApiService
        .createProduct(this.productForm.value)
        .pipe(
          tap(() => {
            this.toastrService.success('Success!');
            this.dialogRef.close(1);
          }),
          takeUntil(this.componentDestroy()),
        )
        .subscribe();
    } else {
      this.toastrService.error('Complete the form correctly!');
    }
  }

  get ean() {
    return this.productForm.get('ean');
  }

  get sku() {
    return this.productForm.get('sku');
  }

  get name() {
    return this.productForm.get('name');
  }

  get manufacturer() {
    return this.productForm.get('manufacturer');
  }
}
