import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IdleTime {
  timeout: number;

  onTimeout: Function;

  eventHandler: EventListener;

  interval: ReturnType<typeof setInterval>;

  timeoutTracker: ReturnType<typeof setInterval>;

  constructor({ timeout, onTimeout }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval(): void {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem('expiredTime'), 10);
      if (expiredTime < Date.now() && this.onTimeout) {
        this.onTimeout();
        this.cleanUp();
      }
    }, 10000);
  }

  updateExpiredTime(): void {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem('expiredTime', (Date.now() + this.timeout * 1000).toString());
    }, 1000);
  }

  tracker(): void {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('mouseup', this.eventHandler);
    window.addEventListener('mousedown', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
    window.addEventListener('keyup', this.eventHandler);
    window.addEventListener('touchstart', this.eventHandler);
    window.addEventListener('touchend', this.eventHandler);
    window.addEventListener('touchmove', this.eventHandler);
  }

  cleanUp(): void {
    clearInterval(this.interval);
    localStorage.removeItem('expiredTime');
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('mouseup', this.eventHandler);
    window.removeEventListener('mousedown', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
    window.removeEventListener('keyup', this.eventHandler);
    window.removeEventListener('touchstart', this.eventHandler);
    window.removeEventListener('touchend', this.eventHandler);
    window.removeEventListener('touchmove', this.eventHandler);
  }
}
