export enum ShippingMethodEnum {
  PALETS = 'palets',
  INNOSHIP_POST = 'innoship.post',
  INNOSHIP_UPS = 'innoship.ups',
  INNOSHIP_DHL = 'innoship.dhl',
  INNOSHIP_DHL_STANDARD = 'innoship.dhl.standard',
  INNOSHIP_DHL_PLUS = 'innoship.dhl.plus',
  INNOSHIP_DPD = 'innoship.dpd',
  INNOSHIP_DPD_24 = 'innoship.dpd.24',
  INNOSHIP_DPD_48 = 'innoship.dpd.48',
  INNOSHIP_DPD_12NOON = 'innoship.dpd.12noon',
  INNOSHIP_DPD_10EXPRESS = 'innoship.dpd.10express',
  INNOSHIP_DPD_SATURDAY = 'innoship.dpd.saturday',
  INNOSHIP_GLS = 'innoship.gls',
  INNOSHIP_FEDEX = 'innoship.fedex',
  INNOSHIP_INPOST = 'innoship.inpost',
  INNOSHIP_ROYALMAIL = 'innoship.royalmail',
  INNOSHIP_ROYALMAIL_24 = 'innoship.royalmail.24',
  INNOSHIP_ROYALMAIL_48 = 'innoship.royalmail.48',
  INNOSHIP_ROYALMAIL_BFPO = 'innoship.royalmail.bfpo',
}
