import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { SkuValidatorService } from '../../barcodeInput/skuValidator.service';

export const SKU_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SKUValidator),
  multi: true,
};

@Directive({
  selector: '[sku][formControlName],[sku][formControl],[sku][ngModel]',
  providers: [SKU_VALIDATOR],
})
export class SKUValidator implements Validator {
  constructor(private skuValidatorService: SkuValidatorService) {}

  validate(c: AbstractControl): ValidationErrors | null {
    if (!c.value) {
      return null;
    }

    return this.skuValidatorService.validate(c.value) ? null : ['eanInvalid'];
  }

  registerOnValidatorChange(fn: () => void): void {}
}
