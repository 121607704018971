import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Paginator } from '../../../../core/services/list/models/Paginator';
import { FiltersService } from '../../services/filters.service';
import { BehaviorFilters } from '../../models/BehaviorFilters.model';
import { TakeUntilDestroy } from '../../../../core/decorators/take-until-destroy';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
@TakeUntilDestroy
export class PaginatorComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(MatPaginator) paginator: QueryList<MatPaginator>;

  @Input() class = '';

  @Input() showSort = true;

  @Input() showItemsPerPage = true;

  @Input() hideRangeLength = false;

  private componentDestroy: () => Observable<void>;

  filters$: Observable<BehaviorFilters>;

  constructor(private filtersService: FiltersService) {}

  ngOnInit(): void {
    this.filters$ = this.filtersService.filtersObservable;
  }

  ngAfterViewInit() {
    this.filtersService.paginator = this.paginator.first;
  }

  ngOnDestroy() {}

  changePage(pageEvent: PageEvent, paginator: Paginator): void {
    const { pageIndex, pageSize } = pageEvent;
    paginator.setOptions(pageIndex + 1, pageSize);
    this.filtersService.runFilters(true, false).pipe(takeUntil(this.componentDestroy())).subscribe();
  }

  changeSort(): void {
    if (this.filtersService.filters) {
      this.filtersService.filters.paginator.page = 1;
    }
    this.filtersService.runFilters(true, true).pipe(takeUntil(this.componentDestroy())).subscribe();
  }
}
