import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiRoutes } from 'src/environments/api-routes';
import { Filters as MikuFilters } from 'src/app/core/services/list/models/Filters';
import { List } from 'src/app/core/models/list';
import { tap } from 'rxjs/operators';
import { UploadedItem } from '../models/uploadedItems.model';
import { ItemExpected } from '../models/item-expected';
import { Delivery } from '../models/delivery.model';
import { ItemFromCsv } from '../models/item-from-csv';
import { EditDeliveryItem } from '../models/add-expected-item.payload';
import { ImportDeliveryItem } from '../models/import-delivery-item';
import { NewDeliveries } from '../models/newDelivery.model';
import { DeliveryPayload } from '../models/delivery-payload.model';
import { DocumentItem } from '../models/document-item.model';
import { ExportService } from '../../utils/export/export.service';
import { Document } from '../models/document.model';
import { DeliveryItemToAcceptanceInterface } from '../models/delivery-item-to-acceptance.interface';

@Injectable({
  providedIn: 'root',
})
export class DeliveryApiService {
  private apiRoutes = apiRoutes;

  constructor(private apiService: ApiService, private http: HttpClient, private exportService: ExportService) {}

  getDeliveries(filters: MikuFilters): Observable<List<NewDeliveries>> {
    return this.http.get<List<NewDeliveries>>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.delivery.root, filters),
    );
  }

  getDelivery(id: string): Observable<Delivery> {
    return this.http.get<Delivery>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.delivery.idParam).replace('{id}', id),
    );
  }

  getDeliveryItems(isDone: boolean, deliveryId: number, filters: MikuFilters): Observable<List<ItemExpected>> {
    const params = new HttpParams().set('done', isDone.toString());
    return this.http.get<List<ItemExpected>>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.delivery.expectedItems.replace('{deliveryId}', deliveryId.toString()),
        filters,
        { perPage: 99999 },
      ),
      { params },
    );
  }

  getAllDeliveryItems(deliveryId: number, filters: MikuFilters): Observable<List<ItemExpected>> {
    return this.http.get<List<ItemExpected>>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.delivery.expectedItems.replace('{deliveryId}', deliveryId.toString()),
        filters,
        { perPage: 99999 },
      ),
    );
  }

  removeDeliveryExpectedItemTest(deliveryId: number, expectedItemId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService
        .getMikuUrl(this.apiRoutes.inventory.deliveryExpectedItem.expectedItemId)
        .replace('{deliveryId}', deliveryId.toString())
        .replace('{expectedItemId}', expectedItemId.toString()),
    );
  }

  addEmployeeToDelivery(deliveryId: number, employee: number): Observable<void> {
    return this.http.post<void>(
      this.apiService
        .getMikuUrl(this.apiRoutes.inventory.delivery.employee)
        .replace('{deliveryId}', deliveryId.toString()),
      { employee },
    );
  }

  createDelivery(payload: Delivery | DeliveryPayload): Observable<Delivery> {
    return this.http.post<Delivery>(this.apiService.getMikuUrl(this.apiRoutes.inventory.delivery.root), payload);
  }

  deleteDelivery(deliveryId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.delivery.idParam).replace('{id}', deliveryId.toString()),
    );
  }

  acceptDelivery(deliveryId: number, forceClose: boolean = false): Observable<void> {
    return this.http.post<void>(
      this.apiService
        .getMikuUrl(this.apiRoutes.inventory.deliveryAcceptance.idParam)
        .replace('{deliveryId}', deliveryId.toString()),
      { forceClose },
    );
  }

  parseCsv(formData: FormData): Observable<ItemFromCsv[]> {
    return this.http.post<ItemFromCsv[]>(
      this.apiService.getMikuUrl(this.apiRoutes.inventory.deliveryExpectedItem.parseCsv),
      formData,
      { headers: this.apiService.getFormDataHeaders() },
    );
  }

  addItemExpected(deliveryId: number, data: DeliveryItemToAcceptanceInterface): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.deliveryExpectedItem.addDeliveryItem.replace('{deliveryId}', deliveryId.toString()),
      ),
      data,
    );
  }

  editVariant(payload: EditDeliveryItem, deliveryId: number, itemId: number): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.deliveryExpectedItem.editExpectedItem
          .replace('{deliveryId}', deliveryId.toString())
          .replace('{itemId}', itemId.toString()),
      ),
      payload,
    );
  }

  addExpectedItemToDelivery(deliveryId: number, data: UploadedItem) {
    return this.http.post(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.deliveryExpectedItem.expectedItem.replace('{deliveryId}', deliveryId.toString()),
      ),
      data,
    );
  }

  importDeliveryExpectedItems(data: ImportDeliveryItem): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.deliveryExpectedItem.import.replace('{deliveryId}', data.deliveryId.toString()),
      ),
      data,
    );
  }

  editExpecteditemInDelivery(expectedItemId: number, data, deliveryId: number): Observable<ItemExpected> {
    return this.http.put<ItemExpected>(
      this.apiService
        .getMikuUrl(this.apiRoutes.inventory.deliveryExpectedItem.expectedItemId)
        .replace('{deliveryId}', deliveryId.toString())
        .replace('{expectedItemId}', expectedItemId.toString()),

      data,
    );
  }

  getDeliveryDocuments(deliveryId: number): Observable<List<DocumentItem>> {
    return this.http.get<List<DocumentItem>>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.delivery.document.root.replace('{deliveryId}', deliveryId?.toString()),
      ),
    );
  }

  uploadDocument(deliveryId: number, files: File[]): Observable<any> {
    const formData = new FormData();
    files.forEach(file => formData.append('uploadFiles[]', file, file.name));

    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.delivery.document.root.replace('{deliveryId}', deliveryId.toString()),
      ),
      formData,
    );
  }

  removeDeliveryDocument(deliveryId: number, documentId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.inventory.delivery.document.delete
          .replace('{deliveryId}', deliveryId.toString())
          .replace('{documentId}', documentId.toString()),
      ),
    );
  }

  downloadDocument(deliveryId: number, documentId: number, document: Document): Observable<File> {
    return this.exportService
      .getFile(
        this.apiRoutes.inventory.delivery.document.download
          .replace('{deliveryId}', deliveryId.toString())
          .replace('{documentId}', documentId.toString()),
      )
      .pipe(
        tap(file => {
          const { name, file: fileName } = document;
          this.exportService.saveFile(name, file, fileName.split('.')[1]);
        }),
      );
  }
}
