import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { apiRoutes } from '../../../environments/api-routes';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusApiService {
  apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  postDispatched(orders: number[]): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.sales.order.status.postDispatched), {
      orders,
    });
  }
}
