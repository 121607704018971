import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../core/api.service';
import { apiRoutes } from '../../../environments/api-routes';
import { takeUntil, tap } from 'rxjs/operators';
import { ExportService } from '../export/export.service';
import { TakeUntilDestroy } from '../../core/decorators/take-until-destroy';

@Injectable({
  providedIn: 'root',
})
@TakeUntilDestroy
export class ImportLocationFromCsvService implements OnDestroy {
  private serviceDestroyed = new Subject<void>();

  apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService, private exportService: ExportService) {}

  ngOnDestroy() {}

  importLocationsFromCsv(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.storage.location.importFromCsv), formData);
  }

  getLocationCSVTemplate(): Observable<File> {
    return this.exportService.getFiles(this.apiRoutes.storage.location.importSampleCsv).pipe(
      tap(csv => this.exportService.saveFile(`LocationCSVTemplate`, csv, 'csv')),
      takeUntil(this.serviceDestroyed),
    );
  }
}
