import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  public message: string;
  public isVisible = false;
  public response: Subject<boolean> = new Subject<boolean>();
  private showOnlyOk: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public show(message: string): Observable<boolean> {
    return new Observable(observer => {
      this.response = new Subject<boolean>();
      this.message = message;
      this.isVisible = true;

      this.response.subscribe(value => {
        this.isVisible = false;
        return observer.next(value);
      });
    });
  }

  getShowOnlyOk$(): Observable<boolean> {
    return this.showOnlyOk.asObservable();
  }

  setShowOnlyOk(v: boolean): void {
    this.showOnlyOk.next(v);
  }

  public getMessage() {
    return this.message;
  }

  public getIsVisible() {
    return this.isVisible;
  }

  public accept() {
    this.response.next(true);
    this.setShowOnlyOk(false);
  }

  public decline() {
    this.response.next(false);
    this.setShowOnlyOk(false);
  }
}
