import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/api.service';
import { Filters } from 'src/app/core/models/filters';
import { List } from 'src/app/core/models/list';
import { apiRoutes } from 'src/environments/api-routes';
import { CollectMethods } from '../models/collect-methods';
import { CollectSingleVariant } from '../models/collect-single-variant';
import { CollectingItemVariant } from '../models/collecting-item-variant';
import { CollectItem } from '../models/collectItem.model';
import { CollectOrder } from '../models/collectOrder.model';
import { ScanOrder } from '../models/scan-order.model';
import { Sessions } from '../models/sessions.model';
import { CollectResponse } from '../models/collect-response.model';
import { Filters as MikuFilters } from 'src/app/core/services/list/models/Filters';

@Injectable({
  providedIn: 'root',
})
export class CollectApiService {
  private apiRoutes = apiRoutes;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  getActiveCollectingItems(): Observable<List<CollectOrder>> {
    return this.http.get<List<CollectOrder>>(this.apiService.getMikuUrl(this.apiRoutes.collecting.root));
  }

  deleteOrder(id: number): Observable<List<CollectOrder>> {
    return this.http.delete<List<CollectOrder>>(
      this.apiService.getUrl(this.apiRoutes.collecting.orderId.replace('{id}', id.toString())),
    );
  }

  getSessionOrders(): Observable<List<CollectOrder>> {
    return this.http.get<List<CollectOrder>>(this.apiService.getMikuUrl(this.apiRoutes.collecting.root));
  }

  startCollecting(orders): Observable<any> {
    return this.http.post<List<CollectOrder>>(
      this.apiService.getMikuUrl(this.apiRoutes.collecting.root, null, { orders }),
      {},
    );
  }

  getBeingCollectedItems(preloader = true, sessionId: string): Observable<List<CollectItem>> {
    return this.http.get<List<CollectItem>>(
      this.apiService.getMikuUrl(this.apiRoutes.collecting.items.getItemsList).replace('{sessionId}', sessionId),
      {
        params: { preloader },
      },
    );
  }

  getOrderByExternalId(barcode: string): Observable<ScanOrder> {
    return this.http.get<ScanOrder>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.scanOrder.replace('{barcode}', barcode)),
    );
  }

  getSessions(): Observable<List<Sessions>> {
    return this.http.get<List<Sessions>>(
      this.apiService.getMikuUrl(this.apiRoutes.collecting.sessions.getSessions, null),
    );
  }

  collectItem(payload, sessionId: string): Observable<List<CollectResponse>> {
    return this.http.post<List<CollectResponse>>(
      this.apiService.getMikuUrl(this.apiRoutes.collecting.items.collect.replace('{sessionId}', sessionId)),
      payload,
    );
  }

  reportInventoryError(payload): Observable<any> {
    return this.http.post(this.apiService.getMikuUrl(this.apiRoutes.inventory.error), payload);
  }

  leaveCollecting(sessionId: string): Observable<any> {
    return this.http.put(
      this.apiService.getMikuUrl(this.apiRoutes.collecting.leave.replace('{sessionId}', sessionId)),
      {},
    );
  }

  joinToSession(sessionId: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.collecting.sessions.joinToSession.replace('{id}', sessionId.toString()),
      ),
      {},
    );
  }

  cancelJoinToSession(sessionId: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.collecting.sessions.cancelJoinToSession.replace('{id}', sessionId.toString()),
      ),
      {},
    );
  }

  approveJoinToSession(sessionId: number, employeeId: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.collecting.sessions.approveJoinToSession
          .replace('{sessionId}', sessionId.toString())
          .replace('{employeeId}', employeeId.toString()),
      ),
      {},
    );
  }

  rejectJoinToSession(sessionId: number, employeeId: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.collecting.sessions.rejectJoinToSession
          .replace('{sessionId}', sessionId.toString())
          .replace('{employeeId}', employeeId.toString()),
      ),
      {},
    );
  }

  pauseSession(sessionId: string): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(this.apiRoutes.collecting.sessions.pause.replace('{sessionId}', sessionId)),
      {},
    );
  }

  cancelSession(sessionId: string): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(this.apiRoutes.collecting.sessions.cancel.replace('{sessionId}', sessionId)),
      {},
    );
  }
}
