import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/api.service';
import { Filters } from 'src/app/core/models/filters';
import { Filters as MikuFilters } from 'src/app/core/services/list/models/Filters';
import { MikuGenericObject } from '../models/miku-generic-object';

export type Extension = 'xls' | 'pdf' | 'csv' | 'png' | 'jpg' | 'jpeg';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  public constructor(private http: HttpClient, private apiService: ApiService) {}

  static resolveFileType(format: string): string {
    switch (format) {
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'pdf':
        return 'application/pdf';
      case 'csv':
        return 'text/csv';
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      default:
        return 'text/csv';
    }
  }

  mikuExport(
    filters: MikuFilters,
    format: Extension,
    path: string,
    genericFilters: MikuGenericObject<any> = null,
    errorNotification = true,
  ): Observable<File> {
    return this.http.get<File>(this.apiService.getMikuUrl(path, filters, { format, ...genericFilters }), {
      responseType: 'blob' as 'json',
      params: { errorNotification },
    });
  }

  /**
   * @deprecated use getFiles
   */
  getFile(path: string, filters?: Filters): Observable<File> {
    return this.http.get<File>(this.apiService.getUrl(path, filters), { responseType: 'blob' as 'json' });
  }

  getFiles(path: string, filters?: MikuFilters, genericParams?: MikuGenericObject<any>): Observable<File> {
    return this.http.get<File>(this.apiService.getMikuUrl(path, filters, genericParams), {
      responseType: 'blob' as 'json',
    });
  }

  saveFile(prefix: string, file: File, format: Extension | string, name: string = null) {
    const date = new Date();
    saveAs(
      new Blob([file], { type: ExportService.resolveFileType(format) }),
      `${prefix}${name ?? date.toISOString()}.${format}`,
    );
  }
}
