import { Pipe, PipeTransform } from '@angular/core';
import convertWeight from '../helpers/convert-weight';
import { WeightUnitsEnum } from '../enums/weight-units.enum';

@Pipe({
  name: 'convertWeight',
})
export class ConvertWeightPipe implements PipeTransform {
  transform(value: number | string, fromUnit = WeightUnitsEnum.G, toUnit = WeightUnitsEnum.KG): string | number {
    let result = value;
    if (value) {
      result = convertWeight(value, fromUnit, toUnit).toFixed(3);
    }
    return result === 'NaN' || !result ? '-' : result;
  }
}
