import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ColumnInterface } from '../list/model/column.interface';
import { SortableColumn } from '../list/model/sortableColumn.model';
import { MassactionColumn } from '../list/model/massactionColumn.model';
import { EmptyColumn } from '../list/model/emptyColumn.model';
import { HeadingColumn } from '../list/model/headingColumn.model';

@Component({
  selector: '[sortableTr]',
  templateUrl: 'sortableTr.component.html',
  styleUrls: ['sortable.scss'],
})
export class SortableTrComponent {
  @Input() public sortBy: string;
  @Input() public sortDir: any;
  @Input() public columns: ColumnInterface[];

  @Output() public sortableTr: EventEmitter<any> = new EventEmitter();
  @Output() public sortByChange = new EventEmitter();
  @Output() public sortDirChange = new EventEmitter();

  public showTableSortSelect = false;

  constructor() {
    if (window.innerWidth < 768) {
      this.showTableSortSelect = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    if (window.innerWidth < 768) {
      this.showTableSortSelect = true;
    } else {
      this.showTableSortSelect = false;
    }
  }

  public columnClicked(column: SortableColumn): void {
    if (column.sortBy.length === 0) {
      return;
    }

    if (this.sortBy !== column.sortBy) {
      this.sortBy = column.sortBy;
      this.sortByChange.emit(this.sortBy);
    } else {
      this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      this.sortDirChange.emit(this.sortDir);
    }

    this.sortableTr.emit({ sortBy: this.sortBy, sortDir: this.sortDir });
  }

  public changeSortColumn(): void {
    this.sortByChange.emit(this.sortBy);
    this.sortableTr.emit({ sortBy: this.sortBy, sortDir: this.sortDir });
  }

  public changeSortOrder(): void {
    this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
    this.sortDirChange.emit(this.sortDir);
    this.sortableTr.emit({ sortBy: this.sortBy, sortDir: this.sortDir });
  }

  public isSortableColumn(column: ColumnInterface): boolean {
    return column instanceof SortableColumn;
  }

  public isMassactionColumn(column: ColumnInterface): boolean {
    return column instanceof MassactionColumn;
  }

  public isEmptyColumn(column: ColumnInterface): boolean {
    return column instanceof EmptyColumn;
  }

  public isHeadingColumn(column: ColumnInterface): boolean {
    return column instanceof HeadingColumn;
  }
}
