export class RelationList {
  private add: number[];

  private remove: number[];

  setAdd(add: number[]): void {
    this.add = add;
  }

  setDelete(remove: number[]): void {
    this.remove = remove;
  }

  getAdd(): number[] {
    return this.add;
  }

  getRemove(): number[] {
    return this.remove;
  }
}
