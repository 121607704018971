import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss'],
})
export class VersionInfoComponent {
  @Input() version;

  show = false;

  showVersion() {
    this.show = !this.show;
  }
}
