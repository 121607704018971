import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.component.html',
})
export class SelectModalComponent implements OnInit {
  @Output() confirm = new EventEmitter();

  @Output() cancel = new EventEmitter();

  series: unknown;

  constructor(
    private dialogRef: MatDialogRef<SelectModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { items: unknown[] & { selectField: string }; confirmText: string; rejectText: string },
  ) {}

  ngOnInit(): void {
    this.series = this.data.items[0];
  }

  onConfirm() {
    this.confirm.emit(this.series);
    this.dialogRef.close();
  }

  onReject() {
    this.cancel.emit();
    this.dialogRef.close();
  }
}
