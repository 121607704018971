import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CsvFile } from '../../delivery/models/csvFile.model';
import { ConfirmService } from '../confirm/confirm.service';
import { ExportService } from '../export/export.service';
import { StringHelpersService } from '../../core/helpers/string-helpers.service';
import { DeliveryApiService } from '../../delivery/services/delivery-api.service';
import { EanExistCheckValidator } from '../../core/validators/ean-exist-check.validator';
import { SkuExistCheckValidator } from '../../core/validators/sku-exist-check.validator';
import { ItemFromCsv } from '../../delivery/models/item-from-csv';
import { UploadedFile } from '../upload-button/uploaded-file';
import { apiRoutes } from '../../../environments/api-routes';
import { CreateProdcutModalComponent } from '../modals/create-prodcut-modal/create-prodcut-modal.component';
import { ExistEanOrSkuValidator } from '../../core/validators/exist-ean-or-sku.validator';

@Component({
  selector: 'app-import-products-from-csv',
  templateUrl: './import-products-from-csv.component.html',
})
export class ImportProductsFromCsvComponent {
  @Input() approveBtn = 'Save';

  @Input() showSample = true;

  @Output() approve = new EventEmitter();

  @Output() saveChange = new EventEmitter();

  @Output() uploadCSV = new EventEmitter();

  csvFile: CsvFile = new CsvFile();

  private destroy$: Subject<void> = new Subject();

  private apiRoutes = apiRoutes;

  constructor(
    public confirmService: ConfirmService,
    public exportService: ExportService,
    private stringHelpersService: StringHelpersService,
    private deliveryApiService: DeliveryApiService,
    private fb: FormBuilder,
    private eanExistCheckValidator: EanExistCheckValidator,
    private skuExistCheckValidator: SkuExistCheckValidator,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {}

  form = this.fb.group({
    items: this.fb.array([]),
  });

  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  addItem(item: ItemFromCsv, index: number): void {
    this.items.push(this.buildItem(item, index));
  }

  buildItem({ ean, sku, qty, purchasePrice, comment }: ItemFromCsv, index: number): FormGroup {
    return this.fb.group(
      {
        ean: [
          ean,
          {
            asyncValidators: [this.eanExistCheckValidator.validate.bind(this.eanExistCheckValidator)],
            updateOn: 'blur',
          },
        ],
        sku: [
          sku,
          {
            asyncValidators: [this.skuExistCheckValidator.validate.bind(this.skuExistCheckValidator)],
            updateOn: 'blur',
          },
        ],
        qty: [qty],
        purchasePrice: [purchasePrice],
        comment: [comment],
        id: [index],
      },
      { validator: ExistEanOrSkuValidator },
    );
  }

  onFileChanges([{ file, blobUrl }]: UploadedFile[]): void {
    this.csvFile.fileType = file.type;
    this.csvFile.fileName = file.name;
    this.csvFile.fileData = this.stringHelpersService.trimMimeType(blobUrl);
    this.uploadCsv();
  }

  uploadCsv(): void {
    const formData: FormData = new FormData();
    formData.append('fileData', this.csvFile.fileData);
    this.deliveryApiService
      .parseCsv(formData)
      .pipe(
        tap(items => {
          items.forEach((item, index) => this.addItem(item, index));
          this.uploadCSV.emit(true);
        }),
      )
      .subscribe();
  }

  downloadSampleCsv(): void {
    const filePrefix = `sample-delivery`;
    this.exportService
      .getFile(this.apiRoutes.inventory.delivery.csvSample)
      .pipe(
        tap(response => {
          this.exportService.saveFile(filePrefix, response, 'csv');
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  createProduct(item: ItemFromCsv) {
    const dialogRef = this.dialog.open(CreateProdcutModalComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: item,
    });

    dialogRef
      .afterClosed()
      .pipe(
        tap(() =>
          this.items.controls.forEach(control => {
            control.patchValue(control.value);
            control.markAsTouched();
            control.updateValueAndValidity();
          }),
        ),
      )
      .subscribe();
  }

  removeItem({ id }: ItemFromCsv) {
    this.items.removeAt(this.items.value.findIndex(item => item.id === id));
  }

  onApprove() {
    if (this.form.valid) {
      this.approve.emit(this.form);
    } else {
      this.toastr.error('Not all fields are completed correctly!');
    }
  }
}
