import { Pipe, PipeTransform } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Pipe({
  name: 'textWrap',
})
export class TextWrapPipe implements PipeTransform {
  constructor(private breakpointObserver: BreakpointObserver) {}

  transform(value: string, replaceString: string, charCount: number = 11): string {
    if (this.breakpointObserver.isMatched('(max-width: 420px)') && value.length - charCount > replaceString.length) {
      return `${value.slice(0, charCount - 1)}${replaceString}`;
    }

    return value;
  }
}
