import { Observable, Subject } from 'rxjs';

export class DeleteDialogData {
  deleteObservable: Observable<void>;

  entityName: string;

  refreshSubject: Subject<void>;

  constructor(deleteObservable: Observable<void>, entityName: string, refreshSubject: Subject<void>) {
    this.deleteObservable = deleteObservable;
    this.entityName = entityName;
    this.refreshSubject = refreshSubject;
  }
}
