import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { versionInfo } from '../version-info';
import { v4 as uuidv4 } from 'uuid';
import { hmrBootstrap } from '../hmr';

const user = JSON.parse(localStorage.getItem('loggedUser'));

Sentry.init({
  dsn: environment.sentryDsn,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: environment.envName,
  release: versionInfo?.tag,
  defaultIntegrations: false,
  beforeSend(event) {
    const errorUserFeedback = JSON.parse(localStorage.getItem('errorUserFeedback'));

    if (user && event.exception && parseInt(errorUserFeedback, 10)) {
      const { firstname, lastname, email } = user;
      Sentry.showReportDialog({
        eventId: event.event_id,
        user: { name: `${firstname} ${lastname}`, email },
      });
    }
    return event;
  },
});

let deviceId = JSON.parse(localStorage.getItem('deviceId'));

if (!deviceId) {
  deviceId = uuidv4();
  localStorage.setItem('deviceId', JSON.stringify(deviceId));
}

Sentry.setTag('deviceId', deviceId);

if (!user) {
  Sentry.setUser({ ip_address: '{{auto}}' });
} else {
  const { email, id, username } = user;
  Sentry.setUser({ ip_address: '{{auto}}', id, username, email });
}

if (environment.production) {
  console.info('Production mode');
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (environment.hmr) {
  hmrBootstrap(module, bootstrap);
} else {
  bootstrap().catch(err => console.log(err));
}
