import { FormGroup, ValidatorFn } from '@angular/forms';

export const ExistEanOrSkuValidator: ValidatorFn = (fg: FormGroup) => {
  const ean = fg.get('ean').value;
  const sku = fg.get('sku').value;

  if (ean === '' && sku !== '') {
    fg.get('ean').setErrors(null);
    fg.get('ean').markAsUntouched();
  }

  if (ean !== '' && sku === '') {
    fg.get('sku').setErrors(null);
    fg.get('sku').markAsUntouched();
  }

  if (ean === '' && sku === '') {
    fg.get('ean').setErrors({ existEanOrSku: true });
    fg.get('ean').markAsTouched();
    fg.get('sku').setErrors({ existEanOrSku: true });
    fg.get('sku').markAsTouched();
    return { existEanOrSku: true };
  }

  return null;
};
