import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-calendar-datepicker-range',
  templateUrl: './calendar-datepicker-range.component.html',
  styleUrls: ['./calendar-datepicker-range.component.scss'],
})
export class CalendarDatepickerRangeComponent {
  @Input() fromControl: FormControl | AbstractControl;

  @Input() toControl: FormControl | AbstractControl;

  @Input() label: string;
}
