import { Injectable } from '@angular/core';
import { PreloadService } from '../preload/preload.service';
import { generateBarcode, createDocument } from './barcode.helper';

@Injectable({
  providedIn: 'root',
})
export class BarcodeService {
  private filename: string = null;

  constructor(private preloadService: PreloadService) {}

  init(rootElement: HTMLImageElement, value: string) {
    if (!rootElement) throw new Error('Barcode root element is required!');
    if (!value) throw new Error('Barcode value is required!');

    this.filename = value;

    generateBarcode(rootElement, value);
  }

  async generateDocument(value, filename = this.filename, forPrint = false) {
    const htmlCanvas = document.createElement('canvas');
    // @ts-ignore
    const canvas = htmlCanvas.transferControlToOffscreen();

    if (!filename) throw new Error('Barcode filename is required!');
    if (!value) throw new Error('Barcode value is required!');

    this.preloadService.increment();

    await createDocument(
      {
        value,
        filename,
        canvas,
      },
      forPrint,
    );

    this.preloadService.decrement();
  }

  reset() {
    this.filename = null;
  }
}
