export class LocationType {
  public id: number = null;

  public name = 'Any';

  public numberLength: number;

  public dependentType: dependentType;

  public mustBeAssignedToParent = false;

  public cantBeAssignedToParent = false;

  public canAssignProduct = false;

  public editable = true;
}

export class dependentType {
  public id = 0;

  public name: string;

  public numberLength: number;
}
