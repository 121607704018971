export class Paginator {
  page = 1;

  perPage = 25;

  pageSizeOptions: number[];

  resultsLength = 0;

  visible = true;

  constructor(
    page: number,
    perPage: number,
    pageSizeOptions: number[] = [1, 5, 25, 50, 100],
    visible: boolean = true,
    resultsLength: number = 0,
  ) {
    this.page = page;
    this.perPage = perPage;
    this.pageSizeOptions = pageSizeOptions;
    this.visible = visible;
    this.resultsLength = resultsLength;
  }

  setOptions(page: number, perPage: number): void {
    this.page = page;
    this.perPage = perPage;
  }
}
