import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ScanInputAction } from './model/ScanInputAction';
import { BarcodeInputService } from './barcodeInput.service';
import { DocumentEventsService } from '../../core/services/document-events.service';

@Component({
  selector: 'barcodeInput',
  templateUrl: 'barcodeInput.component.html',
  styleUrls: ['barcodeInput.component.scss'],
})
export class BarcodeInputComponent implements AfterViewInit, OnInit {
  @Input() name = '';

  @Input() placeholder: string | number = '';

  @Input() withCopyBtn = false;

  @Input() disabled = false;

  @Input() error = false;

  @Input() errorText = '';

  @Output() warehouseScannedEmitter = new EventEmitter();

  @Output() skuScannedEmitter = new EventEmitter();

  @Output() productScannedEmitter = new EventEmitter();

  @ViewChild('barcodeInput') barcodeInput: ElementRef;

  public showInput = false;

  public lastScannedBarcode = '';

  isSelectionRange$: Observable<boolean>;

  constructor(
    public barcodeInputService: BarcodeInputService,
    public toastr: ToastrService,
    private documentEventsService: DocumentEventsService,
  ) {}

  ngOnInit() {
    this.isSelectionRange$ = this.documentEventsService.selectionChange$.pipe(
      map((selection: Selection) => selection?.type === 'Range'),
    );
  }

  public ngAfterViewInit() {
    this.barcodeInputService.addInput(this.name);
    setTimeout(this.barcodeInputService.focusScanInput.bind(this.barcodeInputService, this.name), 1);
  }

  public barcodeScanned(event): void {
    this.lastScannedBarcode = event.target.value.replace(/\s/g, '');
    this.lastScannedBarcode = this.lastScannedBarcode.trim();

    if (!this.lastScannedBarcode) {
      this.toastr.warning('Please enter a valid value into the scanner');
      return;
    }

    event.target.placeholder = this.lastScannedBarcode;
    event.target.value = '';

    const detectedSubject = this.barcodeInputService.detectScannedSubjectByPattern(this.lastScannedBarcode);
    const scanInputChangeAction = this.barcodeInputService.getScanInputAction(this.name, detectedSubject);

    if (typeof scanInputChangeAction === 'undefined') {
      // handle Output EventEmitter
      switch (detectedSubject) {
        case ScanInputAction.SCANNED_SUBJECT_PRODUCT:
          this.productScannedEmitter.emit(this.lastScannedBarcode);
          break;
        case ScanInputAction.SCANNED_SUBJECT_SKU:
          this.skuScannedEmitter.emit(this.lastScannedBarcode);
          break;
        case ScanInputAction.SCANNED_SUBJECT:
          this.warehouseScannedEmitter.emit(this.lastScannedBarcode);
          break;
        default:
          this.toastr.warning('Unexpected scan action, check if ean or barcode is correct.');
      }
    } else {
      // handle function binding (old way) TODO: refactor into service later
      scanInputChangeAction.action(this.lastScannedBarcode);
    }
  }

  public copyPlaceholder(): void {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.barcodeInput?.nativeElement.placeholder);
      e.preventDefault();
      document.removeEventListener('copy', listener);
      this.toastr.success('Copied');
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
  }

  public focusInput(): void {
    this.barcodeInputService.focusScanInput(this.name);
  }
}
