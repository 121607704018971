export class SubscriptionContainer {
  public subscriptions: any[] = [];

  public add(subscription: any) {
    this.subscriptions.push(subscription);
  }

  public clear() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
