import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterField } from './FilterField';
import { FieldTypesEnum } from './field-types.enum';

export class TextFilterField extends FilterField {
  valueControl = new FormControl('');

  constructor(name: string, label: string, value: string = '') {
    super(name, label, FieldTypesEnum.TEXT);
    if (value !== '') {
      this.patchValue(value);
    }
  }

  patchValue(value: string): FilterField {
    this.valueControl.patchValue(value);
    return this;
  }

  setValidators(): FilterField {
    console.warn(`setValidators not implemented ${new Error().stack.split('\n')[2].trim()}`);
    return this;
  }

  isValid(): boolean {
    return true;
  }

  clear() {
    this.patchValue('');
  }

  get fieldControl$(): Observable<FormControl> {
    return new BehaviorSubject<FormControl>(this.valueControl).asObservable();
  }
}
