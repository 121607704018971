import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UploadButtonService } from './upload-button.service';
import { UploadedFile } from './uploaded-file';

/**
 * @example `<app-upload-button buttonText="Text next to upload icon" [isMulti]="true" acceptMime="image/*"></app-upload-button>`
 */
@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UploadButtonService],
})
export class UploadButtonComponent implements OnInit, OnDestroy {
  /**
   * @type {string}
   */
  @Input() buttonText: string;

  /**
   * @type {boolean}
   * Determines if input could accept many files
   */
  @Input() isMulti: boolean;

  /**
   * @type {string}
   * Declaring input accept mimes
   * @example image/*
   */
  @Input() acceptMime: string;

  /**
   * @type {EventEmitter<unknown>}
   * Emits when file changes
   */
  @Output() fileChanges: EventEmitter<UploadedFile[]> = new EventEmitter();

  private destroy$: Subject<void> = new Subject();

  constructor(private uploadButtonService: UploadButtonService) {}

  ngOnInit(): void {
    this.uploadButtonService.onFilesRead
      .pipe(
        tap(files => this.fileChanges.emit(files)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * @param event
   * Handle file input changes
   */
  onFileChanges(event: Event): void {
    const fileList: FileList = (event.target as HTMLInputElement).files;
    const files: UploadedFile[] = [];

    for (let i = 0; i < (this.isMulti ? fileList.length : 1); i++) {
      const reader = new FileReader();
      reader.onload = (onloadEvent: any) => {
        files.push(new UploadedFile(fileList[i], onloadEvent.target.result));
        this.uploadButtonService.emitReadFiles(files);
      };
      if (fileList[i]) reader.readAsDataURL(fileList[i]);
    }
  }
}
