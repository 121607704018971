import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'barcode-range',
  templateUrl: 'barcodeRange.component.html',
})
export class BarcodeRangeComponent implements OnChanges {
  @Input() public label = '';
  @Input() public from: string = null;
  @Input() public to: string = null;

  @Output() public fromChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public toChange: EventEmitter<string> = new EventEmitter<string>();

  public numericFrom: number = -1;
  public numericTo: number = -1;

  public showError = false;
  public errorMessage = '';

  public ngOnChanges(changes: SimpleChanges) {
    if (typeof changes.from !== 'undefined') {
      this.from = changes.from.currentValue;
    }

    if (typeof changes.to !== 'undefined') {
      this.to = changes.to.currentValue;
    }
  }

  public fromChanged(): void {
    if (this.isFromEmptyString()) {
      this.from = null;
    }

    this.validateBarcodes();

    this.fromChange.emit(this.from);
  }

  public toChanged(): void {
    if (this.isToEmptyString()) {
      this.from = null;
    }

    this.validateBarcodes();

    this.toChange.emit(this.to);
  }

  public validateBarcodes(): boolean {
    const partialsFrom = this.from === null ? [] : this.from.split('-');
    const partialsTo = this.to === null ? [] : this.to.split('-');

    partialsFrom.shift();
    partialsTo.shift();

    partialsFrom.forEach(el => {
      if (isNaN(+el)) {
        this.from = null;
        this.errorMessage = 'Location numbers must be numeric';
        this.showError = true;
      }
    });

    partialsTo.forEach(el => {
      if (isNaN(+el)) {
        this.to = null;
        this.errorMessage = 'Location numbers must be numeric';
        this.showError = true;
      }
    });

    this.showError = false;

    return true;
  }

  public isFromEmptyString(): boolean {
    return typeof this.from !== 'string' || this.from === '';
  }

  public isToEmptyString(): boolean {
    return typeof this.to !== 'string' || this.from === '';
  }
}
