import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-choose-quantity-modal',
  templateUrl: './choose-quantity-modal.component.html',
})
export class ChooseQuantityModalComponent {
  @Output() onChoose = new EventEmitter();

  quantity = new FormControl(1, [Validators.required, Validators.min(1)]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; approveText: string; id: number; quantity: number; description: string },
    private dialogRef: MatDialogRef<ChooseQuantityModalComponent>,
  ) {}

  approve() {
    this.onChoose.next(this.quantity.value);
    this.dialogRef.close();
  }

  reject() {
    this.dialogRef.close();
  }
}
