import { Injectable } from '@angular/core';
import { PusherService } from './pusher.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from '../../../environments/api-routes';
import { ApiService } from '../api.service';
import { DebugInfoInterface } from '../models/debug-info.interface';
import { AuthorizationService } from '../../security/services/authorization.service';
import { LocalStorageService } from './local-storage.service';
import { v4 as uuidv4 } from 'uuid';
import { versionInfo } from '../../../../version-info';
import { BrowserFocusDetectorService } from './browser-focus-detector.service';
import { PageRefreshDetectorService } from './page-refresh-detector.service';
import { WarehouseService } from './warehouse.service';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  apiRoutes = apiRoutes;

  versionInfo = versionInfo;

  constructor(
    private pusherService: PusherService,
    private http: HttpClient,
    private apiService: ApiService,
    private authorizationService: AuthorizationService,
    private localStorageService: LocalStorageService,
    private browserFocusDetectorService: BrowserFocusDetectorService,
    private pageRefreshDetectorService: PageRefreshDetectorService,
    private warehouseService: WarehouseService,
  ) {}

  connect() {
    const broadcastChannel = this.pusherService.subscribe('private-broadcast');
    broadcastChannel?.bind('debug-device', ({ requestId }) => {
      this.sendDebugInfo(this.getUserInfo(requestId)).subscribe();
    });
  }

  getUserInfo(request: string) {
    const user = this.authorizationService.getTokenPayload();
    let device = this.localStorageService.getItem('deviceId');
    const versionEvt = this.localStorageService.getItem('versionEvt') ?? null;
    let displayMode = 'pwa';

    if (!device) {
      device = uuidv4();
      this.localStorageService.setItem('deviceId', device);
    }

    if (window.matchMedia('(display-mode: browser)').matches) {
      displayMode = 'browser';
    }

    return {
      request,
      device,
      version: this.versionInfo.tag,
      info: `
        username: ${user.username}
        id: ${user.id}
        ip: ${user.ip}
        warehouse: ${this.warehouseService.warehouseId}
        displayMode: ${displayMode}
        numberOfFocusChanges: ${this.browserFocusDetectorService.count}
        numberOfRefreshPage: ${this.pageRefreshDetectorService.count}
        currentUrl: ${window.location.href}
        versionEvt: ${versionEvt}
      `,
    } as DebugInfoInterface;
  }

  sendDebugInfo(payload: DebugInfoInterface): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.broadcast.uploadInformation), payload, {
      params: { preloader: false },
    });
  }
}
