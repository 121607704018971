import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { ImportOrderFromCsvService } from './import-order-from-csv.service';
import { Observable } from 'rxjs';
import { StoreInterface } from '../../orders/models/store.interface';
import { TakeUntilDestroy } from '../../core/decorators/take-until-destroy';
import { WarehouseService } from '../../core/services/warehouse.service';
import { ToastrService } from 'ngx-toastr';
import { apiRoutes } from '../../../environments/api-routes';

@Component({
  selector: 'app-import-order-from-csv',
  templateUrl: './import-order-from-csv.component.html',
  styleUrls: ['./import-order-from-csv.component.scss'],
})
@TakeUntilDestroy
export class ImportOrderFromCsvComponent implements OnInit, OnDestroy {
  @Output() onImport = new EventEmitter();

  @ViewChild('importInput') importInput: ElementRef;

  private componentDestroy: () => Observable<void>;

  apiRoutes = apiRoutes;

  storeList: StoreInterface[] = [];

  currentStore: string = null;

  constructor(
    public importOderFromCSVService: ImportOrderFromCsvService,
    private warehouseService: WarehouseService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.importOderFromCSVService
      .getStores()
      .pipe(
        tap(({ items }) => (this.storeList = items)),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  ngOnDestroy() {}

  importFile(file) {
    if (!this.currentStore) {
      this.resetImportInput();
      return this.toastr.error('Choose store first!');
    }

    this.importOderFromCSVService
      .importOrderFromCSV(file, this.warehouseService.warehouseId, this.currentStore)
      .pipe(
        tap(() => {
          this.toastr.success('Order has been imported!');
          this.onImport.emit();
        }),
        finalize(() => this.resetImportInput()),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  getOrderCSVTemplate() {
    this.importOderFromCSVService.getOrderCSVTemplate().pipe(takeUntil(this.componentDestroy())).subscribe();
  }

  resetImportInput() {
    this.importInput.nativeElement.value = null;
  }
}
