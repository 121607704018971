import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MONTHS_NAMES } from 'src/app/core/consts/months';
import { MikuDatePickerService } from './miku-date-picker.service';

@Component({
  selector: 'app-miku-date-picker',
  templateUrl: './miku-date-picker.component.html',
  styleUrls: ['./miku-date-picker.component.scss'],
  providers: [MikuDatePickerService],
})
export class MikuDatePickerComponent implements OnInit {
  @Input() dateControl: FormControl;

  @Input() lastDayDefault: boolean;

  @Input() changeZoom: boolean;

  @Input() zoomLevel: number;

  @Input() setDefaultDate = true;

  public yearPool: number[];

  public monthPool: number[];

  public dayPool: number[];

  public selectedYear: number;

  public selectedMonth: number;

  public selectedDay: number;

  public MONTH_NAMES: string[] = MONTHS_NAMES;

  constructor(private mikuDatePickerService: MikuDatePickerService) {}

  ngOnInit(): void {
    const currentDate = moment();
    const poolLength = 15;
    if (this.setDefaultDate) this.readDate();
    this.yearPool = this.mikuDatePickerService.createYearPool(currentDate.year(), poolLength);
    this.monthPool = Array.from<number, number>(Array(12), (_, i) => i + 1);
    this.createDatePool();
  }

  public onYearChanges(year: number): void {
    this.selectedYear = year;

    if (this.setDefaultDate && this.lastDayDefault) {
      this.setLastDayOfMonth();
    }
    this.createDatePool();
    this.changeDate();
  }

  public onMonthChanges(month: number): void {
    this.selectedMonth = month;

    if (this.setDefaultDate && this.lastDayDefault) {
      this.setLastDayOfMonth();
    }
    this.createDatePool();
    this.changeDate();
  }

  public onDayChanges(day: number): void {
    this.selectedDay = day;
    this.changeDate();
  }

  private createDatePool(): void {
    this.dayPool = this.mikuDatePickerService.createDayPool(
      this.selectedYear || new Date().getFullYear(),
      this.selectedMonth || new Date().getMonth() + 1,
    );
    if (this.setDefaultDate && this.selectedDay > this.dayPool.length) {
      this.selectedDay = this.dayPool.length;
    }
  }

  private changeDate(): void {
    const d = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay);
    if (!this.dateControl) this.dateControl = new FormControl();
    this.dateControl.patchValue(d);
  }

  private readDate(): void {
    const controlValue = this.dateControl?.value;
    let date = new Date();
    this.selectedDay = date.getDate();
    if (controlValue) {
      date = new Date(controlValue);
      this.selectedDay = date.getDate();
    }

    this.selectedYear = date.getFullYear();
    this.selectedMonth = date.getMonth() + 1;

    if (this.setDefaultDate && this.lastDayDefault) {
      this.setLastDayOfMonth();
    }

    this.changeDate();
  }

  private setLastDayOfMonth(year: number = this.selectedYear, month: number = this.selectedMonth): void {
    this.selectedDay = moment(`${year}-${month}`, 'YYYY-MM').endOf('month').date();
  }
}
