import { ColumnInterface } from './column.interface';

export class SortableColumn implements ColumnInterface {
  public name: string;
  public cssClass: string;
  public sortBy: string;

  constructor(name = '', sortBy = '', cssClass = '') {
    this.name = name;
    this.sortBy = sortBy;
    this.cssClass = cssClass;
  }
}
