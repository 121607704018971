import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MassActionSelectAll } from './models/mass-action-select-all.model';
import { MassActionSelectByFilters } from './models/mass-action-select-by-filters.model';
import { MassAction } from './models/mass-action.model';
import { MassActionItem } from './models/mass-action-item.model';
import { MassActionPayload } from './models/mass-action-payload.model';
import { Paginator } from '../../core/services/list/models/Paginator';
import { FiltersService } from '../filters/services/filters.service';

@Injectable({
  providedIn: 'root',
})
export class MassactionService {
  selectAll = new MassActionSelectAll();

  selectByFilters = new MassActionSelectByFilters();

  actions: MassAction[] = [];

  selectedAction: MassAction = null;

  items: MassActionItem[];

  itemsIds: number[];

  constructor(private toastr: ToastrService, private filtersService: FiltersService) {}

  init(items: MassActionItem[], paginationCount: number) {
    this.setItems(items);
    this.selectAll.reset();
    this.selectByFilters.reset();
    this.selectByFilters.itemsCount = paginationCount;
  }

  addAction(name: string, action: () => void) {
    this.actions.push(new MassAction(name, action));
  }

  setItems(items: MassActionItem[]) {
    this.items = items;
  }

  selectItem() {
    this.selectByFilters.reset();
  }

  prepareItems() {
    this.itemsIds = [];
    this.itemsIds = this.items.filter(({ massAction }) => massAction).map(({ id }) => id);
  }

  reset() {
    this.actions = [];
  }

  resetItems() {
    this.selectAll.reset();
    this.items.map(item => (item.massAction = false));
  }

  resetMassAction() {
    this.resetItems();
    this.selectByFilters.reset();
  }

  runAction(props?: unknown) {
    this.prepareItems();
    if (this.selectedAction) {
      if (this.itemsIds.length > 0 || this.selectByFilters.filters) {
        const data: MassActionPayload = { filters: this.selectByFilters, items: this.itemsIds };
        this.selectedAction.action(data, props);
        this.filtersService.filters.paginator = new Paginator(1, 25);
      } else {
        this.toastr.error('First select items!');
      }
    } else {
      this.toastr.error('First select action!');
    }
  }
}
